import { Permission } from './permission';

export class UserPrincipal {
    hasAnyPermissions(): boolean {
        return this.permissions.length > 0;
    }
    private permissions: Array<Permission> = Array<Permission>();
    
    constructor(permissions: string[]) {
        this.permissions = permissions.filter(p => p in Permission)
            .map(permission => permission as Permission);
            
        if(this.permissions.length > 0){
            this.permissions.splice(0,0, Permission.AtLeastOne);
        }
    }

    public hasPermission(permission: Permission): boolean {
        return this.permissions.some(userPermission => userPermission === permission);
    }
}