export enum ServiceAction {
    Refresh = 'refresh',
    Start = 'start',
    Stop = 'stop',
    Restart = 'restart',
    Kill = 'kill',
    ChangeStartupType = 'changeStartupType',
    Uninstall = 'uninstall',
    Install = 'install',

}
