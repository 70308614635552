import React, { useEffect } from 'react';
import Select from 'react-select';
import { ValidationErrors } from './ValidationErrors';

interface ValidatableSelectProps {
    fieldName: string;
    options: any;
    isMulti: boolean;
    values: string[];
    validate: Function;
    onChange: Function;
}
export function ValidatableSelect(props: ValidatableSelectProps) {
    const [errors, setErrors]: [string[], Function] = React.useState([]);

    const onValueChanged = async function (values: string[]) {
        setErrors(props.validate(values));
        props.onChange(values);
    };

    useEffect(() => {
        onValueChanged(props.values);
        // This is here to make sure we calculate and show validation errors right away
        // This is ideally done a different way
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="form-group">
                <label>{props.fieldName}</label>

                <Select
                    isMulti={props.isMulti}
                    className={`required ${errors.length === 0 ? '' : 'validation-error'}`}
                    options={props.options}
                    onChange={async (e: any) => {
                        const selected = e.map(function (item: any) {
                            return item.value;
                        });
                        await onValueChanged(selected);
                    }}
                />
                <ValidationErrors errors={errors} />
            </div>
        </>
    );
}
