import { Collection } from 'lodash';
import { IManagingScheduledTasksInstance } from '../../api/models';
import { ApplicationInstanceEnvironmentComparison } from './ApplicationInstanceEnvironmentComparison';
import { RowDefinition } from './ComparisonTable';

interface ScheduledTasksEnvironmentComparisonProps {
    data: IManagingScheduledTasksInstance[];
}

export const ScheduledTasksEnvironmentComparison = ({ data }: ScheduledTasksEnvironmentComparisonProps) => {
    const rows = [
        { rowName: 'Status', property: 'status' },
        { rowName: 'State', property: 'taskState' },
        { rowName: 'Task To Run', property: 'taskToRun' },
        { rowName: 'RunAs', property: 'runAsUser' },
        { rowName: 'Schedule Type', property: 'scheduleType' },
        { rowName: 'Trigger', property: 'trigger' },
        { rowName: 'Repeat Stop If Still Running', property: 'repeatStopIfStillRunning' },
        { rowName: 'Last Result', property: 'lastResult' },
        { rowName: '-', property: '', customDisplayValueFunction: (values: Collection<{ value: string; count: number }>) => '' },
    ] as RowDefinition<IManagingScheduledTasksInstance>[];

    return <ApplicationInstanceEnvironmentComparison data={data} rows={rows} />;
};
