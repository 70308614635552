import { PropsWithChildren } from 'react';
import { AccessDenied } from '../components/Shared/AccessDenied';
import { useAppMakeSelector } from '../hooks/useAppSelector';
import { Permission } from '../permissions/permission';
import userPrincipalSelectors from '../store/userPrincipal/selectors';

const withHasPermission = <TProps,>(Component: React.FunctionComponent<TProps>, permission: Permission, showAccessDenied: boolean = true) => {
    return (props: PropsWithChildren<TProps>) => {
        const hasPermission = useAppMakeSelector(userPrincipalSelectors.makeSelectHasPermission, permission);

        if (!hasPermission) {
            if (showAccessDenied) {
                return <AccessDenied />;
            }

            return null;
        }

        return <Component {...props} />;
    };
};

export default withHasPermission;
