import { createAsyncThunk } from '@reduxjs/toolkit';
import AppManagerClient from '../../api/AppManagerClient';
import { Permission } from '../../permissions/permission';

export const getRootPermissions = createAsyncThunk('userPrincipal/getUserInfo', async (thunkApi) => {
    const results = await AppManagerClient.getRootPermissions();
    //todo:abuchelt - remove when api ready
    results.push(Permission.Root_ScheduledTasks);
    return results;
});
