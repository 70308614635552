import { Table } from 'react-bootstrap';
import { EmptyDataRow } from '../UIExtentions/TableUtilities';
import { ServersTableRow } from './ServersTableRow';

export interface Server {
    id: number;
    name: string;
    environment?: string;
    lastDiscoveredTimestamp?: string;
}

export interface ServersTableProps {
    data: Server[];
}

const ServersTable = (props: ServersTableProps) => {
    return (
        <div className="table-wrapper">
            <Table striped={true} className="table-fixed-header">
                <thead className="thead-white">
                    <tr>
                        <th scope="col" className="col-1">
                            <i className="fa fa-star"></i>
                        </th>
                        <th scope="col" className="col-5">
                            Name
                        </th>
                        <th scope="col" className="col-2">
                            Environment
                        </th>
                        <th scope="col" className="col-3">
                            Last Discovery
                        </th>
                        <th scope="col" className="col-1">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.length > 0 ? (
                        props.data.map(function (d: Server, i: number) {
                            return <ServersTableRow key={'server-' + d.id} name={d.name} environment={d.environment} lastDiscoveredTimestamp={d.lastDiscoveredTimestamp} />;
                        })
                    ) : (
                        <EmptyDataRow colSpan={5} />
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default ServersTable;
