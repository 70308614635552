export enum EventType {
    WindowsServiceStartTriggered = 'service.start.triggered',
    WindowsServiceStartFailed = 'service.start.failed',
    WindowsServiceStartSucceeded = 'service.start.succeeded',

    WindowsServiceStopTriggered = 'service.stop.triggered',
    WindowsServiceStopFailed = 'service.stop.failed',
    WindowsServiceStopSucceeded = 'service.stop.succeeded',

    WindowsServiceRestartTriggered = 'service.restart.triggered',
    WindowsServiceRestartFailed = 'service.restart.failed',
    WindowsServiceRestartSucceeded = 'service.restart.succeeded',

    WindowsServiceKillTriggered = 'service.kill.triggered',
    WindowsServiceKillFailed = 'service.kill.failed',
    WindowsServiceKillSucceeded = 'service.kill.succeeded',

    WindowsServiceInstallTriggered = 'service.install.triggered',
    WindowsServiceInstallFailed = 'service.install.failed',
    WindowsServiceInstallSucceeded = 'service.install.succeeded',

    WindowsServiceUninstallTriggered = 'service.uninstall.triggered',
    WindowsServiceUninstallFailed = 'service.uninstall.failed',
    WindowsServiceUninstallSucceeded = 'service.uninstall.succeeded',

    WindowsServiceChangeStartTypeTriggered = 'service.change-startup-type.triggered',
    WindowsServiceChangeStartTypeFailed = 'service.changestarttype.failed',
    WindowsServiceChangeStartTypeSucceeded = 'service.changestarttype.succeeded',

    WindowsServiceRefreshTriggered = 'service.refresh.triggered',
    WindowsServiceRefreshFailed = 'service.refresh.failed',
    WindowsServiceRefreshSucceeded = 'service.refresh.succeeded',

    AppPoolStartTriggered = 'apppool.start.triggered',
    AppPoolStartFailed = 'apppool.start.failed',
    AppPoolStartSucceeded = 'apppool.start.succeeded',

    AppPoolStopTriggered = 'apppool.stop.triggered',
    AppPoolStopFailed = 'apppool.stop.failed',
    AppPoolStopSucceeded = 'apppool.stop.succeeded',

    AppPoolRecycleTriggered = 'apppool.recycle.triggered',
    AppPoolRecycleFailed = 'apppool.recycle.failed',
    AppPoolRecycleSucceeded = 'apppool.recycle.succeeded',

    AppPoolRefreshTriggered = 'apppool.refresh.triggered',
    AppPoolRefreshFailed = 'apppool.refresh.failed',
    AppPoolRefreshSucceeded = 'apppool.refresh.succeeded',

    WebSiteRefreshTriggered = 'website.refresh.triggered',
    WebSiteRefreshFailed = 'website.refresh.failed',
    WebSiteRefreshSucceeded = 'website.refresh.succeeded',

    ScheduledTaskRefreshTriggered = 'scheduled-task.refresh.triggered',
    ScheduledTaskRefreshFailed = 'scheduled-task.refresh.failed',
    ScheduledTaskRefreshSucceeded = 'scheduled-task.refresh.succeeded',
    ScheduledTaskCreateSucceeded = 'scheduled-task.create.succeeded',
    ScheduledTaskDeleteSucceeded = 'scheduled-task.delete.succeeded',
    ScheduledTaskStartTriggered = 'scheduled-task.start.triggered',
    ScheduledTaskStartFailed = 'scheduled-task.start.failed',
    ScheduledTaskStartSucceeded = 'scheduled-task.start.succeeded',
    ScheduledTaskStopTriggered = 'scheduled-task.stop.triggered',
    ScheduledTaskStopFailed = 'scheduled-task.stop.failed',
    ScheduledTaskStopSucceeded = 'scheduled-task.stop.succeeded',
    ScheduledTaskEnableTriggered = 'scheduled-task.enable.triggered',
    ScheduledTaskEnableFailed = 'scheduled-task.enable.failed',
    ScheduledTaskEnableSucceeded = 'scheduled-task.enable.succeeded',
    ScheduledTaskDisableTriggered = 'scheduled-task.disable.triggered',
    ScheduledTaskDisableFailed = 'scheduled-task.disable.failed',
    ScheduledTaskDisableSucceeded = 'scheduled-task.disable.succeeded',

    VirtualAppRefreshTriggered = 'virtualapp.refresh.triggered',
    VirtualAppRefreshFailed = 'virtualapp.refresh.failed',
    VirtualAppRefreshSucceeded = 'virtualapp.refresh.succeeded',

    AgentPingTriggered = 'agent.ping.triggered',
    AgentPingSucceeded = 'agent.ping.succeeded',
    AgentPingFailed = 'agent.ping.failed',
}
