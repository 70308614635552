import { AgentClientEventSummary } from '../../signalr/models';
import { ClientEventSummaryHandlerBase, ClientEventSummaryHandlerBaseArgs } from '../../signalr/ClientEventSummaryHandlerBase';

export interface AgentClientEventSummaryHandlerArgs extends ClientEventSummaryHandlerBaseArgs<AgentClientEventSummary> {}

export class AgentClientEventSummaryHandler extends ClientEventSummaryHandlerBase<AgentClientEventSummary> {
    private version: string;

    public constructor(server: string, version: string, args: AgentClientEventSummaryHandlerArgs) {
        super(server, args);
        this.version = version;
    }

    protected override isForMe(data: AgentClientEventSummary): boolean {
        return data.targetServer === this.server && data.version === this.version;
    }
}
