import { PageHeading } from '@quad/bootstrap-react';
import { Container } from 'react-bootstrap';
import ScrollPageContent from '../../areas/shared/ScrollPageContent';
import ApplicationsTable from '../../components/Applications/ApplicationsTable';
import { IApplication } from '../../api/models';

export interface ApplicationSearchResultsProps {
    data: IApplication[];
}

const ApplicationSearchResults = (props: ApplicationSearchResultsProps) => {
    return (
        <>
            <ScrollPageContent>
                <PageHeading>
                    <div className="col">
                        <h1>
                            <i className="fa fa-search"></i> Search Results
                        </h1>
                    </div>
                </PageHeading>
                <Container>
                    <ApplicationsTable data={props.data} />
                </Container>
            </ScrollPageContent>
        </>
    );
};

export default ApplicationSearchResults;
