import { dateToLocal, stringToDate } from '../../helpers/DateHelpers';
import { ValidCertificateDateDisplay } from './ValidCertificateDateDisplay';

export interface ValidFromDateDisplayProps {
    date: string;
}

export const ValidFromDateDisplay = ({ date }: ValidFromDateDisplayProps) => {
    const today = new Date();
    const certDate = stringToDate(date) as Date;
    const formattedDate = dateToLocal(certDate);
    const isValid = today > certDate;
    const textColor = isValid ? 'text-success' : 'text-danger';
    const icon = isValid ? 'fa fa-check-circle' : 'fa fa-exclamation-circle';
    const title = isValid ? 'Valid' : 'Certificate has not yet taken effect!';

    return (
        <>
            <ValidCertificateDateDisplay textColor={textColor} icon={icon} certDate={certDate} title={title} formattedDate={formattedDate} />
        </>
    );
};
