import { Row, Col } from 'react-bootstrap';
import { IManagingWebSitesInstance } from '../../api/models';
import { ApiDataWrapper } from '../UIExtentions/ApiDataWrapper';

export const WebSitesInfoView = (props: any) => {
    return (
        <>
            <ApiDataWrapper
                objectType="Install Info"
                objectName={props.applicationName}
                isLoading={props.isLoading}
                isDataNotFound={props.isDataNotFound}
                errorMessage={props.errorMessage}
            >
                <ServerWebSiteDetails serverApp={props.serverApps} />
            </ApiDataWrapper>
        </>
    );
};

export function ServerWebSiteDetails(props: any) {
    const serverApp = props.serverApp as IManagingWebSitesInstance;
    return (
        <>
            <Row>
                <Col sm={4}>Site Id:</Col>
                <Col>{serverApp.siteId}</Col>
            </Row>
            <Row>
                <Col sm={4}>Bindings:</Col>
                <Col>{serverApp.bindings}</Col>
            </Row>
            <Row>
                <Col sm={4}>Autostart:</Col>
                <Col>{`${serverApp.autoStart}`}</Col>
            </Row>
            <Row>
                <Col sm={4}>Log File Directory:</Col>
                <Col>{serverApp.logFileDirectory}</Col>
            </Row>
            <Row>
                <Col sm={4}>State:</Col>
                <Col>{serverApp.state}</Col>
            </Row>
        </>
    );
}
