import { ApiAbortedError, ApiError, ApiFailToParseJsonError, ApiNonSuccessError, IApiRequest } from './models';

const appFetchJsonNullable = async <T>(request: IApiRequest): Promise<T | null> => {
    const response = await appFetch(request);

    if (response.status === 204) {
        return null;
    }

    try {
        const json = await response.json();

        return json;
    } catch (error) {
        throw new ApiFailToParseJsonError(new Error('Failed to get json() from response'));
    }
};

const appFetchJson = async <T>(request: IApiRequest): Promise<T> => {
    const json = await appFetchJsonNullable<T>(request);

    if (json === null) {
        throw new ApiFailToParseJsonError(new Error('API returned no content. If this is ok for this call, use appFetchJsonNullable instead.'));
    }

    return json;
};

function appFetch(request: IApiRequest) {
    const abortablePromise = new Promise<Response>((resolve, reject) => {
        _appFetch(request)
            .then(resolve)
            .catch((reason) => {
                if (reason instanceof ApiAbortedError) {
                    request.onAbort?.call(undefined, reason);
                    return;
                }

                reject(reason);
            });
    });

    return abortablePromise;
}

async function _appFetch(request: IApiRequest) {
    const requestInit: RequestInit = {
        method: request.method ?? (request.method === undefined && request.body !== undefined ? 'POST' : 'GET'),
        headers: {
            Accept: 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: `Bearer ${request.accessToken}`,
        },
        body: JSON.stringify(request.body),
        signal: request.signal,
    };

    try {
        const response = await fetch(request.url, requestInit);

        if (response.ok) {
            // continue
        } else {
            switch (response.status) {
                case 400:
                case 401:
                case 403: {
                    const json = await response.json();
                    const apiError = json as ApiError;
                    const message = apiError?.Message;
                    throw new ApiNonSuccessError(response.status, message);
                }
                default: {
                    throw new ApiNonSuccessError(response.status);
                }
            }
        }

        return response;
    } catch (e) {
        if (e instanceof DOMException && e.code === e.ABORT_ERR) {
            throw new ApiAbortedError(e);
        }

        throw e;
    }
}

abstract class ClientBase {
    constructor(protected baseUrl: string) {}

    protected appFetchJsonNullable = async <T = any>(request: IApiRequest): Promise<T | null> => {
        const rewrote = { ...request, url: `${this.baseUrl}/${request.url}` };

        return await appFetchJsonNullable(rewrote);
    };

    protected appFetchJson = async <T = any>(request: IApiRequest): Promise<T> => {
        const rewrote = { ...request, url: `${this.baseUrl}/${request.url}` };

        return await appFetchJson(rewrote);
    };

    protected appFetch = async (request: IApiRequest) => {
        const rewrote = { ...request, url: `${this.baseUrl}/${request.url}` };

        return await appFetch(rewrote);
    };
}

export default ClientBase;
