import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import appConfig from './appConfig';
import App from './areas/app/App';
import './index.scss';
import { msalInstance, store } from './store';
import SignedOut from './areas/app/SignedOut';
// Bringing in bootstrap's js, custom NavBar for example relies on it...
// Ideally use a react library for this instead
import 'bootstrap';

const baseUrl = appConfig.publicUrl;
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <Provider store={store}>
                <DndProvider backend={HTML5Backend}>
                    <BrowserRouter basename={baseUrl}>
                        <Routes>
                            <Route path="*" element={<App />} />
                            <Route path="/signed-out" element={<SignedOut />} />
                        </Routes>
                    </BrowserRouter>
                </DndProvider>
            </Provider>
        </MsalProvider>
    </React.StrictMode>
);
