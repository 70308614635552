import { AppType } from '../../constants/AppType';
import Applications from '../../components/Applications/Applications';
import withHasPermission from '../../hoc/withHasPermission';
import { Permission } from '../../permissions/permission';

const WindowsServices = withHasPermission(() => {
    return <Applications type={AppType.WindowsService} title={'Windows Services'} icon={'fa fa-cogs'} />;
}, Permission.Root_WindowsServices);

export default WindowsServices;
