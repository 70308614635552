import { AccordionWrapper } from '../UIExtentions/Accordion';
import AgentsTable from '../Agents/AgentsTable';
import { ApiNonSuccessError, IAgent } from '../../api/models';
import { AgentAction } from '../Agents/AgentAction';
import { AgentClientEventSummary } from '../../signalr/models';
import AppManagerClient from '../../api/AppManagerClient';
import { PopupProps } from '../../areas/shared/Popup';
import { useState } from 'react';
import { ApplicationServerManagementToast } from '../ApplicationServerManagement/ApplicationServerManagementToast';

export interface ServerAgentsViewProps {
    isLoading: boolean;
    isDataNotFound: boolean;
    errorMessage?: string;
    serverName: string;
    agents: IAgent[];
}

export interface AgentActionHandlerProps {
    serverName: string;
    version: string;
    action: AgentAction;
}

export const ServerAgentsView = (props: ServerAgentsViewProps) => {
    const [popupProps, setPopupProps] = useState<PopupProps | undefined>();

    const agentActionHandler = async function (props: AgentActionHandlerProps) {
        if (props.action === AgentAction.Ping) {
            await tryWithErrorHandler(props.action, () => AppManagerClient.pingAgent(props.serverName, props.version));
            return;
        }
        showPopup({
            header: `Operation unknown [${props.action}].`,
            body: `Operation: [${props.action}]`,
            type: 'danger',
            autohide: true,
        });
    };

    const tryWithErrorHandler = async function (action: string, funct: () => Promise<string>) {
        try {
            const postResult = await funct();

            showPopup({
                header: `Successfully send action [${action}] to agent.`,
                toCopy: postResult,
                body: `Correlation Id: ${postResult}`,
            });
        } catch (error) {
            const apiError = error as ApiNonSuccessError;
            showPopup({
                header: `Error (${apiError?.status}) while calling [${action}].`,
                type: 'danger',
                body: `Error: ${apiError?.message} `,
            });
        }
    };

    const onTimeout = function (data: AgentClientEventSummary) {
        showPopup({
            header: `Operation timed out on [${data.targetServer}].`,
            body: `Operation: [${data.type}]`,
            type: 'danger',
            autohide: true,
        });
    };

    const showPopup = function (props: PopupProps) {
        setPopupProps(props);
    };

    return (
        <>
            <ApplicationServerManagementToast popupProps={popupProps} onClose={() => setPopupProps(undefined)} />
            <AccordionWrapper
                isDefaultOpen={false}
                headerIcon="fa-download"
                headerText="Agents"
                objectName={props.serverName}
                isLoading={props.isLoading}
                errorMessage={props.errorMessage}
                isDataNotFound={props.isDataNotFound}
            >
                <AgentsTable data={props.agents} onClickCallback={agentActionHandler} onTimeoutCallback={onTimeout} />
            </AccordionWrapper>
        </>
    );
};
