import { Row, Col } from 'react-bootstrap';
import { IManagingAppPoolsInstance } from '../../api/models';
import { ApiDataWrapper } from '../UIExtentions/ApiDataWrapper';

export const AppPoolsInfoView = (props: any) => {
    return (
        <>
            <ApiDataWrapper
                objectType="Install Info"
                objectName={props.applicationName}
                isLoading={props.isLoading}
                isDataNotFound={props.isDataNotFound}
                errorMessage={props.errorMessage}
            >
                <ServerAppPoolDetails serverApp={props.serverApps}></ServerAppPoolDetails>
            </ApiDataWrapper>
        </>
    );
};

export function ServerAppPoolDetails(props: any) {
    const serverApp = props.serverApp as IManagingAppPoolsInstance;
    return (
        <>
            <Row>
                <Col sm={4}>Managed Runtime Version:</Col>
                <Col>{serverApp.managedRuntimeVersion}</Col>
            </Row>
            <Row>
                <Col sm={4}>Identity Type:</Col>
                <Col>
                    <span style={{ lineBreak: 'anywhere' }}>{serverApp.identityType}</span>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>Username:</Col>
                <Col>{serverApp.userName}</Col>
            </Row>
            <Row>
                <Col sm={4}>State:</Col>
                <Col>{serverApp.state}</Col>
            </Row>
        </>
    );
}
