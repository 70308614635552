export const filter = <T>(data: T[], searchTerm: string, fields: string[]): T[] => {
    const searchString = searchTerm.trim().toLowerCase();

    if (searchString.length === 0) {
        return data;
    }

    return data.filter((item: T) => {
        return fields.some((field) => {
            const value = (item as any)[field];
            return value && value.toString().toLowerCase().includes(searchString);
        });
    });
};
