import { useEffect, useState } from 'react';
import { IManagingVirtualAppsInstance } from '../../api/models';
import { VirtualAppAction } from '../../constants/VirtualAppAction';
import { EventType } from '../../constants/EventType';
import {
    ServerApplicationClientEventSummaryHandler,
    ServerApplicationClientEventSummaryHandlerArgs,
} from '../ApplicationServerManagement/ServerApplicationClientEventSummaryHandler';
import useRefedProp from '../../hooks/useRefedProp';
import { Permission } from '../../permissions/permission';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { ServerApplicationClientEventSummary } from '../../signalr/models';
import signalREventsClient from '../../signalr/SignalREventsClient';
import { ServerVirtualAppDetails } from '../VirtualAppsInfo/VirtualAppsInfoView';
import { InfoModal } from '../Shared/InfoModal';
import { ActionButton } from '../UIExtentions/Buttons';
import { VirtualAppsServerRowProps } from './VirtualAppsServerRow';

export interface VirtualAppsServerRowActionsProps {
    serverApp: IManagingVirtualAppsInstance;
    userPrincipal: UserPrincipal;
    serverUserPrincipal: UserPrincipal;
    onClickCallback: (action: VirtualAppAction) => Promise<void>;
    onTimeoutCallback: (data: ServerApplicationClientEventSummary) => void;
}

export function VirtualAppsServerRowActions(props: VirtualAppsServerRowProps) {
    const [showInfo, setShowInfo] = useState(false);
    const [refreshSpinning, setRefreshSpinning] = useState(false);
    const onTimeoutCallbackRef = useRefedProp(props.onTimeoutCallback);

    const serverApp = props.serverApp;
    const userPrincipal = props.userPrincipal;
    const serverUserPrincipal = props.serverUserPrincipal;
    const onClickCallback = props.onClickCallback;

    const getEnableState = function (permission: Permission): boolean {
        return userPrincipal.hasPermission(permission) || serverUserPrincipal?.hasPermission(permission);
    };

    useEffect(() => {
        const VirtualAppRefreshHandler = new ServerApplicationClientEventSummaryHandler(serverApp.serverName, {
            addTypes: [EventType.WebSiteRefreshTriggered],
            failedTypes: [EventType.WebSiteRefreshFailed],
            successTypes: [EventType.WebSiteRefreshSucceeded],
            emptyCallback: () => setRefreshSpinning(false),
            addCallback: () => setRefreshSpinning(true),
            timeoutCallback: (data) => onTimeoutCallbackRef.current?.(data),
        } as ServerApplicationClientEventSummaryHandlerArgs);

        function isForMe(data: ServerApplicationClientEventSummary) {
            return (data.targetApplication === serverApp.name || data.targetApplication === serverApp.siteName) && data.targetServer === serverApp.serverName;
        }

        signalREventsClient.onWebSiteEvent.setHook(`VirtualAppsServerRow_${serverApp.name}_${serverApp.serverName}`, (data) => {
            if (isForMe(data)) {
                VirtualAppRefreshHandler.handleItem(data);
            }
        });
    }, [onTimeoutCallbackRef, serverApp.name, serverApp.serverName, serverApp.siteName]);

    const infoModalTitle = `${serverApp.name} - ${serverApp.serverName}`;

    return (
        <>
            <td>
                <ActionButton
                    disabled={!getEnableState(Permission.VirtualApp_Refresh)}
                    spinning={refreshSpinning}
                    icon="fa-refresh"
                    variant="btn-dark-blue"
                    title="Refresh"
                    onClick={() => onClickCallback(VirtualAppAction.Refresh)}
                />
            </td>
            <td></td>
            <td className="text-center">
                <ActionButton icon="fa-info-circle" variant="btn-dark-blue" title="Info" onClick={() => setShowInfo(true)} />
                <InfoModal title={infoModalTitle} show={showInfo} onHide={() => setShowInfo(false)}>
                    <ServerVirtualAppDetails serverApp={serverApp} />
                </InfoModal>
            </td>
        </>
    );
}
