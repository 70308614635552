import { ReactNode } from 'react';
import { IManagingApplicationInstance, IServerCertificate } from '../../api/models';
import { TimeInMilliseconds } from '../../constants/TimeInMilliseconds';
import { stringToDate } from '../../helpers/DateHelpers';
import { LastDiscoveryType } from '../Servers/LastDiscoveryType';
import { AppManagerWikiLink } from '../Shared/AppManagerWikiLink';
import { Link } from 'react-router-dom';

const DefaultExpirationDays = 7;

const GetLastDiscoveryAgoMilis = (lastDiscoveredTimestampDate: Date): number => {
    const currentTimestamp = new Date();
    const milisDifference = currentTimestamp.getTime() - lastDiscoveredTimestampDate.getTime();
    return milisDifference;
};

const GetLastServerDiscoveryType = (lastDiscoveredTimestamp: Date): LastDiscoveryType => {
    const lastDiscoveryAgoMilis = GetLastDiscoveryAgoMilis(lastDiscoveredTimestamp);
    if (lastDiscoveryAgoMilis < TimeInMilliseconds.MINUTE * 15) {
        return LastDiscoveryType.OK;
    }
    if (lastDiscoveryAgoMilis < TimeInMilliseconds.HOUR) {
        return LastDiscoveryType.Warning;
    }
    return LastDiscoveryType.Error;
};

const GetLastAppDiscoveryTypeByString = (lastDiscoveredTimestamp: string | undefined): LastDiscoveryType => {
    const date = stringToDate(lastDiscoveredTimestamp);
    if (!date) {
        return LastDiscoveryType.Unknown;
    }
    return GetLastAppDiscoveryType(date);
};

const GetLastAppDiscoveryType = (lastDiscoveredTimestamp: Date): LastDiscoveryType => {
    return GetLastDiscoveryType(lastDiscoveredTimestamp, 15);
};

const GetLastCertDiscoveryType = (lastDiscoveredTimestamp: Date): LastDiscoveryType => {
    return GetLastDiscoveryType(lastDiscoveredTimestamp, 60);
};

const GetLastDiscoveryType = (lastDiscoveredTimestamp: Date, maxMinutes: number): LastDiscoveryType => {
    const lastDiscoveryAgoMilis = GetLastDiscoveryAgoMilis(lastDiscoveredTimestamp);
    if (lastDiscoveryAgoMilis < TimeInMilliseconds.MINUTE * maxMinutes) {
        return LastDiscoveryType.OK;
    }
    if (lastDiscoveryAgoMilis < DefaultExpirationDays * TimeInMilliseconds.DAY) {
        return LastDiscoveryType.Warning;
    }
    return LastDiscoveryType.Error;
};

const GetStatusByType = (lastDiscoveryType: LastDiscoveryType): DiscoveryStatusView => {
    switch (lastDiscoveryType) {
        case LastDiscoveryType.OK:
            return {
                colorBtn: 'btn-green-success',
                colorText: 'text-success',
                icon: 'fa-check-circle',
            };
        case LastDiscoveryType.Warning:
            return {
                colorBtn: 'btn-yellow-waning',
                colorText: 'text-warning',
                icon: 'fa-exclamation-triangle',
            };
        case LastDiscoveryType.Error:
            return {
                colorBtn: 'btn-red-error',
                colorText: 'text-danger',
                icon: 'fa-exclamation-circle',
            };
        case LastDiscoveryType.Unknown:
        default:
            return {
                colorBtn: 'btn-red-error',
                colorText: 'text-danger',
                icon: 'fa-exclamation-circle',
            };
    }
};

const GetExpirationTime = (date: Date): string => {
    const agoMili = GetLastDiscoveryAgoMilis(date);
    const lastDiscoveryAgoExpirationDifferenceAbs = Math.abs(agoMili);

    const days = Math.floor(lastDiscoveryAgoExpirationDifferenceAbs / TimeInMilliseconds.DAY);
    const hours = Math.floor((lastDiscoveryAgoExpirationDifferenceAbs % TimeInMilliseconds.DAY) / TimeInMilliseconds.HOUR);
    const minutes = Math.floor((lastDiscoveryAgoExpirationDifferenceAbs % TimeInMilliseconds.HOUR) / TimeInMilliseconds.MINUTE);

    return `${days > 0 ? `${days} day(s) and ` : ''}${hours > 0 ? `${hours} hour(s) and ` : ''}${minutes} minute(s)`;
};

interface DiscoveryStatusView {
    colorText: string;
    colorBtn: string;
    icon: string;
}

interface DiscoveryStatusResult {
    state: LastDiscoveryType;
    message: string;
    view: DiscoveryStatusView;
}

interface AppDiscoveryStatusResult {
    app: DiscoveryStatusResult;
    actions: ReactNode;
}

interface CertificateStatusResult {
    serverCertificate: DiscoveryStatusResult;
    actions: ReactNode;
}

const GetServerDiscoveryStatus = (lastDiscoveredTimestamp: string | undefined): DiscoveryStatusResult => {
    const result = {
        state: LastDiscoveryType.Unknown,
    } as DiscoveryStatusResult;
    const serverTime = stringToDate(lastDiscoveredTimestamp);
    if (!serverTime) {
        result.view = GetStatusByType(result.state);
        result.message = 'Server is not discovered yet';
        return result;
    }
    result.state = GetLastServerDiscoveryType(serverTime);
    result.view = GetStatusByType(result.state);
    if (result.state === LastDiscoveryType.OK) {
        result.message = 'Normal';
        return result;
    }
    const timeMessage = GetExpirationTime(serverTime);
    result.message = `Server has not posted any discovery activity for ${timeMessage}.`;
    return result;
};

const GetCertificateDiscoveryStatus = (serverCertificate: IServerCertificate): CertificateStatusResult => {
    const certificateLastDiscovery = serverCertificate.lastDiscoveredTimestamp;
    const serverLastDiscovery = serverCertificate.server.lastDiscoveredTimestamp;
    const serverName = serverCertificate.server.name;
    const result = {} as CertificateStatusResult;
    const cert = {
        state: LastDiscoveryType.Unknown,
    } as DiscoveryStatusResult;
    result.serverCertificate = cert;
    result.actions = <>???</>;
    const certTime = stringToDate(certificateLastDiscovery);
    if (!certTime) {
        cert.view = GetStatusByType(cert.state);
        cert.message = 'Certificate is not discovered yet';
        return result;
    }

    const serverState = GetServerDiscoveryStatus(serverLastDiscovery);
    if (serverState.state !== LastDiscoveryType.OK) {
        cert.state = serverState.state;
        cert.view = serverState.view;
        cert.message = serverState.message;
        result.actions = serverInvalidStateActions(serverName);
        return result;
    }

    cert.state = GetLastCertDiscoveryType(certTime);
    cert.view = GetStatusByType(cert.state);
    if (cert.state === LastDiscoveryType.OK) {
        cert.message = 'Normal';
        result.actions = <>No actions are needed.</>;
        return result;
    }

    const timeMessage = GetExpirationTime(certTime);
    cert.message = `Certificate has not been discovered for ${timeMessage}. It will be deleted after ${DefaultExpirationDays} days since last discovery.`;
    result.actions = (
        <>
            Server is valid, but certificate discovery is late. Verify if certificate is still present on the server.
            <br />
            <span>
                Please contact <b>server owners</b> so they can verify Agent logs. They can be found in EMT tool{' '}
                <a href="https://emt.quad.com/ResourceManagement" title="Go to EMT" target={'_blank'} rel="noreferrer">
                    Resource Management
                </a>{' '}
                for server <b>{serverName}</b> as Support Team or Primary Team. Detail instructions on Wiki{' '}
                <AppManagerWikiLink pagePath="Troubleshooting" anchor="certificate-is-not-discovered" />.
            </span>
        </>
    );
    return result;
};

const serverInvalidStateActions = (serverName: string) => (
    <>
        Server is in an invalid state. Agent seems to be down or comunication between Agent and API is broken.
        <br />
        <span>
            Please verify if Agent is working properly via <b>Pinging</b> it. You can do that on <Link to={`/servers/${serverName}`}>server details page</Link>.
            <br />
            Please contact <b>server owners</b> so they can verify Agent logs. They can be found in EMT tool{' '}
            <a href="https://emt.quad.com/ResourceManagement" title="Go to EMT" target={'_blank'} rel="noreferrer">
                Resource Management
            </a>{' '}
            for server <b>{serverName}</b> as Support Team or Primary Team. Detail instructions on Wiki{' '}
            <AppManagerWikiLink pagePath="Troubleshooting" anchor="server-is-not-discovered" />.
        </span>
    </>
);

const GetAppDiscoveryStatus = (serverApp: IManagingApplicationInstance): AppDiscoveryStatusResult => {
    const serverAppLastDiscovery = serverApp.lastDiscoveredTimestamp;
    const serverLastDiscovery = serverApp.serverLastDiscoveredTimestamp;
    const serverName = serverApp.serverName;
    const result = {} as AppDiscoveryStatusResult;
    const app = {
        state: LastDiscoveryType.Unknown,
    } as DiscoveryStatusResult;
    result.app = app;
    result.actions = <>???</>;
    const appTime = stringToDate(serverAppLastDiscovery);
    if (!appTime) {
        app.view = GetStatusByType(app.state);
        app.message = 'Application is not discovered yet';
        return result;
    }
    const serverState = GetServerDiscoveryStatus(serverLastDiscovery);
    if (serverState.state !== LastDiscoveryType.OK) {
        app.state = serverState.state;
        app.view = serverState.view;
        app.message = serverState.message;
        result.actions = serverInvalidStateActions(serverName);
        return result;
    }

    app.state = GetLastAppDiscoveryType(appTime);
    app.view = GetStatusByType(app.state);
    if (app.state === LastDiscoveryType.OK) {
        app.message = 'Normal';
        result.actions = <>No actions are needed.</>;
        return result;
    }
    const timeMessage = GetExpirationTime(appTime);
    app.message = `App has not been discovered for ${timeMessage}. It will be deleted after ${DefaultExpirationDays} days since last discovery.`;
    result.actions = (
        <>
            Server is valid, but application discovery is late. Verify if application is still present on the server.
            <br />
            <span>
                Please contact <b>server owners</b> so they can verify Agent logs. They can be found in EMT tool{' '}
                <a href="https://emt.quad.com/ResourceManagement" title="Go to EMT" target={'_blank'} rel="noreferrer">
                    Resource Management
                </a>{' '}
                for server <b>{serverName}</b> as Support Team or Primary Team. Detail instructions on Wiki{' '}
                <AppManagerWikiLink pagePath="Troubleshooting" anchor="application-is-not-discovered" />.
            </span>
        </>
    );
    return result;
};

export { GetLastAppDiscoveryTypeByString, GetServerDiscoveryStatus, GetAppDiscoveryStatus, GetCertificateDiscoveryStatus };
