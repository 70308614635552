import { createSelector } from '@reduxjs/toolkit';
import { Permission } from '../../permissions/permission';
import { RootState } from '../../store';

const selectState = (rs: RootState) => rs.userPrincipal;
const selectPermissions = createSelector([selectState], (state) => state.permissions);
const selectHasAnyPermissions = createSelector([selectPermissions], (permissions) => !!permissions.length);
const selectHasPermissionTest = createSelector(
    [selectPermissions],
    (permissions) => (permission: Permission) => permission === Permission.AtLeastOne ? !!permissions.length : permissions.some((userPermission) => userPermission === permission)
);
const makeSelectHasPermission = () => createSelector([selectHasPermissionTest, (_: RootState, permission: Permission) => permission], (test, permission) => test(permission));

const themeSelectors = {
    selectState,
    selectPermissions,
    selectHasAnyPermissions,
    selectHasPermissionTest,
    makeSelectHasPermission,
};

export default themeSelectors;
