import { Col, Row } from 'react-bootstrap';
import { IApplication } from '../../api/models';
import { ApiDataWrapper } from '../UIExtentions/ApiDataWrapper';

export const ApplicationDetailsView = (props: any) => {
    return (
        <>
            <ApiDataWrapper
                objectType="Details"
                objectName={props.applicationName}
                isLoading={props.isLoading}
                isDataNotFound={props.isDataNotFound}
                errorMessage={props.errorMessage}
            >
                <ApplicationDataView application={props.application} />
            </ApiDataWrapper>
        </>
    );
};

export const ApplicationDataView = (props: any) => {
    const application = props.application as IApplication;

    return (
        <>
            <Row>
                <Col sm={4}>Name:</Col>
                <Col>{application.name}</Col>
            </Row>
            <Row>
                <Col sm={4}>Display name:</Col>
                <Col>{application.displayName}</Col>
            </Row>
            <Row>
                <Col sm={4}>Description:</Col>
                <Col>{application.description}</Col>
            </Row>
        </>
    );
};
