import React from 'react';
import { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { versionSort } from '../../helpers/sorting';
import { parseVersion } from '../../helpers/Version';
import { EmptyDataRow } from '../UIExtentions/TableUtilities';
import { AgentsTableRow } from './AgentsTableRow';
import { IAgent } from '../../api/models';
import { AgentAction } from './AgentAction';
import { AgentClientEventSummary } from '../../signalr/models';
import { AgentActionHandlerProps } from '../ServerAgents/ServerAgentsView';

interface AgentsTableProps {
    data: IAgent[];
    onClickCallback: (props: AgentActionHandlerProps) => Promise<void>;
    onTimeoutCallback: (data: AgentClientEventSummary) => void;
}
const AgentsTable = ({ data, onClickCallback, onTimeoutCallback }: AgentsTableProps) => {
    const [agents, setAgents] = React.useState(new Array<IAgent>());

    const sortAgents = async (data: IAgent[]) => {
        const AgentVersionSort = (left: IAgent, right: IAgent) => {
            const leftVersion = parseVersion(left.version);
            const rightVersion = parseVersion(right.version);

            return versionSort(leftVersion, rightVersion);
        };

        setAgents(data.sort(AgentVersionSort));
    };

    useEffect(() => {
        sortAgents(data);
    }, [data]);

    return (
        <div className="table-wrapper">
            <Table striped={true} className="table-fixed-header">
                <thead className="thead-white">
                    <tr>
                        <th scope="col" className="col-1">
                            <i className="fa fa-star"></i>
                        </th>
                        <th scope="col" className="col-1">
                            Version
                        </th>
                        <th scope="col" className="col-2">
                            Name
                        </th>
                        <th scope="col" className="col-2">
                            Installed Location
                        </th>
                        <th scope="col" className="col-1">
                            RunAs
                        </th>
                        <th scope="col" className="col-3">
                            Created Date
                        </th>
                        <th scope="col" className="col-2">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {agents.length > 0 ? (
                        agents.map(function (agent: IAgent, i: number) {
                            agent.isPrimary = i === 0;
                            return (
                                <AgentsTableRow
                                    key={'agent-' + agent.id}
                                    agent={agent}
                                    onClickCallback={(action: AgentAction) =>
                                        onClickCallback({
                                            serverName: agent.serverName,
                                            version: agent.version,
                                            action: action,
                                        })
                                    }
                                    onTimeoutCallback={onTimeoutCallback}
                                />
                            );
                        })
                    ) : (
                        <EmptyDataRow colSpan={6} />
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default AgentsTable;
