import { Link } from 'react-router-dom';
import { ServerLastDiscoveredTimestamp } from '../Shared/ServerLastDiscoveredTimestamp';
import { InfoLink } from '../UIExtentions/InfoLink';

interface ServersTableRowProps {
    name: string;
    environment?: string;
    lastDiscoveredTimestamp?: string;
}

export function ServersTableRow(props: ServersTableRowProps) {
    return (
        <tr>
            <td></td>
            <td>
                <Link to={`/servers/${props.name}`}>{props.name}</Link>
            </td>
            <td>{props.environment}</td>
            <td>
                <ServerLastDiscoveredTimestamp lastDiscoveredTimestamp={props.lastDiscoveredTimestamp} />
            </td>
            <td>
                <InfoLink to={`/servers/${props.name}`} title={`Details of ${props.name}`} />
            </td>
        </tr>
    );
}
