import AppManagerClient from '../../api/AppManagerClient';
import React, { useEffect } from 'react';
import { ServerAgentsView } from './ServerAgentsView';
import { IAgent } from '../../api/models';

export interface ServerAgentsProps {
    serverName: string;
}
export const ServerAgents = (props: ServerAgentsProps) => {
    const [agents, setAgents] = React.useState(new Array<IAgent>());
    const [isLoading, setLoadingStatus] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const serverName = props.serverName;

    const getAgents = async (serverName: string) => {
        try {
            const result = await AppManagerClient.getAgentsByServerName(serverName);
            setAgents(result);
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setLoadingStatus(false);
        }
    };

    useEffect(() => {
        getAgents(serverName);
    }, [serverName]);

    return <ServerAgentsView agents={agents} isLoading={isLoading} errorMessage={errorMessage} isDataNotFound={agents.length === 0} serverName={serverName} />;
};
