import { Button, Toast, ToastContainer } from 'react-bootstrap';
import { PopupProps } from '../../areas/shared/Popup';

interface ApplicationServerManagementToastProps {
    popupProps?: PopupProps;
    onClose: () => void;
}
export const ApplicationServerManagementToast = (props: ApplicationServerManagementToastProps) => {
    const popupProps = props.popupProps;
    const onClose = props.onClose;

    if (!popupProps) {
        return null;
    }

    return (
        <>
            <ToastContainer position={popupProps.position ?? 'top-end'} style={{ zIndex: 999, paddingTop: 15 }}>
                <Toast onClose={onClose} show bg={popupProps.type ?? 'Info'} delay={popupProps.delay ?? 5000} autohide={popupProps.autohide ?? true}>
                    <Toast.Header>
                        <small>{popupProps.header ?? ''}</small>
                    </Toast.Header>
                    <Toast.Body>
                        <Button
                            variant="white"
                            onClick={() => {
                                const toCopy = popupProps.toCopy ?? popupProps.body;
                                navigator.clipboard.writeText(toCopy);
                            }}
                        >
                            <i className="fa fa-solid fa-copy"></i>
                        </Button>
                        {popupProps.body}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    );
};
