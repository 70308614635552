class TimeoutItemWrapper<V> {
    public value: V;
    public callback?: (value: V, timedOut: boolean) => void;
    public timeoutId: NodeJS.Timeout | undefined;

    public constructor(value: V, callback?: (value: V, timedOut: boolean) => void) {
        this.value = value;
        this.callback = callback;
    }
}

export class TimeoutList<K, V> {
    private store: Map<K, TimeoutItemWrapper<V>>;

    public constructor(private defaultTimeToLive?: number, private timeoutCallback?: Function, private emptyCallback?: () => void) {
        this.store = new Map<K, TimeoutItemWrapper<V>>();
    }

    public async add(key: K, value: V, callback?: (value: V, timedOut: boolean) => void, timeToLive?: number) {
        const item = new TimeoutItemWrapper<V>(value, callback);

        const myTTL = timeToLive ?? this.defaultTimeToLive ?? -1;

        if (myTTL >= 0) {
            item.timeoutId = setTimeout(() => {
                this.removeInternal(key, true);
            }, myTTL);
        }

        this.store.set(key, item);
    }

    public remove(key: K): boolean {
        return this.removeInternal(key, false);
    }

    private removeInternal(key: K, timedOut: boolean): boolean {
        const item = this.store.get(key);
        if (item) {
            const timeoutId = item.timeoutId as NodeJS.Timeout;
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            if (item.callback) {
                item.callback(item.value, timedOut);
            }
            if (timedOut) {
                if (this.timeoutCallback) {
                    this.timeoutCallback(item.value);
                }
            }

            this.store.delete(key);
            if (this.store.size === 0) {
                if (this.emptyCallback) {
                    this.emptyCallback();
                }
            }
        }

        return item !== undefined;
    }
}
