import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IManagingVirtualAppsInstance } from '../../api/models';
import { ApiDataWrapper } from '../UIExtentions/ApiDataWrapper';

export const VirtualAppsInfoView = (props: any) => {
    return (
        <>
            <ApiDataWrapper
                objectType="Install Info"
                objectName={props.applicationName}
                isLoading={props.isLoading}
                isDataNotFound={props.isDataNotFound}
                errorMessage={props.errorMessage}
            >
                <ServerVirtualAppDetails serverApp={props.serverApps} />
            </ApiDataWrapper>
        </>
    );
};

export function ServerVirtualAppDetails(props: any) {
    const serverApp = props.serverApp as IManagingVirtualAppsInstance;
    return (
        <>
            <Row>
                <Col sm={4}>Site:</Col>
                <Col>
                    <Link to={`/applications/website/${serverApp.siteName}`}>{serverApp.siteName}</Link>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>Application Pool:</Col>
                <Col>
                    <Link to={`/applications/apppool/${serverApp.applicationPool}`}>{serverApp.applicationPool}</Link>
                    <span style={{ lineBreak: 'anywhere' }}></span>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>Username:</Col>
                <Col>{serverApp.username}</Col>
            </Row>
            <Row>
                <Col sm={4}>Enabled Protocols:</Col>
                <Col>{serverApp.enabledProtocols}</Col>
            </Row>
            <Row>
                <Col sm={4}>Physical Path:</Col>
                <Col>{serverApp.physicalPath}</Col>
            </Row>
            <Row>
                <Col sm={4}>Virtual Path:</Col>
                <Col>{serverApp.virtualPath}</Col>
            </Row>
            <Row>
                <Col sm={4}>Preload Enabled:</Col>
                <Col>{String(serverApp.preloadEnabled)}</Col>
            </Row>
        </>
    );
}
