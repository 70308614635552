import appConfig from '../../appConfig';

export interface Name {
    name: string;
}

export const ServerNameSort = (x: Name, y: Name) => {
    return x.name.localeCompare(y.name);
};

const trimDataCenterPrefix = (serverName: string) => {
    const serverPrefix = appConfig.knownDataCenterPrefixes.find((knownPrefix) => serverName.startsWith(knownPrefix));
    if (serverPrefix) {
        return serverName.substring(serverPrefix.length);
    }
    return serverName;
};

export function SortIgnoringDataCenterPrefix<T>(a: T, b: T, getServerName: { (x: T): string }): number {
    const aServerName = getServerName(a);
    const bServerName = getServerName(b);

    const aServerInstance = trimDataCenterPrefix(aServerName);
    const bServerInstance = trimDataCenterPrefix(bServerName);

    if (aServerInstance === bServerInstance) {
        return aServerName.localeCompare(bServerName);
    } else {
        return aServerInstance.localeCompare(bServerInstance);
    }
}
