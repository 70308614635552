import { Badge } from 'react-bootstrap';
import { DateDisplay } from '../Shared/DateDisplay';
import { ActionButton } from '../UIExtentions/Buttons';
import { useEffect, useState } from 'react';
import { IAgent } from '../../api/models';
import { AgentAction } from './AgentAction';
import { AgentClientEventSummary } from '../../signalr/models';
import useRefedProp from '../../hooks/useRefedProp';
import signalREventsClient from '../../signalr/SignalREventsClient';
import { AgentClientEventSummaryHandler, AgentClientEventSummaryHandlerArgs } from './AgentClientEventSummaryHandler';
import { EventType } from '../../constants/EventType';

export interface AgentsTableRowProps {
    agent: IAgent;
    onClickCallback: (action: AgentAction) => Promise<void>;
    onTimeoutCallback: (data: AgentClientEventSummary) => void;
}

export function AgentsTableRow(props: AgentsTableRowProps) {
    const agent = props.agent;
    const [pingSpin, setPingSpin] = useState(false);
    const onTimeoutCallbackRef = useRefedProp(props.onTimeoutCallback);
    const onClickCallback = props.onClickCallback;

    useEffect(() => {
        const agentPingHandler = new AgentClientEventSummaryHandler(agent.serverName, agent.version, {
            addTypes: [EventType.AgentPingTriggered],
            successTypes: [EventType.AgentPingSucceeded],
            failedTypes: [EventType.AgentPingFailed],
            emptyCallback: () => setPingSpin(false),
            addCallback: () => setPingSpin(true),
            timeoutCallback: (data) => onTimeoutCallbackRef.current?.(data),
        } as AgentClientEventSummaryHandlerArgs);

        function isForMe(data: AgentClientEventSummary) {
            return data.targetServer === agent.serverName && data.version === agent.version;
        }

        signalREventsClient.onAgentEvent.setHook(`AgentRow_${agent.serverName}_${agent.version}`, (data) => {
            if (isForMe(data)) {
                agentPingHandler.handleItem(data);
            }
        });
    }, [onTimeoutCallbackRef, agent.serverName, agent.version]);

    return (
        <tr>
            {agent.isPrimary ? (
                <td>
                    <Badge bg="secondary">Primary</Badge>
                </td>
            ) : (
                <td></td>
            )}
            <td>{agent.version}</td>
            <td>{agent.name}</td>
            <td>
                <div className="agent-installed-location" title={agent.installedLocation}>
                    {agent.installedLocation}
                </div>
            </td>
            <td>{agent.runAsAccount}</td>
            <td>
                <DateDisplay dateAsString={agent.createdTimestamp} />
            </td>
            <td>
                <ActionButton
                    disabled={false}
                    spinning={pingSpin}
                    icon="fa-table-tennis-paddle-ball"
                    variant="btn-dark-blue"
                    title="Ping Agent"
                    onClick={() => onClickCallback(AgentAction.Ping)}
                />
            </td>
        </tr>
    );
}
