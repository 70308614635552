export const displayArray = (array: Array<string>): string => {
    if (!array || array.length === 0) {
        return 'none';
    }
    return array.join(', ');
};

export const distinctArray = <T>(array: Array<T> | undefined): Array<T> => {
    if (!array || array.length === 0) {
        return [];
    }

    const set = new Set(array);
    const unique = Array.from(set.values());

    return unique;
};
