import React, { useEffect } from 'react';
import AppManagerClient from '../../api/AppManagerClient';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { ApplicationInstall } from './ApplicationInstall';
import { IManagingWindowsServiceInstance } from '../../api/models';

export interface ApplicationInstallationProps {
    applicationName: string;
    serverApps: IManagingWindowsServiceInstance[];
}

export const ApplicationInstallation = (props: ApplicationInstallationProps) => {
    const [userPrincipal, setUserPrincipal]: [UserPrincipal, Function] = React.useState(new UserPrincipal([]));
    const applicationName = props.applicationName;
    const serverApps = props.serverApps;

    const getUserPrincipal = async (appName: string) => {
        const permissionsResult = await AppManagerClient.getWindowsServiceUserPermissions(appName);
        const myUserPrincipal = new UserPrincipal(permissionsResult);
        setUserPrincipal(myUserPrincipal);
    };

    useEffect(() => {
        getUserPrincipal(applicationName);
    }, [applicationName]);

    return (
        <>
            <div className="float-end">
                <ApplicationInstall applicationName={applicationName} serverApps={serverApps} userPrincipal={userPrincipal} />
            </div>
        </>
    );
};
