import { IServerCertificate } from '../../api/models';
import { useEffect, useState } from 'react';
import { AccordionWrapper } from '../UIExtentions/Accordion';
import { ServerCertificateTable } from './ServerCertificateTable';
import { Col, Container, Row } from 'react-bootstrap';
import { TableFilter } from '../Shared/TableFilter';
import { getEnvSortValue } from '../../helpers/EnvironmentHelpers';

export const sortCertificateInstances = (list: Array<IServerCertificate>): Array<IServerCertificate> => {
    list.sort((a, b) => {
        const aEnv = getEnvSortValue(a.server.environment);
        const bEnv = getEnvSortValue(b.server.environment);
        if (aEnv === bEnv) {
            // order by server name within the same environment
            return a.server.name.localeCompare(b.server.name);
        }
        return bEnv - aEnv;
    });

    return list;
};

export interface CertificatesServerManagementViewProps {
    displayName: string;
    isLoading: boolean;
    isDataNotFound: boolean;
    errorMessage: string;
    serverCertificates: IServerCertificate[];
}

export const ServerCertificateManagementView = (props: CertificatesServerManagementViewProps) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortedServerCerts, setSortedServerCerts] = useState(props.serverCertificates);

    const displayName = props.displayName;

    function handleSearchChange(event: any) {
        const searchValue = event.target.value;
        setSearchTerm(searchValue);
    }

    let filteredServerCerts = sortedServerCerts;
    const searchString = searchTerm.trim().toLowerCase();

    if (filteredServerCerts.length > 0) {
        filteredServerCerts = filteredServerCerts.filter(
            (e: IServerCertificate) =>
                e.server.name?.toLowerCase().match(searchString) ||
                e.server.environment?.toLowerCase().match(searchString) ||
                e.server.lastDiscoveredTimestamp?.toLowerCase().match(searchString) ||
                e.storeLocation?.toLowerCase().match(searchString) ||
                e.storeName?.toLowerCase().match(searchString)
        );
    }

    useEffect(() => {
        const local = [...props.serverCertificates];
        sortCertificateInstances(local);
        setSortedServerCerts(local);
    }, [props.serverCertificates]);

    return (
        <>
            <AccordionWrapper
                isDefaultOpen={true}
                headerIcon="fa-server"
                headerText="Servers"
                objectName={displayName}
                isLoading={props.isLoading}
                errorMessage={props.errorMessage}
            >
                {' '}
                <Container>
                    <Row>
                        <Col>
                            <TableFilter update={(e: any) => handleSearchChange(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ServerCertificateTable displayName={displayName} serverCertificates={filteredServerCerts} />
                        </Col>
                    </Row>
                </Container>
            </AccordionWrapper>
        </>
    );
};
