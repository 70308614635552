import { ButtonGroup, Col, Row } from 'react-bootstrap';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { ButtonWithIcon } from '../UIExtentions/Buttons';
import { Permission as PermissionString } from '../../permissions/permission';
import React, { useCallback, useEffect } from 'react';
import { ScopedUserPrincipal } from '../../permissions/ScopedUserPrincipal';
import { WebSiteAction } from '../../constants/WebSiteAction';

interface WebSitesServerManagementButtonsProps {
    selectedServers: string[];
    userPrincipal: UserPrincipal;
    serversUserPrincipal: ScopedUserPrincipal;
    onClickCallback: (action: WebSiteAction) => Promise<void>;
}
// big buttons
export const WebSitesServerManagementButtons = (props: WebSitesServerManagementButtonsProps) => {
    const selectedServers = props.selectedServers;
    const userPrincipal = props.userPrincipal;
    const serversUserPrincipal = props.serversUserPrincipal;
    const onClickCallback = props.onClickCallback;

    const getEnableState = useCallback(
        function (permission: PermissionString): boolean {
            return !!selectedServers.length && (userPrincipal.hasPermission(permission) || serversUserPrincipal?.hasPermission(selectedServers, permission));
        },
        [selectedServers, serversUserPrincipal, userPrincipal]
    );

    const [canRefresh, setCanRefresh] = React.useState(getEnableState(PermissionString.WebSite_Refresh));

    const UpdatePermissions = useCallback(() => {
        setCanRefresh(getEnableState(PermissionString.WebSite_Refresh));
    }, [getEnableState]);

    useEffect(() => {
        UpdatePermissions();
    }, [UpdatePermissions]);

    return (
        <Row style={{ marginBottom: '5px' }}>
            <Col>
                <ButtonGroup style={{ width: '100%' }}>
                    <ButtonWithIcon
                        disabled={!canRefresh}
                        variant="dark-blue"
                        icon="fa-refresh"
                        textColor="white"
                        text="Refresh"
                        onClick={() => onClickCallback(WebSiteAction.Refresh)}
                    />
                </ButtonGroup>
            </Col>
        </Row>
    );
};
