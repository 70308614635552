import React, { ChangeEvent, useMemo, useState, useEffect } from 'react';
import { Card, Row, Form, Col } from 'react-bootstrap';
import Select, { CSSObjectWithLabel, OnChangeValue } from 'react-select';
import { IApplicationPermission } from '../../api/models';
import { CapabilityMatchType, CapabilityMatcher } from './models';
import { AppCapabilities } from './AppCapabilities';
import { AppType } from '../../constants/AppType';

export interface StatusFilterProps {
    setFilteredAppName: { (appName: string | null): void };
    setFilteredAppTypes: { (appTypes: string[]): void };
    setFilteredAppPermissions: { (owners: IApplicationPermission[]): void };
    setFilteredServers: { (serverNames: string[]): void };
    setCapabilityMatchType: { (matchType: CapabilityMatchType): void };
    setCapabilityMatchers: { (capabilityMatchTypes: CapabilityMatcher[]): void };
    serverNames: string[];
    appPermissions: IApplicationPermission[];
    loadingAppOwners: boolean;
}

export const AppTypeDictionary: Record<string, string> = {
    [AppType.VirtualApp]: 'Web',
    [AppType.WindowsService]: 'Windows Service',
    [AppType.ScheduledTask]: 'Scheduled Task',
};

export const StatusFilter = (props: StatusFilterProps) => {
    const {
        setFilteredAppName,
        setFilteredAppTypes,
        setFilteredAppPermissions,
        setFilteredServers,
        setCapabilityMatchType,
        setCapabilityMatchers,
        appPermissions,
        loadingAppOwners,
    } = props;

    const inlineSelectStyles = {
        control: (provided: CSSObjectWithLabel) => ({
            ...provided,
            minHeight: '20px',
            margin: '-2px 5px 0px 5px',
            padding: '0px',
        }),
        valueContainer: (provided: CSSObjectWithLabel) => ({
            ...provided,
            maxHeight: '20px',
            marginTop: '2px',
            padding: '0 0 0 4px',
        }),
        input: (provided: CSSObjectWithLabel) => ({
            ...provided,
            maxHeight: '20px',
            marginTop: '-2px',
            padding: '1px',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        indicatorsContainer: (provided: CSSObjectWithLabel) => ({
            ...provided,
            height: '20px',
        }),
        dropdownIndicator: (provided: CSSObjectWithLabel) => ({
            ...provided,
            paddingTop: 5,
            paddingBottom: 5,
        }),
    };

    const inputAppNameCacheKey = 'statusFilter-inputAppName';
    const inputAppTypesCacheKey = 'statusFilter-appTypes';
    const inputOwnersCacheKey = 'statusFilter-owners';
    const inputServersCacheKey = 'statusFilter-servers';
    const capabilityTypeCacheKey = 'statusFilter-capabilityType';
    const capabilityMatchersCacheKey = 'statusFilter-capabilityMatchers';

    const [inputAppName, setInputAppName] = useState(localStorage.getItem(inputAppNameCacheKey) ?? undefined);
    const [inputAppTypes, setInputAppTypes] = useState(localStorage.getItem(inputAppTypesCacheKey) ?? undefined);
    const [inputOwners, setInputOwners] = useState(localStorage.getItem(inputOwnersCacheKey) ?? undefined);
    const [inputServers, setInputServers] = useState(localStorage.getItem(inputServersCacheKey) ?? undefined);
    const [inputCapabilityMatchType, setInputCapabilityMatchType] = useState(localStorage.getItem(capabilityTypeCacheKey) ?? undefined);
    const [inputCapabilityMatchers, setInputCapabilityMatchers] = useState(localStorage.getItem(capabilityMatchersCacheKey) ?? undefined);

    useEffect(() => {
        setFilteredAppName(inputAppName ?? null);
    }, [inputAppName, setFilteredAppName]);

    useEffect(() => {
        const selected = JSON.parse(inputAppTypes ?? '[]').map(function (item: any) {
            return item.value;
        });
        setFilteredAppTypes(selected);
    }, [inputAppTypes, setFilteredAppTypes]);

    useEffect(() => {
        if (!loadingAppOwners) {
            const selected = JSON.parse(inputOwners ?? '[]').map(function (item: any) {
                return item.value as string;
            });
            const validAppPermissions = appPermissions.filter((permission) => selected.includes(permission.activeDirectoryGroupName));
            setFilteredAppPermissions(validAppPermissions);
        }
    }, [inputOwners, setFilteredAppPermissions, appPermissions, loadingAppOwners]);

    useEffect(() => {
        const selected = JSON.parse(inputServers ?? '[]').map(function (item: any) {
            return item.value;
        });
        setFilteredServers(selected);
    }, [inputServers, setFilteredServers]);

    useEffect(() => {
        let selected = JSON.parse(inputCapabilityMatchType ?? '{}').value as CapabilityMatchType;
        if (!selected) {
            selected = CapabilityMatchType.Any;
        }
        setCapabilityMatchType(selected);
    }, [inputCapabilityMatchType, setCapabilityMatchType]);

    useEffect(() => {
        const selected = JSON.parse(inputCapabilityMatchers ?? '[]').map(function (item: any) {
            return AppCapabilities[item.label];
        });
        setCapabilityMatchers(selected);
    }, [inputCapabilityMatchers, setCapabilityMatchers]);

    const appOwners = useMemo(() => {
        const owners = props.appPermissions.flatMap((permission) => permission.activeDirectoryGroupName);
        return Array.from(new Set(owners)).sort();
    }, [props.appPermissions]);

    return (
        <Card>
            <Card.Header>Filter</Card.Header>
            <Card.Body>
                <Row>
                    <Col sm="3">
                        <Form.Label htmlFor="status-application-name-filter">Application Name</Form.Label>
                        <Form.Control
                            id="status-application-name-filter"
                            value={inputAppName}
                            style={{ minHeight: '38px' }}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setInputAppName(e.currentTarget.value);
                                localStorage.setItem(inputAppNameCacheKey, e.currentTarget.value);
                            }}
                        />
                    </Col>
                    <Col sm="2">
                        <Form.Label htmlFor="status-application-type-filter">Application Type(s)</Form.Label>
                        <Select
                            id="status-application-type-filter"
                            isMulti={true}
                            placeholder=""
                            value={JSON.parse(inputAppTypes ?? '[]')}
                            options={Object.entries(AppTypeDictionary).map(([key, value]) => ({ label: value, value: key }))}
                            onChange={async (e: OnChangeValue<any, boolean>) => {
                                setInputAppTypes(JSON.stringify(e));
                                localStorage.setItem(inputAppTypesCacheKey, JSON.stringify(e));
                            }}
                        />
                    </Col>
                    <Col sm="2">
                        <Form.Label htmlFor="status-capability-match-filter">
                            <div className="form-group d-flex align-items-center">
                                Matching
                                <Select
                                    id="status-capability-match-select"
                                    placeholder=""
                                    value={inputCapabilityMatchType ? JSON.parse(inputCapabilityMatchType) : { label: 'Any', value: CapabilityMatchType.Any }}
                                    options={[
                                        { label: 'All', value: CapabilityMatchType.All },
                                        { label: 'Any', value: CapabilityMatchType.Any },
                                        { label: 'None', value: CapabilityMatchType.None },
                                    ]}
                                    onChange={(e: OnChangeValue<any, boolean>) => {
                                        setInputCapabilityMatchType(JSON.stringify(e));
                                        localStorage.setItem(capabilityTypeCacheKey, JSON.stringify(e));
                                    }}
                                    styles={inlineSelectStyles}
                                />
                                Capabilities
                            </div>
                        </Form.Label>
                        <Select
                            id="status-capability-match-filter"
                            isMulti={true}
                            placeholder=""
                            value={JSON.parse(inputCapabilityMatchers ?? '[]')}
                            options={Object.entries(AppCapabilities).map(([key, value]) => ({ label: key, value: key }))}
                            onChange={async (e: OnChangeValue<any, boolean>) => {
                                setInputCapabilityMatchers(JSON.stringify(e));
                                localStorage.setItem(capabilityMatchersCacheKey, JSON.stringify(e));
                            }}
                        />
                    </Col>
                    <Col sm="2">
                        <Form.Label htmlFor="status-owner-filter">Owner(s)</Form.Label>
                        <Select
                            id="status-owner-filter"
                            isMulti={true}
                            placeholder={props.loadingAppOwners ? 'Loading...' : ''}
                            value={props.loadingAppOwners ? [] : JSON.parse(inputOwners ?? '[]')}
                            options={appOwners.map((name) => {
                                return { value: name, label: name };
                            })}
                            onChange={async (e: OnChangeValue<any, any>) => {
                                setInputOwners(JSON.stringify(e));
                                localStorage.setItem(inputOwnersCacheKey, JSON.stringify(e));
                            }}
                        />
                    </Col>
                    <Col sm="3">
                        <Form.Label htmlFor="status-server-filter">Server(s)</Form.Label>
                        <Select
                            id="status-server-filter"
                            isMulti={true}
                            placeholder=""
                            value={JSON.parse(inputServers ?? '[]')}
                            options={props.serverNames.map((name) => {
                                return { value: name, label: name };
                            })}
                            onChange={async (e: OnChangeValue<any, any>) => {
                                setInputServers(JSON.stringify(e));
                                localStorage.setItem(inputServersCacheKey, JSON.stringify(e));
                            }}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
