export interface EmptyDataRowProps {
    colSpan?: number;
    message?: string;
}

export const EmptyDataRow = (props: EmptyDataRowProps) => {
    return (
        <tr>
            <td colSpan={props.colSpan}>{props.message ?? 'No data found'}</td>
        </tr>
    );
};
