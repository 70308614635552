export interface Version {
    major: number;
    minor: number;
    build: number;
    revision: number;
}
const parseVersion = (version: string): Version => {
    const majorPart = parseVersionPart(version, 0);
    const minorPart = parseVersionPart(version, majorPart.endIndex + 1);
    const buildPart = parseVersionPart(version, minorPart.endIndex + 1);
    const revisionPart = parseVersionPart(version, buildPart.endIndex + 1);

    return { major: majorPart.version, minor: minorPart.version, build: buildPart.version, revision: revisionPart.version };
};

interface VersionPart {
    endIndex: number;
    version: number;
}
const parseVersionPart = (version: string, startIndex: number): VersionPart => {
    const endIndex = version.indexOf('.', startIndex);
    const versionPart = +version.slice(startIndex, endIndex < 0 ? undefined : endIndex);

    return { endIndex: endIndex, version: versionPart };
};
export { parseVersion };
