import { Button } from 'react-bootstrap';

interface ButtonWithIconProps {
    disabled?: boolean;
    spinning?: boolean;
    variant: string;
    onClick: Function;
    icon: string;
    textColor: string;
    text: string;
}
export const ButtonWithIcon = (props: ButtonWithIconProps) => {
    const disabled = props.spinning || props.disabled;
    const icon = 'fa ' + (props.spinning ? 'fa-spinner fa-spin fa-fw' : props.icon);
    return (
        <Button disabled={disabled} variant={props.variant ?? 'secondary'} onClick={() => props.onClick()}>
            <h3>
                <i className={icon} style={{ color: props.textColor ?? 'black' }}></i>
            </h3>
            <span style={{ color: props.textColor ?? 'black' }}>{props.text}</span>
        </Button>
    );
};

interface ActionButtonProps {
    disabled?: boolean;
    spinning?: boolean;
    variant: string;
    onClick: Function;
    icon: string;
    title: string;
    outlined?: boolean;
}
export const ActionButton = (props: ActionButtonProps) => {
    const disabled = props.spinning || props.disabled;
    const icon = 'fa ' + (props.spinning ? 'fa-spinner fa-spin fa-fw' : props.icon);
    const btnClass = 'btn action-button' + (props.outlined ? '-outlined' : '');
    return (
        <button disabled={disabled} className={btnClass + ' ' + props.variant} title={props.title} onClick={() => props.onClick()}>
            <i className={icon}></i>
        </button>
    );
};

interface ActionButtonWithTextProps {
    disabled?: boolean;
    variant: string;
    onClick: Function;
    title: string;
    outlined?: boolean;
}
export const ActionButtonWithText = (props: ActionButtonWithTextProps) => {
    const disabled = props.disabled;
    const btnClass = 'btn action-button' + (props.outlined ? '-outlined' : '');
    return (
        <button disabled={disabled} className={btnClass + ' ' + props.variant} title={props.title} onClick={() => props.onClick()}>
            <i>{props.title}</i>
        </button>
    );
};
