import { IApplication, IManagingApplicationInstance, IManagingAppPoolsInstance } from '../../api/models';

export interface ApplicationStatusModel extends IApplication {
    typeDisplay?: string;
    appInstances?: IManagingApplicationInstance[];
    appPoolName?: string;
    appPools?: IManagingAppPoolsInstance[];
}

export enum CapabilityMatchType {
    Any = 'any',
    All = 'all',
    None = 'none',
}

export type CapabilityMatcher = { (applicationStatusModel: ApplicationStatusModel): boolean };
