import { ButtonGroup, Col, Row } from 'react-bootstrap';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { ButtonWithIcon } from '../UIExtentions/Buttons';
import { Permission as PermissionString } from '../../permissions/permission';
import React, { useCallback, useEffect } from 'react';
import { ScopedUserPrincipal } from '../../permissions/ScopedUserPrincipal';
import { ScheduledTaskAction } from '../../constants/ScheduledTaskAction';

interface ScheduledTasksServerManagementButtonsProps {
    selectedServers: string[];
    userPrincipal: UserPrincipal;
    serversUserPrincipal: ScopedUserPrincipal;
    onClickCallback: (action: ScheduledTaskAction) => Promise<void>;
}
// big buttons
export const ScheduledTasksServerManagementButtons = (props: ScheduledTasksServerManagementButtonsProps) => {
    const selectedServers = props.selectedServers;
    const userPrincipal = props.userPrincipal;
    const serversUserPrincipal = props.serversUserPrincipal;
    const onClickCallback = props.onClickCallback;

    const getEnableState = useCallback(
        function (permission: PermissionString): boolean {
            return !!selectedServers.length && (userPrincipal.hasPermission(permission) || serversUserPrincipal?.hasPermission(selectedServers, permission));
        },
        [selectedServers, serversUserPrincipal, userPrincipal]
    );

    const [canRefresh, setCanRefresh] = React.useState(getEnableState(PermissionString.ScheduledTask_Refresh));
    const [canStart, setCanStart] = React.useState(getEnableState(PermissionString.ScheduledTask_Start));
    const [canStop, setCanStop] = React.useState(getEnableState(PermissionString.ScheduledTask_Stop));
    const [canEnable, setCanEnable] = React.useState(getEnableState(PermissionString.ScheduledTask_Enable));
    const [canDisable, setCanDisable] = React.useState(getEnableState(PermissionString.ScheduledTask_Disable));

    const UpdatePermissions = useCallback(() => {
        setCanRefresh(getEnableState(PermissionString.ScheduledTask_Refresh));
        setCanStart(getEnableState(PermissionString.ScheduledTask_Start));
        setCanStop(getEnableState(PermissionString.ScheduledTask_Stop));
        setCanEnable(getEnableState(PermissionString.ScheduledTask_Enable));
        setCanDisable(getEnableState(PermissionString.ScheduledTask_Disable));
    }, [getEnableState]);

    useEffect(() => {
        UpdatePermissions();
    }, [UpdatePermissions]);

    return (
        <Row style={{ marginBottom: '5px' }}>
            <Col>
                <ButtonGroup style={{ width: '100%' }}>
                    <ButtonWithIcon
                        disabled={!canStart}
                        variant="green"
                        icon="fa-play-circle"
                        textColor="white"
                        text="Start"
                        onClick={() => onClickCallback(ScheduledTaskAction.Start)}
                    />
                    <ButtonWithIcon
                        disabled={!canStop}
                        variant="red"
                        icon="fa-stop-circle"
                        textColor="white"
                        text="Stop"
                        onClick={() => onClickCallback(ScheduledTaskAction.Stop)}
                    />
                </ButtonGroup>
            </Col>
            <Col>
                <ButtonGroup style={{ width: '100%' }}>
                    <ButtonWithIcon
                        disabled={!canEnable}
                        variant="orange"
                        icon="fa-square-check"
                        textColor="white"
                        text="Enable"
                        onClick={() => onClickCallback(ScheduledTaskAction.Enable)}
                    />
                    <ButtonWithIcon
                        disabled={!canDisable}
                        variant="dark-grey"
                        icon="fa-ban"
                        textColor="white"
                        text="Disable"
                        onClick={() => onClickCallback(ScheduledTaskAction.Disable)}
                    />
                </ButtonGroup>
            </Col>
            <Col>
                <ButtonGroup style={{ width: '100%' }}>
                    <ButtonWithIcon
                        disabled={!canRefresh}
                        variant="dark-blue"
                        icon="fa-refresh"
                        textColor="white"
                        text="Refresh"
                        onClick={() => onClickCallback(ScheduledTaskAction.Refresh)}
                    />
                </ButtonGroup>
            </Col>
        </Row>
    );
};
