import { Col, Container, Row, Stack } from 'react-bootstrap';

export function Error404(props : any) {
    return (
            <Stack gap={3}>
            <Container className="text-center">
                <Row>
                    <Col><h3><i className="fa fa-exclamation-triangle fa-fw"></i><i> {props.objectName}</i> Not Found</h3></Col>                    
                </Row>
            </Container>
            </Stack>
    );
}