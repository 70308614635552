import { useMsal } from '@azure/msal-react';
import { LoadingOverlay } from '@quad/bootstrap-react';
import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import appConfig from '../../appConfig';
import ScrollPageContent from '../shared/ScrollPageContent';

const Signout = () => {
    const { instance } = useMsal();

    useEffect(() => {
        instance.logoutRedirect({ postLogoutRedirectUri: `${appConfig.publicUrl}/signed-out` });
    }, [instance]);

    return (
        <>
            <ScrollPageContent>
                <Container fluid>
                    <h1>Signout redirect...</h1>
                    <LoadingOverlay />
                </Container>
            </ScrollPageContent>
        </>
    );
};

export default Signout;
