import { StandardLayout, TopNav, TopNavQuadSiteLogo } from '@quad/bootstrap-react';
import { Link } from 'react-router-dom';
import NoScrollPageContent from '../shared/NoScrollPageContent';

const SignedOut = () => {
    return (
        <StandardLayout topNav={<TopNav siteLogo={<TopNavQuadSiteLogo />} />}>
            <NoScrollPageContent>
                <div className="d-flex flex-column flex-fill justify-content-center align-items-center">
                    <h2>You have been signed out...</h2>
                    <Link to="/home">
                        <span>Sign In</span>
                    </Link>
                </div>
            </NoScrollPageContent>
        </StandardLayout>
    );
};

export default SignedOut;
