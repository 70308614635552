export enum KnownMergerResult {
    Multiple = 'multiple',
}

export abstract class Merger {
    public static merge(objs: any[]): any {
        const merged: { [k: string]: any } = {};
        if (objs.length !== 0) {
            const first = objs[0];
            Object.keys(first).forEach((key) => {
                merged[key] = objs.every((element) => {
                    if (typeof element[key] === 'string' && typeof first[key] === 'string') {
                        // compare strings but ignoring case sensitivity
                        return element[key].localeCompare(first[key], 'en', { sensitivity: 'base' }) === 0;
                    }
                    return element[key] === first[key];
                })
                    ? first[key]
                    : KnownMergerResult.Multiple;
            });
        }
        return merged;
    }
}
