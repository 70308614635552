import { Col, Container, Row, Stack } from 'react-bootstrap';

export interface LoadingProps {
    text?: string;
}

export const Loading = (props: LoadingProps) => {
    const loadingText = props.text?.length ? `Loading ${props.text}...` : 'Loading...';

    return (
        <Stack gap={3}>
            <Container className="text-center">
                <Row>
                    <Col>
                        <h3>
                            <i className="fa fa-spinner fa-spin fa-fw"></i>
                            {loadingText}
                        </h3>
                    </Col>
                </Row>
            </Container>
        </Stack>
    );
};
