import { IApplication } from '../../api/models';
import React, { useEffect } from 'react';
import { AccordionWrapper } from '../UIExtentions/Accordion';
import ApplicationsTable from '../Applications/ApplicationsTable';
import { ApplicationTableButtons } from '../../constants/ApplicationTableItems';

interface ServerApplicationListingViewProps {
    serverName: string;
    applications: IApplication[];
    isLoading: boolean;
    errorMessage: string;
    isDataNotFound: boolean;
}

export const ServerApplicationListingView = (props: ServerApplicationListingViewProps) => {
    const [applications, setApplications]: [IApplication[], Function] = React.useState(props.applications);
    const serverName = props.serverName;
    const hiddenButtons: [columnNames: ApplicationTableButtons] = [ApplicationTableButtons.Delete];

    useEffect(() => {
        const local = [...props.applications];
        setApplications(local);
    }, [props.applications]);

    return (
        <>
            <AccordionWrapper
                isDefaultOpen={true}
                headerIcon="fa-server"
                headerText="Applications"
                objectName={serverName}
                isLoading={props.isLoading}
                errorMessage={props.errorMessage}
            >
                <ApplicationsTable data={applications} maxHeight={250} hiddenButtons={hiddenButtons} />
            </AccordionWrapper>
        </>
    );
};
