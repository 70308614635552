import { useState, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IApplication } from '../../api/models';
import { ApplicationTableColumns } from '../../constants/ApplicationTableItems';
import { AppType } from '../../constants/AppType';
import { useAppMakeSelector } from '../../hooks/useAppSelector';
import { Permission as PermissionString } from '../../permissions/permission';
import userPrincipalSelectors from '../../store/userPrincipal/selectors';
import { Loading } from '../Shared/Loading';
import { TableFilter } from '../Shared/TableFilter';
import ApplicationsCreate from './ApplicationsCreate';
import ApplicationsTable from './ApplicationsTable';

// TODO it is duplicated code and temporary solution, but in future we want to replace it with some React table
// with pagination, searching, filtering ordering etc.

export interface ApplicationsEnhancedTableProps {
    applicationType?: string;
    data: IApplication[];
    isLoading: boolean;
    deleteAppHandler: (appName: string) => void;
}

export function ApplicationsEnhancedTable(props: ApplicationsEnhancedTableProps) {
    const applicationType = props.applicationType;
    const hiddenColumns: [columnNames: ApplicationTableColumns] = [ApplicationTableColumns.Type];

    const [state, setState] = useState({ search: '' });
    const hasCreateApplicationPermission = useAppMakeSelector(userPrincipalSelectors.makeSelectHasPermission, PermissionString.Root_Applications);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const searchValue = event.target.value;
        setState({ search: searchValue });
    }

    const searchedData = useMemo(() => {
        const searchString = state.search.trim().toLowerCase();
        if (searchString.length > 0) {
            return props.data.filter((e: IApplication) => e.name.toLowerCase().includes(searchString) || e.type.toLowerCase().includes(searchString));
        }
        return props.data;
    }, [state.search, props.data]);

    if (props.isLoading) {
        return <Loading />;
    }

    return (
        <div>
            <Row>
                <Col>
                    <TableFilter update={handleChange} />
                </Col>
                {applicationType === AppType.WindowsService ? (
                    <Col className="col-1">
                        <ApplicationsCreate spining={false} disabled={!hasCreateApplicationPermission} applicationType={applicationType} />
                    </Col>
                ) : (
                    <></>
                )}
            </Row>
            <ApplicationsTable data={searchedData} deleteAppHandler={props.deleteAppHandler} hiddenColumns={hiddenColumns} />
        </div>
    );
}
