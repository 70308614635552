import { PropsWithChildren } from 'react';
import { Alert } from 'react-bootstrap';

export type ActionAlertProps = PropsWithChildren<{
    variant: string;
    key: string;
}>;

function GetIcon(variant: string) {
    switch (variant) {
        case 'info':
            return 'fa-info-circle';
        case 'warning':
            return 'fa-exclamation-triangle';
        case 'danger':
            return 'fa-exclamation-circle';
        default:
            return '';
    }
}

export function ActionAlert(props: ActionAlertProps) {
    const icon = GetIcon(props.variant);

    return (
        <Alert variant={props.variant} style={{ marginBottom: '0px', display: 'flex' }}>
            <span>
                <i className={`fa ${icon}`}></i>
            </span>
            &nbsp; &nbsp;
            {props.children}
        </Alert>
    );
}
