import { IManagingWindowsServiceInstance } from '../../api/models';
import { AppEnvironment } from '../../constants/AppEnvironment';
import { ServiceStartType } from '../../constants/ServiceStartType';
import { IStyledText, StyledText } from '../UIExtentions/Text';

function GetStartTypeProps(startType: string, environment: AppEnvironment): IStyledText {
    switch (startType) {
        case ServiceStartType.Automatic:
            return {
                text: 'Auto',
                className: environment === AppEnvironment.Production ? 'text-success' : null,
            };
        case ServiceStartType.Manual:
            return {
                text: 'Manual',
                className: environment === AppEnvironment.Production ? 'text-orange' : null,
            };
        case ServiceStartType.Disabled:
            return {
                text: 'Disabled',
                className: 'text-muted',
            };
        default:
            return {
                text: startType,
            };
    }
}

export default function WindowsServiceStartType(serverApp: IManagingWindowsServiceInstance) {
    const startTypeProps = GetStartTypeProps(serverApp.startType, serverApp.environment as AppEnvironment);

    return <StyledText {...startTypeProps} />;
}