import { useCallback, useEffect, useState } from 'react';
import AppManagerClient from '../../api/AppManagerClient';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { ActionButton } from '../UIExtentions/Buttons';
import { Permission as PermissionString } from '../../permissions/permission';
import { ConfirmationModal, VerbiageType } from '../Shared/ConfirmationModal';
import { Col, Row } from 'react-bootstrap';
import { KnownMergerResult, Merger } from '../../helpers/Merger';
import { ApiNonSuccessError, IManagingWindowsServiceInstance, IName } from '../../api/models';
import { KnownRunAsUsername } from '../../constants/KnownRunAsUsername';
import { ValidatableInput } from '../Shared/ValidatableInput';
import { ValidatableSelect } from '../Shared/ValidatableSelect';
import _ from 'lodash';
import { ValidationErrors } from '../Shared/ValidationErrors';

interface ApplicationInstallProps {
    applicationName: string;
    serverApps: IManagingWindowsServiceInstance[];
    userPrincipal: UserPrincipal;
}

export const ApplicationInstall = (props: ApplicationInstallProps) => {
    const [spinning, setSpinning] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedServers, setSelectedServers] = useState(Array<string>());
    const [binPath, setBinPath] = useState('');
    const [runAsUsername, setRunAsUsername] = useState<string>('');
    const [runAsPassword, setRunAsPassword] = useState<string>('');
    const [shouldUpdatePassword, setShouldUpdatePassword] = useState(false);
    const [serverOptions, setServerOptions] = useState<any>();
    const [fieldsValidity, setFieldsValidity] = useState(new Map<string, boolean>());
    const [valid, setValid] = useState(false);
    const [apiErrors, setApiErrors] = useState(Array<string>());

    const applicationName = props.applicationName;
    const userPrincipal = props.userPrincipal;
    const serverApps = props.serverApps;
    const hasInstallPermission = userPrincipal.hasPermission(PermissionString.WindowsService_Install);

    const setKnownFields = useCallback(async () => {
        const mergedResult = Merger.merge(serverApps) as IManagingWindowsServiceInstance;
        if (mergedResult.binPath !== KnownMergerResult.Multiple) {
            setBinPath(mergedResult.binPath);
        }
        if (mergedResult.runAsUser !== KnownMergerResult.Multiple && mergedResult.runAsUser !== KnownRunAsUsername.LocalSystem) {
            setRunAsUsername(mergedResult.runAsUser);
        }
    }, [serverApps]);

    const getAllServers = async () => {
        const allServerFromClient = await AppManagerClient.getAllServerNames();
        const allServers = allServerFromClient.map(function (server: IName) {
            return { value: server.name, label: server.name };
        });

        setServerOptions(allServers);
    };

    const showConfirmationDialog = async () => {
        if (!serverOptions) {
            setSpinning(true);
            await getAllServers();
            setSpinning(false);
        }
        setShowConfirmation(true);
    };

    const setFieldValidity = function (fieldName: string, valid: boolean) {
        const fieldsValidityCopy = new Map(fieldsValidity);
        fieldsValidityCopy.set(fieldName, valid);
        setFieldsValidity(fieldsValidityCopy);
    };

    const onSelectedServersChanged = function (selectedServers: Array<string>) {
        setSelectedServers(selectedServers);
        if (!shouldUpdatePassword) {
            let passwordToSet = '';
            if (selectedServers.length > 0) {
                const selectedServersInstallInfos = _.filter(serverApps, (item: IManagingWindowsServiceInstance) => {
                    return _.includes(selectedServers, item.serverName);
                });
                if (selectedServers.length === selectedServersInstallInfos.length) {
                    const allSelectedHasPassword = _.every(selectedServersInstallInfos, (item: IManagingWindowsServiceInstance) => {
                        return item.hasPassword;
                    });
                    if (allSelectedHasPassword) {
                        passwordToSet = '********';
                    }
                }
            }
            setRunAsPassword(passwordToSet);
        }
    };

    const onClose = function () {
        setRunAsPassword('');
        setShouldUpdatePassword(false);
        setShowConfirmation(false);
        setApiErrors(Array<string>());
    };

    useEffect(() => {
        setKnownFields();
    }, [setKnownFields]);

    useEffect(() => {
        const valid = Array.from(fieldsValidity.values()).every((valid) => valid);
        setValid(valid);
    }, [fieldsValidity]);

    const installActionHandler = async function (
        serviceName: string,
        servers: string[],
        binPath: string,
        shouldUpdatePassword: boolean,
        runAsUsername?: string,
        runAsPassword?: string
    ) {
        try {
            await AppManagerClient.postWindowsServiceInstallAction(serviceName, servers, binPath, shouldUpdatePassword, runAsUsername, runAsPassword);
            onClose();
        } catch (error) {
            const apiError = error as ApiNonSuccessError;
            const message = apiError.message;
            setApiErrors([message]);
        }
    };

    return (
        <>
            <ActionButton
                disabled={!hasInstallPermission}
                spinning={spinning}
                outlined={true}
                icon="fa-plus"
                variant="btn-green"
                title="Install"
                onClick={async () => showConfirmationDialog()}
            />
            <ConfirmationModal
                title={`${applicationName} - Install`}
                show={showConfirmation}
                enabled={valid}
                verbiageType={VerbiageType.OkCancel}
                onCancel={() => onClose()}
                onConfirm={async () => {
                    await installActionHandler(applicationName, selectedServers, binPath, shouldUpdatePassword, runAsUsername, runAsPassword);
                }}
            >
                <Row>
                    <Col>
                        <p className="bg-warning text-center">
                            <i className={'fa fa-warning'}></i>
                            <b> Windows service install is legacy feature. Please consider using azure pipelines for installations.</b>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ValidationErrors errors={apiErrors} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ValidatableSelect
                            fieldName={'Servers*'}
                            options={serverOptions}
                            isMulti={true}
                            onChange={(value: string[]) => onSelectedServersChanged(value)}
                            validate={(value: string[]) => {
                                const errors = [];
                                if (value.length === 0) {
                                    errors.push('At least one server must be selected.');
                                }

                                setFieldValidity('Servers', errors.length === 0);
                                return errors;
                            }}
                            values={[]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ValidatableInput
                            fieldName={'Bin Path*'}
                            value={binPath}
                            placeholder={"Exe path e.g. 'F:\\QuadServices\\TestService\\TestService.exe'"}
                            onChange={(value: string) => setBinPath(value)}
                            validate={(value: string) => {
                                const errors = [];
                                if (value === undefined || value.length === 0) {
                                    errors.push('Bin path is required.');
                                } else if (value.toUpperCase().startsWith('C')) {
                                    errors.push('Bin path cannot start with system drive letter.');
                                }

                                setFieldValidity('BinPath', errors.length === 0);
                                return errors;
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <ValidatableInput
                        fieldName={'Username'}
                        value={runAsUsername}
                        placeholder={"With domain name, e.g. 'qg\\TestServiceAccount_' or 'qg\\TestServiceAccount$'"}
                        onChange={(value: string) => setRunAsUsername(value)}
                        validate={(value: any) => []}
                    />
                </Row>
                <Row>
                    <ValidatableInput
                        fieldName={'Password'}
                        type={'password'}
                        value={runAsPassword}
                        onChange={(value: string) => {
                            if (runAsPassword !== value) {
                                setShouldUpdatePassword(true);
                                setRunAsPassword(value);
                            }
                        }}
                        validate={(value: any) => []}
                    />
                </Row>
            </ConfirmationModal>
        </>
    );
};
