import { ActionButton } from '../UIExtentions/Buttons';
import { Item } from './Item';

interface ItemListRowProps<T extends Item> {
    item: T;
    canModify: boolean;
    onDelete: (item: T) => void;
}
export function ItemListRow<T extends Item>(props: ItemListRowProps<T>) {
    const item = props.item;
    const canModify = props.canModify;

    return (
        <tr>
            <td>{item.forDisplay()}</td>
            <td>
                <ActionButton
                    disabled={!canModify}
                    icon="fa-trash"
                    variant="btn-red"
                    title="Delete"
                    onClick={() => {
                        props.onDelete(item);
                    }}
                />
            </td>
        </tr>
    );
}
