import { dateToLocal, stringToDate } from '../../helpers/DateHelpers';
import { ValidCertificateDateDisplay } from './ValidCertificateDateDisplay';

export interface ValidToDateDisplayProps {
    date: string;
}

export const ValidToDateDisplay = ({ date }: ValidToDateDisplayProps) => {
    const today = new Date();
    const certDate = stringToDate(date) as Date;
    const nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
    const formattedDate = dateToLocal(certDate);
    const isValid = today < (certDate as Date);
    const willExpireSoon = today <= (certDate as Date) && nextWeek > (certDate as Date);
    const validTextColor = willExpireSoon ? 'text-warning' : 'text-success';
    const textColor = isValid ? validTextColor : 'text-danger';
    const validIcon = willExpireSoon ? 'fa fa-exclamation-triangle' : 'fa fa-check-circle';
    const icon = isValid ? validIcon : 'fa fa-exclamation-circle';
    const validMessage = willExpireSoon ? 'Certificate will expire soon!' : 'Valid';
    const title = isValid ? validMessage : 'Certificate is expired!';

    return (
        <>
            <ValidCertificateDateDisplay textColor={textColor} icon={icon} certDate={certDate} title={title} formattedDate={formattedDate} />
        </>
    );
};
