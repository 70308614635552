import AppManagerClient from '../../api/AppManagerClient';
import { IApplication } from '../../api/models';
import React, { useCallback, useEffect } from 'react';
import { WebSiteApplicationListingView } from './WebSiteApplicationListingView';
import { ApplicationNameSort } from '../Applications/ApplicationSorting';

interface WebSiteApplicationListingProps {
    webSiteName: string;
}

export const WebSiteApplicationListing = (props: WebSiteApplicationListingProps) => {
    const [apps, setApps]: [IApplication[], Function] = React.useState([]);
    const [isLoading, setLoadingStatus] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const webSiteName = props.webSiteName;

    const getApplications = useCallback(async (webSiteName: string) => {
        try {
            const applicationsResult = await AppManagerClient.getVirtualAppsByWebSiteName(webSiteName);
            setApps(applicationsResult.sort(ApplicationNameSort));
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setLoadingStatus(false);
        }
    }, []);

    useEffect(() => {
        getApplications(webSiteName);
    }, [getApplications, webSiteName]);

    return <WebSiteApplicationListingView applications={apps} isLoading={isLoading} errorMessage={errorMessage} isDataNotFound={apps.length === 0} webSiteName={webSiteName} />;
};
