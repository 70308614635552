import { useEffect, useState } from 'react';
import { Checkbox } from '../../areas/app/Checkbox';
import { WindowsServicesServerRowActions } from './WindowsServicesServerRowActions';
import WindowsServiceStartType from './WindowsServiceStartType';
import WindowsServiceState from './WindowsServiceState';
import { IManagingWindowsServiceInstance } from '../../api/models';
import { ServiceAction } from '../../constants/ServiceAction';
import { ServiceStartType } from '../../constants/ServiceStartType';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { ServerApplicationClientEventSummary } from '../../signalr/models';
import { ApplicationLastDiscoveredTimestamp } from '../ApplicationServerManagement/ApplicationLastDiscoveredTimestamp';

export interface WindowsServicesServerRowProps {
    selected: boolean;
    selectionChangedCallback: Function;
    serverApp: IManagingWindowsServiceInstance;
    userPrincipal: UserPrincipal;
    serverUserPrincipal: UserPrincipal;
    onClickCallback: (action: ServiceAction, startType?: ServiceStartType) => Promise<void>;
    onTimeoutCallback: (data: ServerApplicationClientEventSummary) => void;
}

export function WindowsServicesServerRow(props: WindowsServicesServerRowProps) {
    const [selected, setSelected] = useState(false);

    const serverApp = props.serverApp;

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    return (
        <tr>
            <td>
                <Checkbox selected={selected} selectionChangedCallback={(checked: boolean) => props.selectionChangedCallback(checked, serverApp.serverName)} />
            </td>
            <td className="details-link">
                <ApplicationLastDiscoveredTimestamp serverApp={serverApp} />
            </td>
            <td>{serverApp.environment}</td>
            <td>
                <WindowsServiceStartType {...serverApp} />
            </td>
            <td>
                <WindowsServiceState {...serverApp} />
            </td>
            <WindowsServicesServerRowActions
                serverApp={serverApp}
                userPrincipal={props.userPrincipal}
                serverUserPrincipal={props.serverUserPrincipal}
                onClickCallback={props.onClickCallback}
                onTimeoutCallback={props.onTimeoutCallback}
            ></WindowsServicesServerRowActions>
        </tr>
    );
}
