import { useEffect, useState } from 'react';
import AppManagerClient from '../../api/AppManagerClient';
import { IManagingWindowsServiceInstance } from '../../api/models';
import { WindowsServicesServerManagementView } from './WindowsServicesServerManagementView';
import { AccordionWrapper } from '../UIExtentions/Accordion';
import { WindowsServiceEnvironmentComparison } from '../Comparison/WindowsServiceEnvironmentComparison';
import { Stack } from 'react-bootstrap';

export const WindowsServicesServerManagement = (props: any) => {
    const [serverApps, setServerApps] = useState(Array<IManagingWindowsServiceInstance>());
    const [isLoading, setLoadingStatus] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const applicationName = props.applicationName;

    const getServers = async (appName: string) => {
        try {
            const serversResult = await AppManagerClient.getWindowsServiceForManaging(appName);
            setServerApps(serversResult);
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setLoadingStatus(false);
        }
    };

    useEffect(() => {
        getServers(applicationName);
    }, [applicationName]);

    return (
        <Stack gap={3}>
            <AccordionWrapper
                fitToSize={true}
                headerIcon="fa-code-compare"
                headerText="Environment Comparison"
                objectName={applicationName}
                isLoading={props.isLoading}
                errorMessage={props.errorMessage}
            >
                <WindowsServiceEnvironmentComparison data={serverApps} />
            </AccordionWrapper>

            <WindowsServicesServerManagementView
                serverApps={serverApps}
                userPrincipal={props.userPrincipal}
                isLoading={isLoading}
                errorMessage={errorMessage}
                isDataNotFound={serverApps.length === 0}
                applicationName={applicationName}
            />
        </Stack>
    );
};
