import { ItemListTable } from '../ItemList/ItemListTable';
import _ from 'lodash';
import { AllowedSystemLocationItem } from './AllowedSystemLocationItem';
import { useCallback, useEffect, useState } from 'react';
import { AllowedSystemLocationsAddModal } from './AllowedSystemLocationsAddModal';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { Permission } from '../../permissions/permission';
import { tryCallApi } from '../../api/Helpers';
import AppManagerClient from '../../api/AppManagerClient';
import { IServer } from '../../api/models';

interface AllowedSystemLocationsViewProps {
    userPrincipal: UserPrincipal;
    server: IServer;
}

export const AllowedSystemLocationsView = (props: AllowedSystemLocationsViewProps) => {
    const locationsSeparator = ';';
    const header = 'Allowed System Locations';
    const [currentLocations, setCurrentLocations] = useState(Array<AllowedSystemLocationItem>());
    const [showAddModal, setShowAddModal] = useState(false);

    const userPrincipal = props.userPrincipal;
    const server = props.server;

    const setAllowedSystemLocationItems = (allowedSystemLocations?: string) => {
        if (allowedSystemLocations === null) {
            return;
        }

        const locations = allowedSystemLocations?.split(locationsSeparator);
        const locationItems = _.map(locations, (location) => new AllowedSystemLocationItem(location));
        setCurrentLocations(locationItems);
    };

    const apiUpdateAllowedSystemLocations = useCallback(
        async (allowedSystemLocations: string | null): Promise<boolean> => {
            return await tryCallApi(async () => await AppManagerClient.putServerProperties(server.name, { allowedSystemLocations: allowedSystemLocations }));
        },
        [server.name]
    );

    const getAllowedSystemLocationItems = (locations: Array<AllowedSystemLocationItem>): string | null => {
        if (locations.length === 0) {
            return null;
        }
        const locationNames = _.map(locations, (location) => location.value);
        const allowedSystemLocations = _.join(locationNames, locationsSeparator);
        return allowedSystemLocations;
    };

    const updateLocations = async (locationItems: Array<AllowedSystemLocationItem>) => {
        const allowedSystemLocations = getAllowedSystemLocationItems(locationItems);
        if (await apiUpdateAllowedSystemLocations(allowedSystemLocations)) {
            setCurrentLocations(locationItems);
        }
    };

    const addLocation = async (location: AllowedSystemLocationItem) => {
        const alreadyContains = _.some(currentLocations, function (currentLocation) {
            return currentLocation.value.toLowerCase() === location.value.toLowerCase();
        });
        if (alreadyContains) {
            return;
        }

        const newLocationItems = [...currentLocations];
        newLocationItems.push(location);

        await updateLocations(newLocationItems);
    };

    const deleteLocation = async (location: AllowedSystemLocationItem) => {
        const newLocationItems = currentLocations.filter((currentLocation) => currentLocation.value !== location.value);
        await updateLocations(newLocationItems);
    };

    useEffect(() => {
        setAllowedSystemLocationItems(server.allowedSystemLocations);
    }, [server.allowedSystemLocations]);

    return (
        <>
            <ItemListTable
                title={header}
                addIcon="fa-folder-plus"
                items={currentLocations}
                canModify={userPrincipal.hasPermission(Permission.Server_UpdateCustomProperties)}
                onAdd={() => setShowAddModal(true)}
                onDelete={async (location) => await deleteLocation(location)}
            />
            <AllowedSystemLocationsAddModal show={showAddModal} onAdded={async (location) => await addLocation(location)} onHide={() => setShowAddModal(false)} />
        </>
    );
};
