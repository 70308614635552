import { AppEnvironment } from '../constants/AppEnvironment';

export const getEnvSortValue = (environment: string): number => {
    switch (environment.toLowerCase()) {
        case AppEnvironment.Production.toLowerCase():
            return 3;
        case AppEnvironment.Beta.toLowerCase():
            return 2;
        default:
            return 1;
    }
};

export const getAppEnvSortValue = (environment: AppEnvironment): number => {
    switch (environment) {
        case AppEnvironment.Production:
            return 3;
        case AppEnvironment.Beta:
            return 2;
        default:
            return 1;
    }
};
