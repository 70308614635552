import { PublicClientApplication } from '@azure/msal-browser';
import { combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import appConfig, { msalConfig } from './appConfig';
import reducers from './reducers';
import { Action } from './store/shared/models';
import { UserPrincipalState } from './store/userPrincipal/models';

const combinedReducers = combineReducers<RootState>({ ...reducers });

const store = configureStore({
    reducer: combinedReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: appConfig.appEnvironment !== 'Production',
});

export interface RootState {
    userPrincipal: UserPrincipalState;
}

export type AppDispatch = typeof store.dispatch;
export type AppThunkAction<TPayload = any> = ThunkAction<Promise<void>, RootState, unknown, Action<TPayload>>;

const msalInstance = new PublicClientApplication(msalConfig);

export { store, msalInstance };
