import { ICertificateAccessRight } from '../../api/models';

export interface CertificateAccessRightTableRowProps {
    accessRight: ICertificateAccessRight;
}

export function CertificateAccessRightTableRow(props: CertificateAccessRightTableRowProps) {
    const accessRight = props.accessRight;

    return (
        <tr>
            <td>{accessRight.identity}</td>
            <td>{accessRight.rights}</td>
            <td>{accessRight.accessControlType}</td>
        </tr>
    );
}
