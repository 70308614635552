import AppManagerClient from '../../api/AppManagerClient';
import { ApiNonSuccessError, IManagingApplicationInstance, IManagingWindowsServiceInstance, IPostApplicationActionResponse } from '../../api/models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PopupProps } from '../../areas/shared/Popup';
import { AccordionWrapper } from '../UIExtentions/Accordion';
import { Loading } from '../Shared/Loading';
import { WindowsServicesServerManagementButtons } from './WindowsServicesServerManagementButtons';
import { WindowsServicesServerTable } from './WindowsServicesServerTable';
import { ApplicationServerManagementToast } from '../ApplicationServerManagement/ApplicationServerManagementToast';
import { ServerApplicationClientEventSummary, ServerPermissionClientEventSummary } from '../../signalr/models';
import signalREventsClient from '../../signalr/SignalREventsClient';
import _ from 'lodash';
import { ApplicationInstallation } from '../ApplicationInstallation/ApplicationInstallation';
import { ScopedUserPrincipal } from '../../permissions/ScopedUserPrincipal';
import { AppType } from '../../constants/AppType';
import { displayArray } from '../../helpers/arrayHelpers';
import { ServiceAction } from '../../constants/ServiceAction';
import { ServiceStartType } from '../../constants/ServiceStartType';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { filter } from '../Shared/Filter';
import { Search } from '../Shared/Search';
import { Col, Container, Row } from 'react-bootstrap';
import { nameof } from 'ts-simple-nameof';
import { getAppEnvSortValue } from '../../helpers/EnvironmentHelpers';

export const sortWindowsServiceInstances = (list: Array<IManagingWindowsServiceInstance>): Array<IManagingWindowsServiceInstance> => {
    list.sort((a, b) => {
        const aEnv = getAppEnvSortValue(a.environment);
        const bEnv = getAppEnvSortValue(b.environment);
        if (aEnv === bEnv) {
            // order by server name within the same environment
            return a.serverName.localeCompare(b.serverName);
        }
        return bEnv - aEnv;
    });

    return list;
};

export interface WindowsServiceActionHandlerProps {
    serviceName: string;
    servers: string[];
    action: ServiceAction;
    startType?: ServiceStartType;
}

export interface WindowsServicesServerManagementViewProps {
    userPrincipal: UserPrincipal;
    applicationName: string;
    isLoading: boolean;
    isDataNotFound: boolean;
    errorMessage: string;
    serverApps: IManagingWindowsServiceInstance[];
}

export const WindowsServicesServerManagementView = (props: WindowsServicesServerManagementViewProps) => {
    const [servers, setServers] = useState(new Array<string>());
    const [allSelected, setAllSelected] = useState(false);
    const [serverApps, setServerApps] = useState(props.serverApps);
    const [popupProps, setPopupProps] = useState<PopupProps | undefined>();
    const [serversUserPrincipal, setServersUserPrincipal] = useState(new ScopedUserPrincipal([]));
    const [searchTerm, setSearchTerm] = useState('');

    const applicationName = props.applicationName;

    const filteredServerApps = useMemo(() => {
        const filterFields = [
            nameof<IManagingWindowsServiceInstance>((f) => f.serverName),
            nameof<IManagingWindowsServiceInstance>((f) => f.environment),
            nameof<IManagingWindowsServiceInstance>((f) => f.startType),
            nameof<IManagingWindowsServiceInstance>((f) => f.state),
        ];
        return filter(serverApps, searchTerm, filterFields);
    }, [searchTerm, serverApps]);

    const getServersUserPrincipal = async (serverApps: IManagingWindowsServiceInstance[]) => {
        const servers = _.map(serverApps, (serverApp) => serverApp.serverName);
        const permissionsResult = await AppManagerClient.getApplicationUserPermissionsForServers(AppType.WindowsService, servers);
        const myServersUserPrincipal = new ScopedUserPrincipal(permissionsResult);
        setServersUserPrincipal(myServersUserPrincipal);
    };

    const serverSelectionChanged = useCallback(
        function (selected: boolean, serverName: string) {
            let updatedServers = [...servers];

            if (selected === true) {
                updatedServers.push(serverName);
            } else {
                updatedServers = updatedServers.filter((x) => x !== serverName);
            }

            setServers(updatedServers);
        },
        [servers]
    );

    useEffect(() => {
        getServersUserPrincipal(serverApps);
    }, [serverApps]);

    useEffect(() => {
        function isForMe(data: ServerPermissionClientEventSummary) {
            const servers = _.map(serverApps, (serverApp) => serverApp.serverName);
            return servers.includes(data.targetServer);
        }

        signalREventsClient.onServerPermissionEvent.setHook(`AppPoolsServerManagementView_${applicationName}`, (data) => {
            if (isForMe(data)) {
                getServersUserPrincipal(serverApps);
            }
        });
    }, [applicationName, serverApps]);

    useEffect(() => {
        const local = [...props.serverApps];
        sortWindowsServiceInstances(local);
        setServerApps(local);
    }, [props.serverApps]);

    useEffect(() => {
        function isForMe(data: ServerApplicationClientEventSummary) {
            return data.targetApplication === applicationName;
        }

        const refreshServerApp = async function (targetAppName: string, serverName: string) {
            const refreshedApps = await AppManagerClient.getWindowsServiceForManagingByServers(targetAppName, [serverName]);

            //update server list
            const updatedServerApps = serverApps.map((s) => {
                return refreshedApps.find((app) => s.name === app.name && s.serverName === app.serverName) ?? s;
            });

            //add new servers
            refreshedApps.forEach((newApp) => {
                if (updatedServerApps.find((app) => app.name === newApp.name && app.serverName === newApp.serverName) === undefined) {
                    updatedServerApps.push(newApp);
                }
            });

            //remove not found servers
            if (refreshedApps.find((missingApp) => targetAppName === missingApp.name && serverName === missingApp.serverName) === undefined) {
                const removed = _.remove(updatedServerApps, (app) => app.name === targetAppName && app.serverName === serverName);
                if (removed.length !== 0) {
                    serverSelectionChanged(false, serverName);
                }
            }

            sortWindowsServiceInstances(updatedServerApps);
            setServerApps(updatedServerApps);
        };

        signalREventsClient.onWindowsServiceEvent.setHook(`ApplicationServerManagementView_${applicationName}`, (data) => {
            if (isForMe(data)) {
                if (data.isSuccess) {
                    refreshServerApp(data.targetApplication, data.targetServer);
                }
                if (data.isFailure) {
                    showPopup({
                        header: `Operation on [${data.targetServer}] failed.`,
                        body: `[${data.type}]: ${data.errorMessage}`,
                        type: 'danger',
                        autohide: true,
                    });
                }
            }
        });
    }, [applicationName, serverApps, serverSelectionChanged]);

    if (serverApps) {
        const allServersSelected = serverApps.every((s: IManagingApplicationInstance) => servers.includes(s.serverName));
        if (allServersSelected !== allSelected) {
            setAllSelected(allServersSelected);
        }
    }

    const allServersSelectionChanged = function (selected: boolean) {
        const allServers = serverApps.map((s: IManagingApplicationInstance) => {
            return s.serverName;
        });
        if (selected) {
            setServers(allServers);
        } else {
            setServers([]);
        }
    };

    const windowsServiceActionHandler = async function (props: WindowsServiceActionHandlerProps) {
        await tryWithErrorHandler(props.action, () => AppManagerClient.postWindowsServiceAction(props.serviceName, props.action, props.servers, props.startType));
    };

    const tryWithErrorHandler = async function (action: ServiceAction, funct: () => Promise<IPostApplicationActionResponse>) {
        try {
            const postResult = await funct();

            showPopup({
                header: `Successfully sent action [${action}] to agent.`,
                toCopy: postResult.correlationId,
                body: `Messages: ${displayArray(postResult.messages)}. Warnings: ${displayArray(postResult.warnings)}`,
            });
        } catch (error) {
            const apiError = error as ApiNonSuccessError;
            showPopup({
                header: `Error (${apiError?.status}) while calling [${action}].`,
                type: 'danger',
                body: `Error: ${apiError?.message} `,
            });
        }
    };

    const onTimeout = function (data: ServerApplicationClientEventSummary) {
        showPopup({
            header: `Operation timed out on [${data.targetServer}].`,
            body: `Operation: [${data.type}]`,
            type: 'danger',
            autohide: true,
        });
    };

    const showPopup = function (props: PopupProps) {
        setPopupProps(props);
    };

    return (
        <>
            <ApplicationServerManagementToast popupProps={popupProps} onClose={() => setPopupProps(undefined)} />

            {props.isLoading ? (
                <Loading text="Server Management" />
            ) : (
                <WindowsServicesServerManagementButtons
                    selectedServers={servers}
                    userPrincipal={props.userPrincipal}
                    serversUserPrincipal={serversUserPrincipal}
                    onClickCallback={async (action: ServiceAction, startType?: ServiceStartType) =>
                        await windowsServiceActionHandler({
                            serviceName: applicationName,
                            servers: servers,
                            action: action,
                            startType: startType,
                        })
                    }
                />
            )}

            <AccordionWrapper
                isDefaultOpen={true}
                headerIcon="fa-server"
                headerText="Servers"
                objectName={applicationName}
                isLoading={props.isLoading}
                errorMessage={props.errorMessage}
            >
                <Container>
                    <Row>
                        <Col sm="11">
                            <Search onUpdate={setSearchTerm} />
                        </Col>
                        <Col>
                            <ApplicationInstallation applicationName={applicationName} serverApps={serverApps} />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <WindowsServicesServerTable
                                userPrincipal={props.userPrincipal}
                                serversUserPrincipal={serversUserPrincipal}
                                allServersSelectionChanged={allServersSelectionChanged}
                                serverSelectionChanged={serverSelectionChanged}
                                onClickCallback={windowsServiceActionHandler}
                                serverApps={filteredServerApps}
                                servers={servers}
                                allSelected={allSelected}
                                onTimeoutCallback={onTimeout}
                            />
                        </Col>
                    </Row>
                </Container>
            </AccordionWrapper>
        </>
    );
};
