import { LoadingOverlay } from '@quad/bootstrap-react';
import { PropsWithChildren } from 'react';
import { AccessDenied } from '../../components/Shared/AccessDenied';
import { useAppMakeSelector } from '../../hooks/useAppSelector';
import { Permission } from '../../permissions/permission';
import userPrincipalSelectors from '../../store/userPrincipal/selectors';

export type AuthorizationWrapperProps = PropsWithChildren<{
    permission: Permission;
    isLoading: boolean;
    showAccessDenied: boolean;
}>;

export function AuthorizationWrapper({ permission, isLoading, showAccessDenied, children }: AuthorizationWrapperProps) {
    const hasPermission = useAppMakeSelector(userPrincipalSelectors.makeSelectHasPermission, permission);

    if (isLoading) {
        return <LoadingOverlay />;
    }

    if (hasPermission) {
        return <>{children}</>;
    }

    if (showAccessDenied) {
        return <AccessDenied />;
    }

    return null;
}
