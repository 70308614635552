import { useMemo } from 'react';
import { IApplicationPermission } from '../../api/models';
import { ApplicationStatusModel } from './models';
import { RoleType } from '../../constants/RoleType';
import { TooltipTable } from '../Shared/TooltipTable';
import { useGetUniqueId } from '@quad/bootstrap-react';
import { groupBy } from 'lodash';
import { createSelector } from 'reselect';

export interface OwnersDisplayProps {
    application: ApplicationStatusModel;
    applications: ApplicationStatusModel[];
    allAppPermissions: IApplicationPermission[];
}

interface DisplayInformation {
    display: JSX.Element;
    tableHeaders: string[];
    tableRows: (string | JSX.Element)[][];
}

export const OwnersDisplay = (props: OwnersDisplayProps) => {
    const { application } = props;

    const elementId = useGetUniqueId('owner-');

    const appToPermissionGroup = selectAppToPermissionGroup(props);

    const ownerInformation = useMemo((): DisplayInformation | null => {
        let appPermissions = appToPermissionGroup.get(application.name);

        if (!appPermissions?.length) {
            return null;
        }

        appPermissions = appPermissions.sort((a, b) => {
            if (a.role === b.role) {
                return a.activeDirectoryGroupName.localeCompare(b.activeDirectoryGroupName);
            }
            return b.role.localeCompare(a.role);
        });

        const owners = appPermissions.filter((permission) => permission.role === RoleType.Owner);

        let ownerText = owners.map((permission) => permission.activeDirectoryGroupName).join(',');
        if (!ownerText) {
            ownerText = 'Contributers Only (No Owner)';
        }

        const rowElements = appPermissions.map((permission) => [permission.activeDirectoryGroupName, permission.role]);

        return {
            display: <>{ownerText}</>,
            tableHeaders: ['AD Group', 'Role'],
            tableRows: rowElements,
        };
    }, [application, appToPermissionGroup]);

    return (
        <>
            {ownerInformation ? (
                <span id={elementId}>
                    {ownerInformation.display}
                    <TooltipTable elementId={elementId} headers={ownerInformation.tableHeaders} rows={ownerInformation.tableRows} />
                </span>
            ) : (
                <></>
            )}
        </>
    );
};

const selectAppToPermissionGroup = createSelector(
    [(props: OwnersDisplayProps) => props.applications, (props: OwnersDisplayProps) => props.allAppPermissions],
    (applications, allAppPermissions) => {
        const appPermissions = new Map<string, { role: string; activeDirectoryGroupName: string }[]>();

        for (const app of applications) {
            let permissionsForApp = allAppPermissions.filter((permission) => permission.applicationName?.toLowerCase() === app.name?.toLowerCase());

            if (!permissionsForApp?.length && app.appPoolName) {
                //check app pool
                permissionsForApp = allAppPermissions.filter((permission) => permission.applicationName?.toLowerCase() === app.appPoolName?.toLowerCase());
            }

            const distinct = Object.values(groupBy(permissionsForApp, (permission) => `${permission.activeDirectoryGroupName}|${permission.role}`)).map((x) => ({
                role: x[0].role,
                activeDirectoryGroupName: x[0].activeDirectoryGroupName,
            }));

            appPermissions.set(app.name, distinct);
        }

        return appPermissions;
    }
);
