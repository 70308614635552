import { Collection } from 'lodash';
import { IManagingWebSitesInstance } from '../../api/models';
import { ApplicationInstanceEnvironmentComparison } from './ApplicationInstanceEnvironmentComparison';
import { RowDefinition } from './ComparisonTable';

interface WebSitesEnvironmentComparisonProps {
    data: IManagingWebSitesInstance[];
}

export const WebSitesEnvironmentComparison = ({ data }: WebSitesEnvironmentComparisonProps) => {
    const rows = [
        { rowName: 'State', property: 'state' },
        { rowName: 'Auto Start', property: 'autoStart' },
        { rowName: 'LogFile Directory', property: 'logFileDirectory' },
        { rowName: 'Bindings', property: 'bindings' },
        { rowName: '-', property: '', customDisplayValueFunction: (values: Collection<{ value: string; count: number }>) => '' },
    ] as RowDefinition<IManagingWebSitesInstance>[];

    return <ApplicationInstanceEnvironmentComparison data={data} rows={rows} />;
};
