import { Table } from 'react-bootstrap';
import { EmptyDataRow } from '../UIExtentions/TableUtilities';
import { ValueDisplay } from './ValueDisplay';
import { Collection } from 'lodash';
import { StatusIconProps } from '../Shared/StatusIcon';
import { DetailsColor } from './DetailsColor';

export function lowestDateDisplayValueFunction(values: Collection<{ value: string; count: number }>): string {
    const lowestDate = values.minBy((value) => {
        return new Date(value.value);
    });

    return lowestDate?.value ? new Date(lowestDate.value).toLocaleString() : '';
}

export interface ColumnDefinition {
    columnName: string;
    columnValue: string;
    compareColumnValue: string;
    className: string;
}

export interface RowDefinition<T> {
    rowName: string;
    property: keyof T;
    customDisplayValueFunction?: (values: Collection<{ value: string; count: number }>) => string;
    customDisplayDetailsValueFunction?: (value: string) => string;
    customStatusFunction?: (value: string) => StatusIconProps['statusType'];
    customDetailsStatusColorFunction?: (value: string) => DetailsColor;
}

export interface ComparisonTableProps<T> {
    columns: Array<ColumnDefinition>;
    filterProperty: keyof T;
    detailsBy: keyof T;
    rowName: string;
    rows: Array<RowDefinition<T>>;
    data: T[];
}

const getHeaders = (columns: Array<ColumnDefinition>): JSX.Element[] => {
    const headers: JSX.Element[] = [];

    columns.forEach((column) => {
        headers.push(
            <th key={`status-header-${column.columnName}`} scope="col" className={column.className} style={{ color: 'white' }}>
                {column.columnName}
            </th>
        );
    });

    return headers;
};

export function ComparisonTable<T>({ columns, filterProperty, detailsBy, rows, rowName, data }: ComparisonTableProps<T>) {
    const headers = getHeaders(columns);

    return (
        <Table striped size="sm">
            <thead>
                <tr>
                    <th style={{ textAlign: 'center' }}>{rowName}</th>
                    {headers !== null && headers.length > 0 ? headers.map((header: JSX.Element) => header) : <></>}
                </tr>
            </thead>
            <tbody>
                <>
                    {rows === null ? (
                        <EmptyDataRow colSpan={1 + headers.length} />
                    ) : (
                        rows.map((row) => {
                            return (
                                <tr key={`${row.property as string}`}>
                                    <td>{row.rowName}</td>
                                    {columns.map((column) => {
                                        return (
                                            <td key={column.columnName}>
                                                <ValueDisplay
                                                    valueProperty={row.property}
                                                    detailsByProperty={detailsBy}
                                                    detailsByPropertyDisplayName={'Value'}
                                                    data={data}
                                                    filterProperty={filterProperty}
                                                    filterPropertyValue={column.columnValue}
                                                    compareToFilterPropertyValue={column.compareColumnValue}
                                                    customDisplayValueFunction={row.customDisplayValueFunction}
                                                    customDisplayDetailsValueFunction={row.customDisplayDetailsValueFunction}
                                                    customStatusFunction={row.customStatusFunction}
                                                    customDetailsStatusColorFunction={row.customDetailsStatusColorFunction}
                                                />
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })
                    )}
                </>
            </tbody>
        </Table>
    );
}
