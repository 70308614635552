import { PageHeading } from '@quad/bootstrap-react';
import { groupBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import AppManagerClient from '../../api/AppManagerClient';
import { IApplication, IApplicationPermission, IManagingAppPoolsInstance, IManagingVirtualAppsInstance } from '../../api/models';
import ScrollPageContent from '../../areas/shared/ScrollPageContent';
import { AppTypeDictionary, StatusFilter } from '../../components/ApplicationStatus/StatusFilter';
import { StatusTable } from '../../components/ApplicationStatus/StatusTable';
import { ApplicationStatusModel, CapabilityMatchType, CapabilityMatcher } from '../../components/ApplicationStatus/models';
import { Loading } from '../../components/Shared/Loading';
import { AppType } from '../../constants/AppType';
import withHasPermission from '../../hoc/withHasPermission';
import { useApplications } from '../../hooks/useApplications';
import { Permission } from '../../permissions/permission';

const sortApplications = (x: IApplication, y: IApplication): number => {
    const typeCompare = x.type.localeCompare(y.type);
    return typeCompare === 0 ? x.name.localeCompare(y.name) : typeCompare;
};

const supportedAppTypes = [AppType.WindowsService, AppType.VirtualApp, AppType.ScheduledTask];

const loadScheduledTaskAndServiceApplications = async (allApplications: IApplication[]): Promise<ApplicationStatusModel[]> => {
    const allApps = allApplications.filter((x) => x.type !== AppType.VirtualApp);

    const allAppDetails = (
        await Promise.all([
            AppManagerClient.getMyManagingApplicationsByType(AppType.ScheduledTask), //
            AppManagerClient.getMyManagingApplicationsByType(AppType.WindowsService),
        ])
    ).flat();

    const appStatuses = allApps.map((application) => {
        const appDetails = allAppDetails.filter((app) => app.name === application.name && app.type === application.type);

        const applicationStatus: ApplicationStatusModel = {
            ...application,
            appInstances: appDetails,
            typeDisplay: AppTypeDictionary[application.type],
        };

        return applicationStatus;
    });

    return appStatuses;
};

const loadVirtualApplications = async (allApplications: IApplication[]): Promise<ApplicationStatusModel[]> => {
    const virtualApps = allApplications.filter((x) => x.type === AppType.VirtualApp);

    const [virtualAppDetails, appPoolDetails] = await Promise.all([
        AppManagerClient.getMyManagingApplicationsByType(AppType.VirtualApp) as Promise<IManagingVirtualAppsInstance[]>,
        AppManagerClient.getMyManagingApplicationsByType(AppType.AppPool) as Promise<IManagingAppPoolsInstance[]>,
    ]);

    const virtualAppsWithDetails = virtualApps.map((application) => {
        const appDetails = virtualAppDetails.filter((appDtl) => appDtl.name?.toLowerCase() === application.name?.toLowerCase());

        return { application, appPoolName: appDetails[0]?.applicationPool, appDetails };
    });

    const appStatuses = Object.values(
        groupBy(virtualAppsWithDetails, (x) => (x.appPoolName ? `AppPool|${x.appPoolName.toLowerCase()}` : `AppName|${x.appPoolName.toLowerCase()}`))
    ).map((appGroup) => {
        // Wcf's seem to be a parent virtual app (the top site with a Healthcheck.aspx) and a child virtual app (the subsite with the WCF's dlls)
        // They also seem to share the same app pool between that parent and child site
        // Objective here is to combine those, show the name of the parent app, but the details of the child app
        //   FlexibleScheduleManagerWcf (parent, use this name)
        //   FelixbleScheduleManagerWcf/FelixbleScheduleManagerWcf (child, use these details)
        // Web apps should just be 1:1, will see both parent and sub virtual apps.
        //   webapps18.qg.com
        //   webapps18.qg.com-CRASS
        const appPoolName = appGroup[0].appPoolName;
        const { application } = appGroup.find((x) => !x.application.displayName.includes('/')) ?? appGroup[0];
        const { appDetails } = appGroup.find((x) => x.application.displayName.includes('/')) ?? appGroup[0];
        const appPools = appPoolName ? appPoolDetails.filter((x) => x.name?.toLowerCase() === appPoolName.toLowerCase()) : [];

        const applicationStatus: ApplicationStatusModel = {
            ...application,
            appPoolName,
            appPools,
            appInstances: appDetails,
            typeDisplay: AppTypeDictionary[application.type],
        };

        return applicationStatus;
    });

    return appStatuses;
};

export const ApplicationStatus = withHasPermission(() => {
    const [appDetailsAreLoading, setAppDetailsAreLoading] = useState(true);
    const [appOwnersAreLoading, setAppOwnersAreLoading] = useState(true);
    const [applicationStatuses, setApplicationStatuses] = useState(Array<ApplicationStatusModel>());
    const [filteredAppTypes, setFilteredAppTypes] = useState(Array<string>());
    const [filteredServerNames, setFilteredServerNames] = useState(Array<string>());
    const [filteredAppPermissions, setFilteredAppPermissions] = useState(Array<IApplicationPermission>());
    const [filteredAppName, setFilteredAppName] = useState<string | null>(null);
    const [appPermissions, setAppPermissions] = useState(Array<IApplicationPermission>());
    const [capabilityMatchType, setCapabilityMatchType] = useState<CapabilityMatchType>(CapabilityMatchType.Any);
    const [filteredAppCapabilities, setFilteredAppCapabilities] = useState(Array<CapabilityMatcher>());

    const { applications, isLoading: appsAreLoading } = useApplications(supportedAppTypes, true, sortApplications);

    useEffect(() => {
        if (appsAreLoading) {
            return;
        }

        if (!applications.length) {
            setAppDetailsAreLoading(false);
            return;
        }

        Promise.all([
            loadScheduledTaskAndServiceApplications(applications), //
            loadVirtualApplications(applications),
        ]).then((appStatuss) => {
            const appStatus = appStatuss.flat();

            setApplicationStatuses(appStatus);
            setAppDetailsAreLoading(false);
        });
    }, [appsAreLoading, applications]);

    const serverNames = useMemo(() => {
        const servers = applicationStatuses
            .filter((app: ApplicationStatusModel) => app.appInstances)
            .flatMap((app: ApplicationStatusModel) => app.appInstances!.map((appInstance) => appInstance.serverName));
        return Array.from(new Set(servers)).sort();
    }, [applicationStatuses]);

    useEffect(() => {
        //don't care as much about this information so load it after the page has loaded
        if (applicationStatuses.length > 0) {
            AppManagerClient.getAllUserApplicationPermissions().then((appPermissions) => {
                setAppPermissions(appPermissions);
                setAppOwnersAreLoading(false);
            });
        }
    }, [applications, applicationStatuses]);

    return (
        <ScrollPageContent>
            <PageHeading>
                <div className="col">
                    <h1>
                        <i className={'fa fa-signal'}></i> {'Application Status Dashboard'}
                    </h1>
                </div>
            </PageHeading>
            <Container fluid>
                {appDetailsAreLoading ? (
                    <Loading text={appsAreLoading ? 'Applications' : 'Application Details'} />
                ) : (
                    <div>
                        <StatusFilter
                            key="app-status-filter"
                            serverNames={serverNames}
                            appPermissions={appPermissions}
                            loadingAppOwners={appOwnersAreLoading}
                            setFilteredAppTypes={setFilteredAppTypes}
                            setFilteredServers={setFilteredServerNames}
                            setFilteredAppPermissions={setFilteredAppPermissions}
                            setFilteredAppName={setFilteredAppName}
                            setCapabilityMatchType={setCapabilityMatchType}
                            setCapabilityMatchers={setFilteredAppCapabilities}
                        />
                        <StatusTable
                            key="app-status-table"
                            applications={applicationStatuses}
                            allAppPermissions={appPermissions}
                            filteredAppName={filteredAppName}
                            filteredAppTypes={filteredAppTypes}
                            filteredAppPermissions={filteredAppPermissions}
                            filteredServers={filteredServerNames}
                            capabilityMatchType={capabilityMatchType}
                            filteredAppCapabilities={filteredAppCapabilities}
                        />
                    </div>
                )}
            </Container>
        </ScrollPageContent>
    );
}, Permission.Root_Applications);
