import { Collection } from 'lodash';
import { IManagingVirtualAppsInstance } from '../../api/models';
import { ApplicationInstanceEnvironmentComparison } from './ApplicationInstanceEnvironmentComparison';
import { RowDefinition } from './ComparisonTable';

interface VirtualAppsEnvironmentComparisonProps {
    data: IManagingVirtualAppsInstance[];
}

export const VirtualAppsEnvironmentComparison = ({ data }: VirtualAppsEnvironmentComparisonProps) => {
    const rows = [
        { rowName: 'Application Pool', property: 'applicationPool' },
        { rowName: 'Site Name', property: 'siteName' },
        { rowName: 'Virtual Path', property: 'virtualPath' },
        { rowName: 'Physical Path', property: 'physicalPath' },
        { rowName: 'Username', property: 'username' },
        { rowName: 'Enabled Protocols', property: 'enabledProtocols' },
        { rowName: 'Preload Enabled', property: 'preloadEnabled' },
        { rowName: '-', property: '', customDisplayValueFunction: (values: Collection<{ value: string; count: number }>) => '' },
    ] as RowDefinition<IManagingVirtualAppsInstance>[];

    return <ApplicationInstanceEnvironmentComparison data={data} rows={rows} />;
};
