import AppManagerClient from '../../api/AppManagerClient';
import { IEventLog } from '../../api/models';
import { EventLogView } from '../EventLog/EventLogView';
import React, { useEffect } from 'react';
import { ApplicationPermissionClientEventSummary, ServerApplicationClientEventSummary } from '../../signalr/models';
import signalREventsClient from '../../signalr/SignalREventsClient';
import { Merger } from '../../helpers/Merger';
import { EventType } from '../../constants/EventType';
import { AppType } from '../../constants/AppType';

export const VirtualAppEventLog = (props: any) => {
    const [eventList, setEventList]: [IEventLog[], Function] = React.useState([]);
    const [isLoading, setLoadingStatus] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const applicationName = props.applicationName;
    const [webSiteName, setWebSiteName] = React.useState('');

    const getWebSiteName = async (appName: string) => {
        try {
            const serversResult = await AppManagerClient.getVirtualAppsForManaging(appName);
            setWebSiteName(Merger.merge(serversResult).siteName);
        } catch (err: any) {
            err.message;
        }
    };

    useEffect(() => {
        function isForMe(data: ServerApplicationClientEventSummary | ApplicationPermissionClientEventSummary) {
            return (
                (data.targetApplication === applicationName && data.targetApplicationType === AppType.VirtualApp) ||
                (data.targetApplication === webSiteName && data.targetApplicationType === AppType.WebSite)
            );
        }

        signalREventsClient.onWebSiteEvent.setHook(`ApplicationEventLog_${webSiteName}`, (data) => {
            if (isForMe(data)) {
                getEventLogs(data.targetApplication, webSiteName);
            }
        });
    }, [applicationName, webSiteName]);

    const getEventLogs = async (appName: string, siteName: string | null) => {
        const filterEventTypes = `('${EventType.WebSiteRefreshTriggered}', '${EventType.WebSiteRefreshSucceeded}', '${EventType.WebSiteRefreshFailed}')`;
        const filterCriteria = `((targetApplication eq '${appName}' and targetApplicationType eq '${AppType.VirtualApp}') or (targetApplication eq '${siteName}' and targetApplicationType eq '${AppType.WebSite}' and type in ${filterEventTypes}))`;
        const queryVirtualApp = `$filter= ${filterCriteria} &$select=type,createdTimestamp,operation,targetServer,summary&$orderby=CreatedTimestamp desc&$expand=User($select=DisplayName)`;
        try {
            const eventResult = await AppManagerClient.getEvents(queryVirtualApp);
            setEventList(eventResult);
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setLoadingStatus(false);
        }
    };

    useEffect(() => {
        getWebSiteName(applicationName);
        getEventLogs(applicationName, webSiteName);
    }, [applicationName, webSiteName]);

    return <EventLogView eventList={eventList} isLoading={isLoading} errorMessage={errorMessage} isDataNotFound={false} objectName={webSiteName} />;
};
