import React, { useMemo } from 'react';
import { IApplicationPermission } from '../../api/models';
import { ApplicationStatusModel, CapabilityMatchType, CapabilityMatcher } from './models';
import { Table, Card } from 'react-bootstrap';
import { ApplicationLink } from './ApplicationLink';
import { OwnersDisplay } from './OwnersDisplay';
import { StatusDisplay } from './StatusDisplay';
import { VersionDisplay } from './VersionDisplay';
import { AppEnvironment } from '../../constants/AppEnvironment';
import { EmptyDataRow } from '../UIExtentions/TableUtilities';

export interface StatusTableProps {
    applications: Array<ApplicationStatusModel>;
    allAppPermissions: Array<IApplicationPermission>;
    filteredAppTypes: Array<string>;
    filteredServers: Array<string>;
    filteredAppPermissions: Array<IApplicationPermission>;
    filteredAppName: string | null;
    capabilityMatchType: CapabilityMatchType;
    filteredAppCapabilities: Array<CapabilityMatcher>;
}

const SecondHeaderRow: React.CSSProperties = {
    top: '35.5px',
};

const envHeaders = () => {
    const headers: JSX.Element[] = [];

    for (let i = 0; i < 3; i++) {
        headers.push(
            <th key={`env-status-header-${i}`} scope="col" className="col-1" style={SecondHeaderRow}>
                Status
            </th>
        );
        headers.push(
            <th key={`env-version-header-${i}`} scope="col" className="col-1" style={SecondHeaderRow}>
                Version
            </th>
        );
    }

    return headers;
};

export const StatusTable = (props: StatusTableProps) => {
    const columnsPerEnv = envHeaders().length / 3;

    const filteredApplications = useMemo(() => {
        return props.applications
            .filter((application) => {
                let isValid = props.filteredAppName === null || application.displayName.toLowerCase().includes(props.filteredAppName.trim().toLowerCase());

                if (isValid && props.filteredAppTypes.length) {
                    isValid = props.filteredAppTypes.includes(application.type);
                }
                if (isValid && props.filteredServers.length) {
                    isValid = application.appInstances?.some((instance) => props.filteredServers.includes(instance.serverName)) ?? false;
                }
                if (isValid && props.filteredAppPermissions.length) {
                    isValid = props.filteredAppPermissions.some((permission) => permission.applicationName === application.name);
                }
                if (isValid && props.filteredAppCapabilities.length) {
                    if (props.capabilityMatchType === CapabilityMatchType.All) {
                        isValid = props.filteredAppCapabilities.every((capabilityMatcher) => capabilityMatcher(application));
                    } else if (props.capabilityMatchType === CapabilityMatchType.Any) {
                        isValid = props.filteredAppCapabilities.some((capabilityMatcher) => capabilityMatcher(application));
                    } else if (props.capabilityMatchType === CapabilityMatchType.None) {
                        isValid = props.filteredAppCapabilities.every((capabilityMatcher) => !capabilityMatcher(application));
                    } else {
                        throw new Error(`Not Implemented! CapabilityMatchType: ${props.capabilityMatchType}`);
                    }
                }

                return isValid;
            })
            .sort((a, b) => {
                if (a.typeDisplay === b.typeDisplay) {
                    return a.displayName.localeCompare(b.displayName);
                }
                return a.typeDisplay?.localeCompare(b.typeDisplay!) ?? 1;
            });
    }, [
        props.applications,
        props.filteredAppName,
        props.filteredAppPermissions,
        props.filteredAppTypes,
        props.filteredServers,
        props.filteredAppCapabilities,
        props.capabilityMatchType,
    ]);

    return (
        <Card>
            <Card.Body>
                <Table striped size="sm">
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}></th>
                            <th style={{ textAlign: 'center' }}></th>
                            <th style={{ textAlign: 'center' }}></th>
                            <th colSpan={columnsPerEnv} style={{ textAlign: 'center' }} className={'btn-orange'}>
                                Development
                            </th>
                            <th colSpan={columnsPerEnv} style={{ textAlign: 'center' }} className={'btn-green'}>
                                Beta
                            </th>
                            <th colSpan={columnsPerEnv} style={{ textAlign: 'center', backgroundColor: 'purple', color: 'white' }}>
                                Production
                            </th>
                        </tr>
                        <tr>
                            <th scope="col" className="col-1" style={SecondHeaderRow}>
                                Application
                            </th>
                            <th scope="col" className="col-1" style={SecondHeaderRow}>
                                Type
                            </th>
                            <th scope="col" className="col-1" style={SecondHeaderRow}>
                                Owner(s)
                            </th>
                            {envHeaders()}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredApplications !== null && filteredApplications.length > 0 ? (
                            filteredApplications.map((application: ApplicationStatusModel) => {
                                return (
                                    <tr key={application.name}>
                                        <td>
                                            <ApplicationLink application={application} />
                                        </td>
                                        <td>{application.typeDisplay}</td>
                                        <td>
                                            <OwnersDisplay application={application} applications={props.applications} allAppPermissions={props.allAppPermissions} />
                                        </td>
                                        <td>
                                            <StatusDisplay application={application} environment={AppEnvironment.Development} />
                                        </td>
                                        <td>
                                            <VersionDisplay application={application} environment={AppEnvironment.Development} postEnvironment={AppEnvironment.Beta} />
                                        </td>
                                        <td>
                                            <StatusDisplay application={application} environment={AppEnvironment.Beta} />
                                        </td>
                                        <td>
                                            <VersionDisplay application={application} environment={AppEnvironment.Beta} postEnvironment={AppEnvironment.Production} />
                                        </td>
                                        <td>
                                            <StatusDisplay application={application} environment={AppEnvironment.Production} />
                                        </td>
                                        <td>
                                            <VersionDisplay application={application} environment={AppEnvironment.Production} />
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <EmptyDataRow colSpan={3 + columnsPerEnv * 3} />
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};
