import { Link } from 'react-router-dom';
import { IManagingApplicationInstance } from '../../api/models';
import { ActionButton } from '../UIExtentions/Buttons';
import { InfoModal } from '../Shared/InfoModal';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { DateDisplay } from '../Shared/DateDisplay';
import { GetAppDiscoveryStatus } from './ApplicationServerDiscoveryStatus';
import { useState } from 'react';

interface ApplicationLastDiscoveredTimestampProps {
    serverApp: IManagingApplicationInstance;
}
export function ApplicationLastDiscoveredTimestamp({ serverApp }: ApplicationLastDiscoveredTimestampProps) {
    const [show, setShow] = useState(false);

    const serverName = serverApp.serverName;

    const status = GetAppDiscoveryStatus(serverApp);

    const clickHandler = async () => {
        setShow(true);
    };

    const statusCls = classNames('col-9', status.app.view.colorText);
    const iconModal = classNames(status.app.view.icon, status.app.view.colorText);
    const infoModalTitle = `State of ${serverApp.name} on ${serverName}`;
    return (
        <>
            <ActionButton disabled={false} spinning={false} icon={status.app.view.icon} variant={status.app.view.colorBtn} title="Click to check details" onClick={clickHandler} />
            &nbsp;
            <Link to={`/servers/${serverName}`}>{serverName}</Link>
            <InfoModal title={infoModalTitle} show={show} onHide={() => setShow(false)} includeClose={true} icon={iconModal}>
                <Row>
                    <Col className="col-3">Status</Col>
                    <Col className={statusCls}>{status.app.message}</Col>
                </Row>
                <Row>
                    <Col className="col-3">Server Last Discovery</Col>
                    <Col className="col-9">
                        <DateDisplay dateAsString={serverApp.serverLastDiscoveredTimestamp} />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-3">App Last Discovery</Col>
                    <Col className="col-9">
                        <DateDisplay dateAsString={serverApp.lastDiscoveredTimestamp} />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-3">Actions</Col>
                    <Col className={statusCls}>{status.actions}</Col>
                </Row>
            </InfoModal>
        </>
    );
}
