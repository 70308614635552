import { useState } from 'react';
import { ActionButton } from '../UIExtentions/Buttons';
import { ConfirmationModal, VerbiageType } from '../Shared/ConfirmationModal';
import AppManagerClient from '../../api/AppManagerClient';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { Permission as PermissionString } from '../../permissions/permission';
import { tryCallApi } from '../../api/Helpers';
import { ActionAlert } from '../UIExtentions/ActionAlert';
import { AppType } from '../../constants/AppType';

interface ApplicationDeleteProps {
    name: string;
    deleteAppHandler: (appName: string) => void;
}

function ApplicationsDelete(props: ApplicationDeleteProps) {
    const [showDeleteApp, setShowDeleteApp] = useState(false);
    const [hasDeleteAppPermission, setHasDeleteAppPermission] = useState(false);
    const [spinning, setSpinning] = useState(false);

    const applicationName = props.name;
    let userPrincipal: UserPrincipal;
    const deleteAppClick = async () => {
        setSpinning(true);

        const success = await tryCallApi(async () => {
            const permissionsResult = await AppManagerClient.getApplicationUserPermissions(applicationName, AppType.WindowsService);
            userPrincipal = new UserPrincipal(permissionsResult);
        });

        if (success && userPrincipal) {
            const canDelete = userPrincipal.hasPermission(PermissionString.Application_Delete);
            setHasDeleteAppPermission(canDelete);
        }
        setShowDeleteApp(true);
        setSpinning(false);
    };

    const deleteAppHandler = async () => {
        const success = await tryCallApi(async () => {
            await AppManagerClient.deleteApplication(applicationName);
        });
        if (success) {
            props.deleteAppHandler(applicationName);
        }
    };

    const alertType = hasDeleteAppPermission ? 'warning' : 'danger';
    const verbiageType = hasDeleteAppPermission ? VerbiageType.YesNo : VerbiageType.OkCancel;
    const body = hasDeleteAppPermission ? (
        <div>
            Are you sure you want to delete <b>{applicationName}</b>?
        </div>
    ) : (
        <div>
            You cannot delete <b>{applicationName}</b> because you are not an owner.
        </div>
    );

    return (
        <>
            <ActionButton outlined={false} spinning={spinning} icon="fa-trash" title={`Delete ${props.name}`} variant="btn-red" onClick={deleteAppClick} />
            <ConfirmationModal
                show={showDeleteApp}
                enabled={hasDeleteAppPermission}
                title={'Delete Application'}
                verbiageType={verbiageType}
                onCancel={() => setShowDeleteApp(false)}
                onConfirm={() => {
                    deleteAppHandler();
                    setShowDeleteApp(false);
                }}
            >
                <ActionAlert key="delete-alert" variant={alertType}>
                    {body}
                </ActionAlert>
            </ConfirmationModal>
        </>
    );
}

export default ApplicationsDelete;
