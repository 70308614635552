import AppManagerClient from '../../api/AppManagerClient';
import { IApplication } from '../../api/models';
import React, { useCallback, useEffect } from 'react';
import { ServerApplicationListingView } from './ServerApplicationListingView';
import { ApplicationNameSort } from '../Applications/ApplicationSorting';

interface ServerApplicationListingProps {
    serverName: string;
}

export const ServerApplicationListing = (props: ServerApplicationListingProps) => {
    const [apps, setApps]: [IApplication[], Function] = React.useState([]);
    const [isLoading, setLoadingStatus] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const serverName = props.serverName;

    const getApplications = useCallback(async (serverName: string) => {
        try {
            const applicationsResult = await AppManagerClient.getApplicationsByServerName(serverName);
            setApps(applicationsResult.sort(ApplicationNameSort));
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setLoadingStatus(false);
        }
    }, []);

    useEffect(() => {
        getApplications(serverName);
    }, [getApplications, serverName]);

    return <ServerApplicationListingView applications={apps} isLoading={isLoading} errorMessage={errorMessage} isDataNotFound={apps.length === 0} serverName={serverName} />;
};
