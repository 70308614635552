import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { ValidationErrors } from './ValidationErrors';

interface ValidatableInputProps {
    type?: string;
    fieldName: string;
    value: string;
    placeholder?: string;
    focus?: boolean;
    validate: (value: string) => string[];
    onChange: (value: string) => void;
    onEnterKeyDown?: () => void;
}
export function ValidatableInput(props: ValidatableInputProps) {
    const [errors, setErrors]: [string[], Function] = useState([]);
    const input: any = useRef(null);

    const onValueChanged = async function (value: string) {
        setErrors(props.validate(value));
        props.onChange(value);
    };

    const onKeyDown = async function (event: KeyboardEvent<HTMLInputElement>) {
        if (props.onEnterKeyDown && event.key === 'Enter') {
            props.onEnterKeyDown();
        }
    };

    useEffect(() => {
        if (props.focus) {
            input.current.focus();
        }
    }, [props.focus]);

    useEffect(() => {
        onValueChanged(props.value);
        // This is here to make sure we calculate and show validation errors right away
        // This is ideally done a different way
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="form-group">
                <label>{props.fieldName}</label>
                <input
                    ref={input}
                    placeholder={props.placeholder}
                    type={`${props.type ?? 'text'}`}
                    className={`form-control required ${errors.length === 0 ? '' : 'validation-error'}`}
                    aria-required="true"
                    value={props.value}
                    onChange={async (e) => await onValueChanged(e.target.value)}
                    onKeyDown={onKeyDown}
                />
                <ValidationErrors errors={errors} />
            </div>
        </>
    );
}
