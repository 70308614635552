import classNames from 'classnames';
import { HtmlHTMLAttributes } from 'react';

export interface StatusIconProps extends HtmlHTMLAttributes<HTMLElement> {
    displayText?: string;
    statusType: 'success' | 'infoWarning' | 'warning' | 'error';
}

const statusClasses: Record<StatusIconProps['statusType'], string> = {
    success: 'fa-check-circle text-success',
    infoWarning: 'fa-exclamation-circle text-warning',
    warning: 'fa-exclamation-triangle text-warning',
    error: 'fa-times-circle text-danger',
};

export const StatusIcon = (props: StatusIconProps) => {
    return <i {...props} className={classNames('fa', statusClasses[props.statusType], props.className)} title={props.displayText} />;
};
