import { AppType } from '../../constants/AppType';
import Applications from '../../components/Applications/Applications';
import withHasPermission from '../../hoc/withHasPermission';
import { Permission } from '../../permissions/permission';

const AppPools = withHasPermission(() => {
    return <Applications type={AppType.AppPool} title={'App Pools'} icon={'fa-solid fa-globe'} />;
}, Permission.Root_AppPools);

export default AppPools;
