import {
    IManagingAppPoolsInstance,
    IManagingApplicationInstance,
    IManagingScheduledTasksInstance,
    IManagingVirtualAppsInstance,
    IManagingWebSitesInstance,
    IManagingWindowsServiceInstance,
} from '../api/models';
import { AppType } from '../constants/AppType';

export function isWindowsServiceInstance(value: IManagingApplicationInstance): value is IManagingWindowsServiceInstance {
    return value.type === AppType.WindowsService;
}

export function isWebSitesInstance(value: IManagingApplicationInstance): value is IManagingWebSitesInstance {
    return value.type === AppType.WebSite;
}

export function isScheduledTaskInstance(value: IManagingApplicationInstance): value is IManagingScheduledTasksInstance {
    return value.type === AppType.ScheduledTask;
}

export function isVirtualAppsInstance(value: IManagingApplicationInstance): value is IManagingVirtualAppsInstance {
    return value.type === AppType.VirtualApp;
}

export function isAppPoolsInstance(value: IManagingApplicationInstance): value is IManagingAppPoolsInstance {
    return value.type === AppType.AppPool;
}
