import { dateToISO, dateToLocal, stringToISO, stringToLocal } from '../../helpers/DateHelpers';

export interface DateDisplayProps {
    date?: Date;
    dateAsString?: string;
}

export const DateDisplay = ({ date, dateAsString }: DateDisplayProps) => {
    if (date) {
        return <span title={dateToISO(date)}>{dateToLocal(date)}</span>;
    }
    if (dateAsString) {
        return <span title={stringToISO(dateAsString)}>{stringToLocal(dateAsString)}</span>;
    }
    return <>no date provided</>;
};
