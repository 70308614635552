import { PageContent, PageFooter, PageFooterLegal, PageFooterVersion } from '@quad/bootstrap-react';
import { HtmlHTMLAttributes, ReactNode } from 'react';
import packageJson from '../../../package.json';

interface NoScrollPageContentProps {
    containerProps?: HtmlHTMLAttributes<HTMLDivElement>;
    children: ReactNode;
}

function NoScrollPageContent({ containerProps, children }: NoScrollPageContentProps) {
    return (
        <PageContent
            scrollType="NoScroll"
            containerProps={containerProps}
            footer={<PageFooter pageFooterLegal={<PageFooterLegal />} version={<PageFooterVersion version={packageJson.version} />} />}
        >
            {children}
        </PageContent>
    );
}

export default NoScrollPageContent;
