import { IApplication } from '../../api/models';
import React, { useEffect } from 'react';
import { AccordionWrapper } from '../UIExtentions/Accordion';
import ApplicationsTable from '../Applications/ApplicationsTable';
import { ApplicationTableButtons, ApplicationTableColumns } from '../../constants/ApplicationTableItems';

interface AppPoolApplicationListingViewProps {
    appPoolName: string;
    applications: IApplication[];
    isLoading: boolean;
    errorMessage: string;
    isDataNotFound: boolean;
}

export const AppPoolApplicationListingView = (props: AppPoolApplicationListingViewProps) => {
    const [applications, setApplications]: [IApplication[], Function] = React.useState(props.applications);
    const appPoolName = props.appPoolName;
    const hiddenButtons: [columnNames: ApplicationTableButtons] = [ApplicationTableButtons.Delete];
    const hiddenColumns: [columnNames: ApplicationTableColumns] = [ApplicationTableColumns.Type];

    useEffect(() => {
        const local = [...props.applications];
        setApplications(local);
    }, [props.applications]);

    return (
        <>
            <AccordionWrapper
                isDefaultOpen={false}
                headerIconType="fa-solid"
                headerIcon="fa-window-maximize"
                headerText="Virtual Apps"
                objectName={appPoolName}
                isLoading={props.isLoading}
                errorMessage={props.errorMessage}
            >
                <ApplicationsTable data={applications} maxHeight={250} hiddenButtons={hiddenButtons} hiddenColumns={hiddenColumns} />
            </AccordionWrapper>
        </>
    );
};
