import React from 'react';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { ActionButton } from '../UIExtentions/Buttons';
import { ActionAlert } from '../UIExtentions/ActionAlert';
import { Permission as PermissionString } from '../../permissions/permission';
import { ConfirmationModal, VerbiageType } from '../Shared/ConfirmationModal';
import { ServiceAction } from '../../constants/ServiceAction';

interface ApplicationUninstallProps {
    applicationName: string;
    server: string;
    onClickCallback: (action: ServiceAction) => Promise<void>;
    userPrincipal: UserPrincipal;
}

export const ApplicationUninstall = (props: ApplicationUninstallProps) => {
    const [showConfirmation, setShowConfirmation]: [boolean, Function] = React.useState(false);
    const applicationName = props.applicationName;
    const server = props.server;
    const tryCallback = props.onClickCallback;
    const userPrincipal = props.userPrincipal;

    const uninstallActionHandler = function (serviceName: string, server: string) {
        tryCallback(ServiceAction.Uninstall);
    };

    const alertBody = (
        <div>
            Are you sure that you want to uninstall <b>{applicationName}</b> from <b>{server}</b>?
        </div>
    );

    return (
        <>
            <ActionButton
                disabled={!userPrincipal.hasPermission(PermissionString.WindowsService_Uninstall)}
                spinning={false}
                icon="fa-trash"
                variant="btn-red"
                title="Uninstall"
                onClick={() => setShowConfirmation(true)}
            />
            <ConfirmationModal
                title="Uninstall Application"
                show={showConfirmation}
                enabled={true}
                verbiageType={VerbiageType.YesNo}
                onCancel={() => setShowConfirmation(false)}
                onConfirm={() => {
                    uninstallActionHandler(applicationName, server);
                    setShowConfirmation(false);
                }}
            >
                <ActionAlert key="uninstall-alert" variant="warning">
                    {alertBody}
                </ActionAlert>
            </ConfirmationModal>
        </>
    );
};
