import { IManagingScheduledTasksInstance } from '../../api/models';
import { AppEnvironment } from '../../constants/AppEnvironment';
import { ScheduledTaskState } from '../../constants/ScheduledTaskState';
import { IStyledText, StyledText } from '../UIExtentions/Text';

function GetStateProps(state: string, environment: AppEnvironment): IStyledText {
    switch (state) {
        case ScheduledTaskState.Enabled:
            return {
                text: 'Enabled',
                className: 'text-success',
            };
        case ScheduledTaskState.Disabled:
            return {
                text: 'Disabled',
                className: environment === AppEnvironment.Production ? 'text-danger' : null,
            };
        default:
            return {
                text: state,
            };
    }
}

export default function ScheduledTasksState(serverApp: IManagingScheduledTasksInstance) {
    const stateProps = GetStateProps(serverApp.taskState, serverApp.environment as AppEnvironment);
    return <StyledText {...stateProps} />;
}
