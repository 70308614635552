import React, { useEffect } from 'react';
import { Checkbox } from '../../areas/app/Checkbox';
import { AppPoolsServerRowActions, AppPoolsServerRowActionsProps } from './AppPoolsServerRowActions';
import AppPoolsState from './AppPoolsState';
import { ApplicationLastDiscoveredTimestamp } from '../ApplicationServerManagement/ApplicationLastDiscoveredTimestamp';

export interface AppPoolServerRowProps extends AppPoolsServerRowActionsProps {
    selected: boolean;
    selectionChangedCallback: Function;
}

export function AppPoolsServerRow(props: AppPoolServerRowProps) {
    const [selected, setSelected]: [any, Function] = React.useState(false);

    const serverApp = props.serverApp;

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    return (
        <tr>
            <td>
                <Checkbox selected={selected} selectionChangedCallback={(checked: boolean) => props.selectionChangedCallback(checked, serverApp.serverName)} />
            </td>
            <td className="details-link">
                <ApplicationLastDiscoveredTimestamp serverApp={serverApp} />
            </td>
            <td>{serverApp.environment}</td>
            <td>
                <AppPoolsState {...serverApp} />
            </td>
            <AppPoolsServerRowActions {...props}></AppPoolsServerRowActions>
        </tr>
    );
}
