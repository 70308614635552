import { PageHeading } from '@quad/bootstrap-react';
import { Container } from 'react-bootstrap';
import ScrollPageContent from '../../areas/shared/ScrollPageContent';
import { useState, useEffect } from 'react';
import AppManagerClient from '../../api/AppManagerClient';
import { ICertificate } from '../../api/models';
import CertificatesEnhancedTable from './CertificateEnhancedTable';
import { ApiDataWrapper } from '../UIExtentions/ApiDataWrapper';

interface CertificatesProps {
    title: string;
    icon: string;
}
function Certificates(props: CertificatesProps) {
    const title = props.title;
    const icon = 'fa ' + props.icon;
    const batchSize = 1000;
    const [errorMessage, setErrorMessage] = useState('');
    const [certificates, setCertificates] = useState(Array<ICertificate>());
    const [wereAllBatchesReceived, setWereAllBatchesReceived] = useState(false);
    const loadAllCertificates = async () => {
        try {
            let iteration = 0;
            let certs = new Array<ICertificate>();
            let certsBatch = [];

            do {
                certsBatch = await AppManagerClient.getCertificates({
                    top: batchSize,
                    select: 'friendlyName,commonName,thumbprint,validTo,issuer,email,keyUsages,validFrom',
                    expand: 'serverCertificates($expand=server($select=id,name);$select=lastDiscoveredTimestamp)',
                    filter: null,
                    orderBy: 'thumbprint asc',
                    skip: batchSize * iteration,
                });

                certs = certs.concat(certsBatch);
                setCertificates(certs);
                iteration++;
            } while (certsBatch.length === batchSize);
            setWereAllBatchesReceived(true);
        } catch (err: unknown) {
            if (err instanceof Error) {
                setErrorMessage(err.message);
            }
        }
    };

    useEffect(() => {
        loadAllCertificates();
    }, []);

    return (
        <>
            <ScrollPageContent>
                <PageHeading>
                    <div className="col">
                        <h1>
                            <i className={icon}></i> {title}
                        </h1>
                    </div>
                </PageHeading>
                <ApiDataWrapper isLoading={false} errorMessage={errorMessage} objectType={'Certificates'} objectName={'Certificate'} isDataNotFound={false} />
                <Container fluid>
                    <CertificatesEnhancedTable isLoading={false} data={certificates} showServers={true} useAutoHeight={false} areCertsReceived={wereAllBatchesReceived} />
                </Container>
            </ScrollPageContent>
        </>
    );
}

export default Certificates;
