export class SignalREventsClientHook<T> {
    private eventHooks: Map<string, (args: T) => void>;

    public constructor(public event: string) {
        this.eventHooks = new Map<string, (args: T) => void>();
    }

    public onEvent(data: T) {
        this.eventHooks.forEach((value, key) => value(data));
    }

    public setHook(hookName: string, func: (args: T) => void) {
        this.eventHooks.set(hookName, func);
    }
}
