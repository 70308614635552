import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ApplicationStatusModel } from './models';
import { AppType } from '../../constants/AppType';
import { StatusIcon } from '../Shared/StatusIcon';

export interface ApplicationDisplayProps {
    application: ApplicationStatusModel;
}

export const ApplicationLink = (props: ApplicationDisplayProps) => {
    const { application } = props;

    const renderApplicationLink = (application: ApplicationStatusModel) => {
        return <Link to={`/applications/${application.type}/${application.name}`}>{application.displayName}</Link>;
    };

    const renderApplicationPoolLink = (application: ApplicationStatusModel) => {
        let title = '';
        if (!application.appPools?.length) {
            title = "Application Pool couldn't be found. Check that it's installed and that you are an owner.";
        }

        return (
            <Link to={`/applications/${AppType.AppPool}/${application.appPoolName}`} title={title}>
                {application.displayName} {title.length > 0 ? <StatusIcon statusType="warning" className={'ps-1'} /> : <></>}
            </Link>
        );
    };

    const applicationDisplay = useMemo(() => {
        if (application.type === AppType.WindowsService || application.type === AppType.ScheduledTask) {
            return renderApplicationLink(application);
        } else if (application.appPoolName?.length) {
            return renderApplicationPoolLink(application);
        }
        return <>{application.displayName}</>;
    }, [application]);

    return applicationDisplay;
};
