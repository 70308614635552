import { IManagingAppPoolsInstance } from '../../api/models';
import { AppEnvironment } from '../../constants/AppEnvironment';
import { AppPoolState } from '../../constants/AppPoolState';
import { IStyledText, StyledText } from '../UIExtentions/Text';

function GetStateProps(state: string, environment: AppEnvironment): IStyledText {
    switch (state) {
        case AppPoolState.Started:
            return {
                text: 'Started',
                className: 'text-success',
            };
        case AppPoolState.Stopped:
            return {
                text: 'Stopped',
                className: environment === AppEnvironment.Production ? 'text-danger' : null,
            };
        default:
            return {
                text: state,
            };
    }
}

export default function AppPoolsState(serverApp: IManagingAppPoolsInstance) {
    const stateProps = GetStateProps(serverApp.state, serverApp.environment as AppEnvironment);

    return <StyledText {...stateProps} />;
}
