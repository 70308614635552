import React from 'react';
import { Button, CloseButton, Modal } from 'react-bootstrap';
import { AdGroupSearch } from './AdGroupSearch';
import { PermissionItem } from './models';

interface PermissionAddModalProps {
    show: boolean;
    roleName: string;
    onHide: Function;
    onPermissionAdded: (permission: PermissionItem) => void;
}
export function PermissionAddModal(props: PermissionAddModalProps) {
    const [adGroupName, setAdGroupName]: [string, Function] = React.useState('');
    const [adGroupSid, setAdGroupSid]: [string, Function] = React.useState('');
    const [adGroupFocus, setAdGroupFocus]: [boolean, Function] = React.useState(false);

    const getAddedPermission = (): PermissionItem => {
        const persmission = {
            id: 0,
            role: props.roleName,
            activeDirectoryGroupName: adGroupName,
            activeDirectoryGroupSecurityId: adGroupSid,
        };

        return persmission;
    };

    const onAdd = () => {
        const permission = getAddedPermission();
        props.onPermissionAdded(permission);
        setTimeout(() => {
            onHide();
        }, 100);
    };

    const onHide = () => {
        props.onHide();
    };

    const onEntered = () => {
        setAdGroup('', '');
        setAdGroupFocus(true);
    };

    const setAdGroup = (adGroupName: string, adGroupSid: string) => {
        setAdGroupName(adGroupName);
        setAdGroupSid(adGroupSid);
    };

    return (
        <>
            <Modal size="lg" centered show={props.show} onEntered={() => onEntered()}>
                <Modal.Header>
                    <Modal.Title>Add AD Group to {props.roleName}s role</Modal.Title>
                    <CloseButton onClick={() => onHide()} />
                </Modal.Header>
                <Modal.Body>
                    <AdGroupSearch focus={adGroupFocus} onChange={(adGroupName: string, adGroupSid: string) => setAdGroup(adGroupName, adGroupSid)} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide()}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => onAdd()}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
