export interface PermissionItem {
    id: number;
    role: string;
    activeDirectoryGroupSecurityId: string;
    activeDirectoryGroupName: string;
}

export interface PermissionDragItem {
    roleName: string;
    activeDirectoryGroupSecurityId: string;
}

export const permissionDragItemType = 'PermissionItem';
