import { Table } from 'react-bootstrap';
import { ActionButton } from '../UIExtentions/Buttons';
import { EmptyDataRow } from '../UIExtentions/TableUtilities';
import { Item } from './Item';
import { ItemListRow } from './ItemListRow';

interface ItemListTableProps<T extends Item> {
    title: string;
    items: T[];
    canModify: boolean;
    addIcon: string;
    enableTopPadding?: boolean;
    onDelete: (item: T) => void;
    onAdd: () => void;
}
export function ItemListTable<T extends Item>(props: ItemListTableProps<T>) {
    const items = props.items;
    const title = props.title;
    const canModify = props.canModify;
    const addIcon = props.addIcon;
    const enableTopPadding = props.enableTopPadding;
    const onDelete = props.onDelete;
    const onAdd = props.onAdd;

    return (
        <Table striped>
            <thead className="thead-white">
                <tr>
                    <th scope="col" className="col-12" style={enableTopPadding ? {} : { paddingTop: 0 }}>
                        {title} <ActionButton disabled={!canModify} icon={addIcon} variant="btn-dark-blue" title="Add" onClick={onAdd} />
                    </th>
                </tr>
            </thead>
            <tbody>
                {items.map((item) => (
                    <ItemListRow key={item.getKey()} item={item} canModify={canModify} onDelete={onDelete} />
                ))}
                {!items.length && <EmptyDataRow />}
            </tbody>
        </Table>
    );
}
