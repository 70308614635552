import React, { useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';

export interface TooltipTableProps {
    elementId: string;
    headers: string[];
    rows: (string | JSX.Element)[][];
}

const noCellPadding: React.CSSProperties = {
    paddingTop: '0px',
    paddingBottom: '0px',
};

export const TooltipTable = (props: TooltipTableProps) => {
    const { elementId, headers, rows } = props;

    const renderTable = useMemo(() => {
        return (
            <Table borderless style={{ color: 'white', fontFamily: 'Consolas' }}>
                <thead style={{ borderBottom: '1px solid white' }}>
                    <tr>
                        {headers.map((header, index) => {
                            return (
                                <th key={elementId + '-tt-th-' + index} style={noCellPadding}>
                                    {header}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((rowData, index) => {
                        return (
                            <tr key={elementId + '-tt-tr-' + index}>
                                {rowData.map((rowText, tIndex) => {
                                    return (
                                        <td key={elementId + '-tt-td-' + tIndex} style={noCellPadding}>
                                            {rowText}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    }, [elementId, headers, rows]);

    return (
        <Tooltip anchorSelect={'#' + elementId} place="top">
            {renderTable}
        </Tooltip>
    );
};
