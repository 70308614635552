import appConfig from '../appConfig';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import MsalClientBase from '../api/MsalClientBase';
import { SignalREventsClientHook } from './SignalREventsClientHook';

export class SignalRClient extends MsalClientBase {
    private connection: HubConnection | undefined;

    public constructor(private url: string, private clientHooks: SignalREventsClientHook<any>[]) {
        super(appConfig.apiBaseUrl);

        this.start();
    }

    private createConnection() {
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${this.baseUrl}/${this.url}`, { accessTokenFactory: this.getAccessToken })
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        this.connection = newConnection;

        this.wireUpEvents();
    }

    private wireUpEvents() {
        this.connection?.onclose(async () => {
            await this.start();
        });

        this.clientHooks.forEach((value) => this.connection?.on(value.event, (data) => value.onEvent(data)));
    }

    private async start() {
        if (this.connection === undefined) {
            this.createConnection();
        }

        if (this.connection?.state === HubConnectionState.Disconnected) {
            try {
                await this.connection?.start();
                console.log('SignalR Connected.');
            } catch (err) {
                console.log(err);
                setTimeout(async () => await this.start(), 5000);
            }
        }
    }
}
