import AppManagerClient from '../../api/AppManagerClient';
import { IManagingWebSitesInstance } from '../../api/models';
import React, { useEffect } from 'react';
import { WebSitesInfoView } from './WebSitesInfoView';
import { Merger } from '../../helpers/Merger';

export const WebSitesInfo = (props: any) => {
    const [serverApps, setServerApps]: [IManagingWebSitesInstance[], Function] = React.useState([]);
    const [isLoading, setLoadingStatus] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const applicationName = props.applicationName;

    const getServers = async (appName: string) => {
        try {
            const serversResult = await AppManagerClient.getWebSitesForManaging(appName);
            setServerApps(serversResult);
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setLoadingStatus(false);
        }
    };

    useEffect(() => {
        getServers(applicationName);
    }, [applicationName]);

    return (
        <WebSitesInfoView
            serverApps={Merger.merge(serverApps)}
            isLoading={isLoading}
            errorMessage={errorMessage}
            isDataNotFound={serverApps.length === 0}
            applicationName={applicationName}
        />
    );
};
