import { Alert, Container, Row, Stack, Button } from 'react-bootstrap';

export function UnhandledError(props: any) {
    return (
        <Stack gap={3}>
            <Container className="text-center">
                <Row>
                    <Alert variant="danger">
                        <Alert.Heading>Oops! Something went wrong!</Alert.Heading>
                        <p>
                            {props.message}
                        </p>
                        <p>
                            <Button href="home">Go Home</Button>
                        </p>
                    </Alert>
                </Row>
            </Container>
        </Stack>
    );
}