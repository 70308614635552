import { isVirtualAppsInstance } from '../../helpers/AppInstanceHelper';
import { ApplicationStatusModel, CapabilityMatcher } from './models';

const matchEnabledProtocol = (appStatusModel: ApplicationStatusModel, protocol: string): boolean => {
    if (!appStatusModel.appInstances?.length) {
        return false;
    }

    return (
        appStatusModel.appInstances.find((appInstance) => {
            return isVirtualAppsInstance(appInstance) && appInstance.enabledProtocols?.toLowerCase().includes(protocol);
        }) != null
    );
};

export const AppCapabilities: Record<string, CapabilityMatcher> = {
    ['HTTP']: (appStatusModel: ApplicationStatusModel): boolean => {
        return matchEnabledProtocol(appStatusModel, 'http');
    },
    ['HTTPS']: (appStatusModel: ApplicationStatusModel): boolean => {
        return matchEnabledProtocol(appStatusModel, 'https');
    },
    ['NET.TCP']: (appStatusModel: ApplicationStatusModel): boolean => {
        return matchEnabledProtocol(appStatusModel, 'net.tcp');
    },
    ['64 Bit']: (appStatusModel: ApplicationStatusModel): boolean => {
        if (!appStatusModel.appPools?.length) {
            return false;
        }

        return appStatusModel.appPools.some((appPool) => !appPool.enable32Bit);
    },
};
