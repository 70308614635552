import { IDateFilterParams } from '@ag-grid-community/core';
import { dateToLocalStringNoHours, stringToDate } from './DateHelpers';

export const dateFilterParams: IDateFilterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
        const certDate = stringToDate(cellValue) as Date;
        const formattedDateNoHours = dateToLocalStringNoHours(certDate);
        const filterLocalDateNoHours = dateToLocalStringNoHours(filterLocalDateAtMidnight);

        if (formattedDateNoHours === filterLocalDateNoHours) {
            return 0;
        }

        if (certDate < filterLocalDateAtMidnight) {
            return -1;
        }

        if (certDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    },
    buttons: ['clear'],
};
