import { PageHeading } from '@quad/bootstrap-react';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { ApplicationEventLog } from '../../components/EventLog/ApplicationEventLog';
import { ApplicationPermissions } from '../../components/Permissions/ApplicationPermissions';
import { ApplicationDetails } from '../../components/ApplicationDetails/ApplicationDetails';
import { AccordionWrapper } from '../../components/UIExtentions/Accordion';
import { ApplicationPermissionClientEventSummary } from '../../signalr/models';
import { useEffect } from 'react';
import signalREventsClient from '../../signalr/SignalREventsClient';
import { UserPrincipal } from '../../permissions/userPrincipal';
import AppManagerClient from '../../api/AppManagerClient';
import React from 'react';
import ScrollPageContent from '../shared/ScrollPageContent';
import withHasPermission from '../../hoc/withHasPermission';
import { Permission } from '../../permissions/permission';
import { useParams } from 'react-router-dom';
import { AppType } from '../../constants/AppType';
import { ScheduledTasksInfo } from '../../components/ScheduledTasksInfo/ScheduledTasksInfo';
import { ScheduledTasksServerManagement } from '../../components/ScheduledTasksManagement/ScheduledTasksServerManagement';

export const ScheduledTaskDetails = withHasPermission(() => {
    const applicationName = useParams<'name'>().name!;
    const [userPrincipal, setUserPrincipal] = React.useState(new UserPrincipal([]));

    const getUserPrincipal = async (appName: string) => {
        const permissionsResult = await AppManagerClient.getScheduledTasksUserPermissions(appName);
        const myUserPrincipal = new UserPrincipal(permissionsResult);
        setUserPrincipal(myUserPrincipal);
    };

    useEffect(() => {
        getUserPrincipal(applicationName);
    }, [applicationName]);

    useEffect(() => {
        function isForMe(data: ApplicationPermissionClientEventSummary) {
            return data.targetApplication === applicationName;
        }

        signalREventsClient.onApplicationPermissionEvent.setHook(`ScheduledTaskDetails_${applicationName}`, (data) => {
            if (isForMe(data)) {
                getUserPrincipal(data.targetApplication);
            }
        });
    }, [applicationName]);

    return (
        <ScrollPageContent>
            <PageHeading>
                <div className="col">
                    <h1>
                        <i className="fa fa-clock"></i> {applicationName} Details
                    </h1>
                </div>
            </PageHeading>

            <Stack gap={3}>
                <Container>
                    <AccordionWrapper isDefaultOpen={false} headerIcon="fa-info-circle" headerText="Application Details">
                        <Container>
                            <Row>
                                <Col sm={12} md={6}>
                                    <ApplicationDetails applicationName={applicationName} applicationType={AppType.ScheduledTask} />
                                </Col>
                                <Col sm={12} md={6}>
                                    <ScheduledTasksInfo applicationName={applicationName} />
                                </Col>
                            </Row>
                        </Container>
                    </AccordionWrapper>
                </Container>

                <Container>
                    <ApplicationPermissions applicationName={applicationName} userPrincipal={userPrincipal} applicationType={AppType.ScheduledTask} />
                </Container>

                <Container>
                    <ScheduledTasksServerManagement applicationName={applicationName} userPrincipal={userPrincipal} />
                </Container>

                <Container>
                    <ApplicationEventLog applicationName={applicationName} applicationType={AppType.ScheduledTask} />
                </Container>
            </Stack>
        </ScrollPageContent>
    );
}, Permission.Root_ScheduledTasks);
