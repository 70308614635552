import { TimeoutList } from '../helpers/TimeoutList';
import { SignalRServerClientEvent } from './models';

export interface ClientEventSummaryHandlerBaseArgs<T extends SignalRServerClientEvent> {
    addTypes: string[];
    successTypes: string[];
    failedTypes: string[];
    emptyCallback?: () => void;
    addCallback?: (data: T) => void;
    successCallback?: (data: T) => void;
    failedCallback?: (data: T) => void;
    timeoutCallback?: (data: T) => void;
}

export abstract class ClientEventSummaryHandlerBase<T extends SignalRServerClientEvent> {
    private events: TimeoutList<string, T>;
    private args: ClientEventSummaryHandlerBaseArgs<T>;
    protected server: string;

    public constructor(server: string, args: ClientEventSummaryHandlerBaseArgs<T>) {
        this.server = server;
        this.args = args;
        this.events = new TimeoutList<string, T>(60000, undefined, this.args.emptyCallback);
    }

    protected abstract isForMe(data: T): boolean;

    public handleItem(data: T) {
        if (!this.isForMe(data)) {
            return;
        }

        if (this.args.addTypes.includes(data.type)) {
            this.events.add(data.correlationId, data, (value, timedOut) => {
                if (timedOut) {
                    this.args.timeoutCallback?.(value);
                }
            });
            this.args.addCallback?.(data);
        }

        if (this.args.failedTypes.includes(data.type)) {
            this.args.failedCallback?.(data);
            this.events.remove(data.correlationId);
        }

        if (this.args.successTypes.includes(data.type)) {
            this.args.successCallback?.(data);
            this.events.remove(data.correlationId);
        }
    }
}
