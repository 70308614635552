import { IManagingWindowsServiceInstance } from '../../api/models';
import { AppEnvironment } from '../../constants/AppEnvironment';
import { ServiceStartType } from '../../constants/ServiceStartType';
import { ServiceState } from '../../constants/ServiceState';
import { IStyledText, StyledText } from '../UIExtentions/Text';

function GetStateProps(state: string, startType: string, environment: AppEnvironment): IStyledText {

    const calculateVariant = (className: string) => {
        if (startType === ServiceStartType.Disabled) {
            return state === ServiceState.ServiceRunning ? 'text-orange' : 'text-muted';
        }
        return className;
    };

    switch (state) {
        case ServiceState.ServiceRunning:
            return {
                text: 'Running',
                className: calculateVariant('text-success'),
            };
        case ServiceState.ServiceStopped:
            return {
                text: 'Stopped',
                className: environment === AppEnvironment.Production ? calculateVariant('text-danger') : null,
            };
        case ServiceState.ServiceStartPending:
            return {
                text: 'Start Pending',
                className: calculateVariant('text-orange'),
            };
        case ServiceState.ServiceStopPending:
            return {
                text: 'Stop Pending',
                className: calculateVariant('text-orange'),
            };
        default:
            return {
                text: state,
            };
    }
}



export default function WindowsServiceState(serverApp: IManagingWindowsServiceInstance) {
    const stateProps = GetStateProps(serverApp.state, serverApp.startType, serverApp.environment as AppEnvironment);

    return <StyledText {...stateProps} />;
}