interface CheckboxProps {
    selected: boolean;
    selectionChangedCallback: Function;
    shouldDisable?: boolean;
}

export function Checkbox(props: CheckboxProps) {
    const content = props.shouldDisable ? (
        <span>
            <input type="checkbox" disabled className="form-check-input" checked={props.selected} onChange={(e) => props.selectionChangedCallback(e.target.checked)} />
        </span>
    ) : (
        <span>
            <input type="checkbox" className="form-check-input" checked={props.selected} onChange={(e) => props.selectionChangedCallback(e.target.checked)} />
        </span>
    );

    return <>{content}</>;
}
