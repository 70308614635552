import AppManagerClient from '../../api/AppManagerClient';
import { IEventLog } from '../../api/models';
import { EventLogView } from '../EventLog/EventLogView';
import React, { useEffect } from 'react';
import { ApplicationPermissionClientEventSummary, ServerApplicationClientEventSummary } from '../../signalr/models';
import signalREventsClient from '../../signalr/SignalREventsClient';

export const ApplicationEventLog = (props: any) => {
    const [eventList, setEventList]: [IEventLog[], Function] = React.useState([]);
    const [isLoading, setLoadingStatus] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const applicationName = props.applicationName;
    const applicationType = props.applicationType;

    useEffect(() => {
        function isForMe(data: ServerApplicationClientEventSummary | ApplicationPermissionClientEventSummary) {
            return data.targetApplication === applicationName && data.targetApplicationType === applicationType;
        }

        signalREventsClient.onWindowsServiceEvent.setHook(`ApplicationEventLog_${applicationName}`, (data) => {
            if (isForMe(data)) {
                getEventLogs(data.targetApplication, data.targetApplicationType);
            }
        });

        signalREventsClient.onApplicationPermissionEvent.setHook(`ApplicationEventLog_${applicationName}`, (data) => {
            if (isForMe(data)) {
                getEventLogs(data.targetApplication, data.targetApplicationType);
            }
        });

        signalREventsClient.onAppPoolEvent.setHook(`ApplicationEventLog_${applicationName}`, (data) => {
            if (isForMe(data)) {
                getEventLogs(data.targetApplication, data.targetApplicationType);
            }
        });

        signalREventsClient.onWebSiteEvent.setHook(`ApplicationEventLog_${applicationName}`, (data) => {
            if (isForMe(data)) {
                getEventLogs(data.targetApplication, data.targetApplicationType);
            }
        });

        signalREventsClient.onScheduledTaskEvent.setHook(`ApplicationEventLog_${applicationName}`, (data) => {
            if (isForMe(data)) {
                getEventLogs(data.targetApplication, data.targetApplicationType);
            }
        });
    }, [applicationName, applicationType]);

    const getEventLogs = async (appName: string, appType: string) => {
        const query = `$filter=targetApplication eq '${appName}' and targetApplicationType eq '${appType}'&$select=type,createdTimestamp,operation,targetServer,summary&$orderby=createdTimestamp desc&$expand=user($select=displayName)`;
        try {
            const eventResult = await AppManagerClient.getEvents(query);
            setEventList(eventResult);
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setLoadingStatus(false);
        }
    };

    useEffect(() => {
        getEventLogs(applicationName, applicationType);
    }, [applicationName, applicationType]);

    return <EventLogView eventList={eventList} isLoading={isLoading} errorMessage={errorMessage} isDataNotFound={false} objectName={applicationName} />;
};
