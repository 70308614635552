export module StringUtils {
    export function isNullOrEmpty(s: string): boolean {
        return !s;
    }
    export function substringAfterSeparator(separator: string, text: string) : string{
        if (isNullOrEmpty(text)) {
            return text;
        }
        return text.substring(text.lastIndexOf(separator) + separator.length, text.length);
    }
    export function capitalizeFirstLetter(text: string) : string{
        if (isNullOrEmpty(text)) {
            return text;
        }
        return text[0].toUpperCase() + text.slice(1);
    }
    
}
