import AppManagerClient from '../../api/AppManagerClient';
import React, { useEffect } from 'react';
import { IApplication, IODataQuery } from '../../api/models';
import { Loading } from '../../components/Shared/Loading';
import ApplicationSearchResults from './ApplicationSearchResults';
import { UnhandledError } from '../../components/Shared/UnhandledError';
import { Error404 } from '../Shared/Error404NotFound';
import { ApplicationNameSort } from './ApplicationSorting';
import { useParams } from 'react-router-dom';
import withHasPermission from '../../hoc/withHasPermission';
import { Permission } from '../../permissions/permission';
import { Navigate } from 'react-router';

const ApplicationSearch = withHasPermission(() => {
    const [applications, setApplications] = React.useState<IApplication[]>([]);
    const [isLoading, setLoadingStatus] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const name = useParams<'name'>().name!;

    const findApps = async (appName: string) => {
        const criteria: IODataQuery = {
            filter: `name eq '${appName}'`,
            top: null,
            select: null,
            orderBy: null,
            expand: null,
            skip: null,
        };

        try {
            const apps = await AppManagerClient.applications.get(criteria);
            setApplications(apps.sort(ApplicationNameSort));
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setLoadingStatus(false);
        }
    };

    useEffect(() => {
        findApps(name);
    }, [name]);

    if (isLoading) {
        return <Loading />;
    } else if (errorMessage.length > 0) {
        return <UnhandledError message={errorMessage} />;
    } else if (applications.length === 0) {
        return <Error404 objectName={name as string} />;
    } else if (applications.length === 1) {
        return <Navigate to={`/applications/${applications[0].type}/${applications[0].name}`} />;
    } else {
        return <ApplicationSearchResults data={applications} />;
    }
}, Permission.Root_Applications);

export default ApplicationSearch;
