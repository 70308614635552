import { ApiNonSuccessError } from './models';

export const tryCallApi = async (funct: Function): Promise<boolean> => {
    try {
        await funct();
        return true;
    } catch (error) {
        const apiError = error as ApiNonSuccessError;
        const message = `Error (${apiError?.status}) while calling API. Message: ${apiError?.message}.`;
        alert(message);
        return false;
    }
};
