import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface InfoLinkProps {
    to: string;
    disabled?: boolean;
    title: string;
    outlined?: boolean;
}
export const InfoLink = ({ to, disabled, outlined, title }: InfoLinkProps) => {
    const className = classNames('btn btn-dark-blue', 'action-button' + (outlined ? '-outlined' : ''), { disabled });
    return (
        <Link to={to} className={className} title={title}>
            <i className="fa fa-info-circle" />
        </Link>
    );
};
