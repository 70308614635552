import { PageHeading } from '@quad/bootstrap-react';
import { Badge, Container } from 'react-bootstrap';
import ScrollPageContent from '../../areas/shared/ScrollPageContent';
import AppManagerClient from '../../api/AppManagerClient';
import { useState, useEffect, useCallback } from 'react';
import { ApplicationNameSort } from './ApplicationSorting';
import _ from 'lodash';
import { IApplication } from '../../api/models';
import { ApplicationsEnhancedTable } from './ApplicationsEnhancedTable';
import { NavBar, NavBarItem } from '../Shared/NavBar';

interface ApplicationsProps {
    type?: string;
    title: string;
    icon: string;
}
function Applications(props: ApplicationsProps) {
    const type = props.type;
    const title = props.title;
    const icon = props.icon;

    const [allApplications, setAllApplications] = useState(Array<IApplication>());
    const [allAppsAreLoading, setAllAppsAreLoading] = useState(true);
    const [userApplications, setUserApplications] = useState(Array<IApplication>());
    const [userAppsAreLoading, setUserAppsAreLoading] = useState(true);

    const loadAllApps = useCallback(async () => {
        try {
            const apps = type ? await AppManagerClient.getApplicationsByType(type) : await AppManagerClient.getAllApplications();
            setAllApplications(apps.sort(ApplicationNameSort));
        } finally {
            setAllAppsAreLoading(false);
        }
    }, [type]);

    const loadUserApps = useCallback(async () => {
        try {
            const apps = type ? await AppManagerClient.getUserApplicationsByType(type) : await AppManagerClient.getUserApplications();
            setUserApplications(apps.sort(ApplicationNameSort));
        } finally {
            setUserAppsAreLoading(false);
        }
    }, [type]);

    useEffect(() => {
        loadAllApps();
        loadUserApps();
    }, [loadAllApps, loadUserApps]);

    const deleteApps = (apps: IApplication[], appName: string, setter: (apps: IApplication[]) => void) => {
        const shouldStay = _.filter(apps, (item: IApplication) => {
            return item.name !== appName;
        });
        setter(shouldStay);
    };
    const deleteAppHandler = (appName: string) => {
        deleteApps(allApplications, appName, setAllApplications);
        deleteApps(userApplications, appName, setUserApplications);
    };

    return (
        <ScrollPageContent>
            <PageHeading>
                <div className="col">
                    <h1>
                        <i className={icon}></i> {title}
                    </h1>
                </div>
            </PageHeading>

            <Container>
                <NavBar>
                    <NavBarItem target="user-apps" active={true}>
                        <i className="fa fa-star"></i>
                        <span> My Applications </span>
                        <Badge className="row-count">{userApplications.length}</Badge>
                    </NavBarItem>
                    <NavBarItem target="all-apps">
                        <span> All Applications </span>
                        <Badge className="row-count">{allApplications.length}</Badge>
                    </NavBarItem>
                </NavBar>

                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active container" id="user-apps" role="tabpanel" aria-labelledby="user-apps-tab">
                        <ApplicationsEnhancedTable applicationType={type} isLoading={userAppsAreLoading} data={userApplications} deleteAppHandler={deleteAppHandler} />
                    </div>
                    <div className="tab-pane fade container" id="all-apps" role="tabpanel" aria-labelledby="all-apps-tab">
                        <ApplicationsEnhancedTable applicationType={type} isLoading={allAppsAreLoading} data={allApplications} deleteAppHandler={deleteAppHandler} />
                    </div>
                </div>
            </Container>
        </ScrollPageContent>
    );
}

export default Applications;
