import { Navigate } from 'react-router-dom';

export interface RedirectProps {
    to: string;
}

const Redirect = ({ to }: RedirectProps) => {
    return <Navigate replace to={to} />;
};

export default Redirect;
