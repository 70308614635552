import { PropsWithChildren } from 'react';

export type NavBarProps = PropsWithChildren<{}>;
export const NavBar = (props: NavBarProps) => {
    const children = props.children;
    return (
        <nav className="navbar">
            <div className="container justify-content-center">
                <ul className="nav nav-pills nav-justified w-100" id="pills-tab" role="tablist">
                    {children}
                </ul>
            </div>
        </nav>
    );
};

export type NavBarItemProps = PropsWithChildren<{
    target: string;
    active?: boolean;
}>;
export const NavBarItem = (props: NavBarItemProps) => {
    const active = props.active ? true : false;
    const target = props.target;
    const children = props.children;
    return (
        <li className="nav-item" role="presentation">
            <button
                className={`nav-link ${active ? 'active' : ''}`}
                id={`${target}-tab`}
                data-bs-toggle="pill"
                data-bs-target={`#${target}`}
                type="button"
                role="tab"
                aria-controls={target}
                aria-selected={active}
            >
                {children}
            </button>
        </li>
    );
};
