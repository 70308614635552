export enum ApplicationTableColumns {
    Name = 'Name',
    DisplayName = 'DisplayName',
    Type = 'Type',
    Actions = 'Actions',
}

export enum ApplicationTableButtons {
    Delete = 'Delete',
    Information = 'Information',
}
