import { ServerApplicationClientEventSummary } from '../../signalr/models';
import { ClientEventSummaryHandlerBase, ClientEventSummaryHandlerBaseArgs } from '../../signalr/ClientEventSummaryHandlerBase';

export interface ServerApplicationClientEventSummaryHandlerArgs extends ClientEventSummaryHandlerBaseArgs<ServerApplicationClientEventSummary> {}

export class ServerApplicationClientEventSummaryHandler extends ClientEventSummaryHandlerBase<ServerApplicationClientEventSummary> {
    public constructor(server: string, args: ServerApplicationClientEventSummaryHandlerArgs) {
        super(server, args);
    }

    protected override isForMe(data: ServerApplicationClientEventSummary): boolean {
        return data.targetServer === this.server;
    }
}
