import { Table } from 'react-bootstrap';
import { IManagingVirtualAppsInstance } from '../../api/models';
import { Checkbox } from '../../areas/app/Checkbox';
import { VirtualAppAction } from '../../constants/VirtualAppAction';
import { ScopedUserPrincipal } from '../../permissions/ScopedUserPrincipal';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { ServerApplicationClientEventSummary } from '../../signalr/models';
import { EmptyDataRow } from '../UIExtentions/TableUtilities';
import { VirtualAppsActionHandlerProps } from './VirtualAppsServerManagementView';
import { VirtualAppsServerRow } from './VirtualAppsServerRow';

interface ApplicationServerTableProps {
    userPrincipal: UserPrincipal;
    serversUserPrincipal: ScopedUserPrincipal;
    allServersSelectionChanged: (selected: boolean) => void;
    serverSelectionChanged: (selected: boolean, serverName: string) => void;
    onClickCallback: (props: VirtualAppsActionHandlerProps) => Promise<void>;
    serverApps: IManagingVirtualAppsInstance[];
    servers: string[];
    allSelected: boolean;
    onTimeoutCallback: (data: ServerApplicationClientEventSummary) => void;
}
export const VirtualAppsServerTable = ({
    userPrincipal,
    serversUserPrincipal,
    allServersSelectionChanged,
    serverSelectionChanged,
    onClickCallback,
    serverApps,
    servers,
    allSelected,
    onTimeoutCallback,
}: ApplicationServerTableProps) => {
    return (
        <Table striped size="sm">
            <thead>
                <tr>
                    <th scope="col" className="col-1">
                        <Checkbox selected={allSelected} selectionChangedCallback={allServersSelectionChanged} />
                    </th>
                    <th scope="col" className="col-4">
                        Name
                    </th>
                    <th scope="col" className="col-1">
                        Env.
                    </th>
                    <th scope="col" className="col-1">
                        Refresh
                    </th>
                    <th scope="col" className="col-3"></th>
                    <th scope="col" className="col-3 text-center">
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {serverApps !== null && serverApps.length > 0 ? (
                    serverApps.map(function (serverApp: IManagingVirtualAppsInstance) {
                        const selected = servers.includes(serverApp.serverName);
                        return (
                            <VirtualAppsServerRow
                                serverApp={serverApp}
                                key={serverApp.serverId}
                                selected={selected}
                                userPrincipal={userPrincipal}
                                serverUserPrincipal={serversUserPrincipal?.getUserPrincipal(serverApp.serverName)}
                                selectionChangedCallback={serverSelectionChanged}
                                onClickCallback={(action: VirtualAppAction) =>
                                    onClickCallback({
                                        virtualAppName: serverApp.name,
                                        webSiteName: serverApp.siteName,
                                        servers: [serverApp.serverName],
                                        action: action,
                                    })
                                }
                                onTimeoutCallback={onTimeoutCallback}
                            />
                        );
                    })
                ) : (
                    <EmptyDataRow colSpan={7} />
                )}
            </tbody>
        </Table>
    );
};
