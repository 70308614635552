import { Version } from './Version';

const versionSort = (left: Version, right: Version): number => {
    let result = right.major - left.major;
    if (result === 0) {
        result = right.minor - left.minor;
    }
    if (result === 0) {
        result = right.build - left.build;
    }
    if (result === 0) {
        result = right.revision - left.revision;
    }
    return result;
};

export { versionSort };
