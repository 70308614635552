import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageHeading } from '@quad/bootstrap-react';
import { Card, Col, Container, Row, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ScrollPageContent from '../../areas/shared/ScrollPageContent';
import useCurrentUserInfo from '../../hooks/useCurrentUserInfo';

export function AccessDenied() {
    const currentUserInfo = useCurrentUserInfo();

    return (
        <ScrollPageContent>
            <PageHeading containerProps={{ style: { backgroundColor: 'var(--bs-warning)' } }}>
                <Col className="text-center">
                    <h1>Access Denied</h1>
                    <h3>{`Sorry ${currentUserInfo?.displayName}, you don't have access to the requested resource.`}</h3>
                </Col>
            </PageHeading>
            <Container fluid="lg">
                <Row className="justify-content-center">
                    <Col>
                        <h2>Here are a few things you can try:</h2>
                        <Stack gap={5} className="mx-5">
                            <Card>
                                <Card.Body>
                                    <h3>
                                        <ArrowIcon />
                                        Try a different account
                                    </h3>
                                    <p>
                                        <Link to="/signout">
                                            <FontAwesomeIcon fixedWidth icon={faSignOutAlt} />
                                            If you have a different account, try signing in with that account
                                        </Link>
                                    </p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <h3>
                                        <ArrowIcon />
                                        Request Access
                                    </h3>
                                    <p>If you never had access but want to get access, please follow the IAM procedures to request accesss.</p>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <h3>
                                        <ArrowIcon />
                                        Contact Helpdesk
                                    </h3>
                                    <p>If you think this is a problem with the application, please contact Helpdesk and include the following information</p>

                                    <Row className="ms-3">
                                        <Col sm="12">
                                            <label className="fw-bold">Date &amp; Time: </label> {new Date().toUTCString()} (UTC)
                                        </Col>
                                        <Col sm="12">
                                            <label className="fw-bold">User Principal: </label> {currentUserInfo?.email}
                                        </Col>
                                        <Col sm="12">
                                            <label className="fw-bold">Url:</label> <code>{window.location.href}</code>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </ScrollPageContent>
    );
}

const ArrowIcon = () => <i className="fa fa-arrow-circle-o-right fa-2x float-start pe-4"></i>;
