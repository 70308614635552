import { Item } from '../ItemList/Item';

export class AllowedSystemLocationItem implements Item {
    constructor(public value: string) {}
    getKey() {
        return this.value;
    }
    forDisplay() {
        return this.value;
    }
}
