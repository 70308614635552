import { Table } from 'react-bootstrap';
import { IApplication } from '../../api/models';
import { ApplicationTableButtons, ApplicationTableColumns } from '../../constants/ApplicationTableItems';
import { EmptyDataRow } from '../UIExtentions/TableUtilities';
import { ApplicationsTableRow } from './ApplicationsTableRow';

export interface ApplicationsTableProps {
    data: IApplication[];
    deleteAppHandler?: (appName: string) => void;
    maxHeight?: number;
    hiddenColumns?: [columnName: ApplicationTableColumns];
    hiddenButtons?: [buttonName: ApplicationTableButtons];
}

const ApplicationsTable = (props: ApplicationsTableProps) => {
    const hiddenColumns = props.hiddenColumns;
    const hiddenButtons = props.hiddenButtons;
    const maxHeight = props.maxHeight;

    const CheckMaxHeight = () => {
        if (maxHeight) {
            return maxHeight > 0;
        }

        return false;
    };

    const CheckIfColumnShouldBeHidden = (columnNm: ApplicationTableColumns) => {
        if (hiddenColumns) {
            return hiddenColumns.includes(columnNm);
        }

        return false;
    };

    const GetHiddenColumns = () => {
        if (hiddenColumns) {
            return hiddenColumns.length;
        }

        return 0;
    };

    return (
        <div className={CheckMaxHeight() ? 'max-height-' + maxHeight : 'table-wrapper'}>
            <Table striped={true} className="table-fixed-header">
                <thead className="thead-white">
                    <tr>
                        <th scope="col" className="col-1">
                            <i className="fa fa-star"></i>
                        </th>
                        {CheckIfColumnShouldBeHidden(ApplicationTableColumns.Name) ? (
                            <></>
                        ) : (
                            <th scope="col" className="col-4">
                                Name
                            </th>
                        )}
                        {CheckIfColumnShouldBeHidden(ApplicationTableColumns.DisplayName) ? (
                            <></>
                        ) : (
                            <th scope="col" className="col-4">
                                Display Name
                            </th>
                        )}
                        {CheckIfColumnShouldBeHidden(ApplicationTableColumns.Type) ? (
                            <></>
                        ) : (
                            <th scope="col" className="col-2">
                                Type
                            </th>
                        )}
                        {CheckIfColumnShouldBeHidden(ApplicationTableColumns.Actions) ? (
                            <></>
                        ) : (
                            <th scope="col" className="col-1">
                                Actions
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {props.data.length > 0 ? (
                        props.data.map(function (d) {
                            return (
                                <ApplicationsTableRow
                                    name={d.name}
                                    displayName={d.displayName}
                                    type={d.type}
                                    key={'app-' + d.id}
                                    deleteAppHandler={(appName: string) => {
                                        if (props.deleteAppHandler) {
                                            props.deleteAppHandler(appName);
                                        }
                                    }}
                                    hiddenColumns={hiddenColumns}
                                    hiddenButtons={hiddenButtons}
                                />
                            );
                        })
                    ) : (
                        <EmptyDataRow colSpan={5 - GetHiddenColumns()} />
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default ApplicationsTable;
