import { Configuration } from '@azure/msal-browser';
import { AppEnvironment } from './constants/AppEnvironment';

export interface AppConfig {
    apiBaseUrl: string;
    authClientId: string;
    authRedirectUrl: string;
    apiScope: string;
    appEnvironment: AppEnvironment.Development | AppEnvironment.Beta | AppEnvironment.Production;
    publicUrl: string;
    knownDataCenterPrefixes: string[];
}

const appConfig: AppConfig = {
    apiBaseUrl: window.runtimeConfig?.apiBaseUrl ?? process.env.REACT_APP_API_BASE_URL ?? '',
    authClientId: window.runtimeConfig?.authClientId ?? process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
    authRedirectUrl: window.runtimeConfig?.authRedirectUrl ?? process.env.REACT_APP_AUTH_REDIRECT_URL ?? '',
    apiScope: window.runtimeConfig?.apiScope ?? process.env.REACT_APP_AUTH_APPMANAGER_API_SCOPE ?? '',
    appEnvironment: (window.runtimeConfig?.appEnvironment ?? process.env.REACT_APP_APP_ENVIRONMENT ?? '') as AppConfig['appEnvironment'],
    publicUrl: process.env.PUBLIC_URL,
    knownDataCenterPrefixes: ['SX', 'WA'],
};

export const msalConfig: Configuration = {
    auth: {
        clientId: `${appConfig.authClientId}`,
        authority: 'https://login.microsoftonline.com/06f4dfec-0eb8-414d-b2e7-7f9bacee2641',
        redirectUri: appConfig.authRedirectUrl,
    },
    cache: {
        cacheLocation: 'sessionStorage',
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [appConfig.apiScope],
};

export default appConfig;
