import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useMemo } from 'react';

const useCurrentUserInfo = () => {
    const { accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const account = accounts[0];

    const userInfo = useMemo(
        () =>
            account
                ? {
                      displayName: account.name ?? '',
                      email: account.username,
                      isAuthenticated,
                  }
                : undefined,
        [account, isAuthenticated]
    );

    return userInfo;
};

export default useCurrentUserInfo;
