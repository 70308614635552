import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, GridOptions, ICellRendererParams, ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { dateFilterParams } from '../../helpers/GridFilters';
import { dateToLocal, stringToDate } from '../../helpers/DateHelpers';
import { ICertificate, IServer } from '../../api/models';
import { getShortThumbprint } from '../../helpers/DisplayHelpers';
import { Loading } from '../Shared/Loading';
import { Col, Row } from 'react-bootstrap';
import { ActionButton } from '../UIExtentions/Buttons';
import { ValidFromDateDisplay } from '../Shared/ValidFromDateDisplay';
import { ValidToDateDisplay } from '../Shared/ValidToDateDisplay';

export interface SimplifiedServerCertificate {
    lastDiscoveredTimestamp: string;
    server: IServer;
}

export interface CertificatesTableProps {
    data: ICertificate[];
    showServers: boolean;
    useAutoHeight: boolean;
    isLoading: boolean;
    areCertsReceived: boolean;
}

ModuleRegistry.registerModules([ClientSideRowModelModule]);

interface IRow {
    thumbprint: string;
    commonName: string;
    validFrom: Date;
    validTo: Date;
    issuer: string;
    keyUsages: string;
    serverCertificates: SimplifiedServerCertificate[];
    friendlyName: string;
    email: string;
    numberOfServers: number;
}

function getCoalescedName(friendlyName: string | undefined, commonName: string | undefined) {
    return friendlyName === undefined || friendlyName === null || friendlyName === '' ? commonName : friendlyName;
}

function getCellValueWithTooltip(params: ICellRendererParams) {
    return (
        <>
            <span title={params.value}>{params.value}</span>
        </>
    );
}

const CertificatesEnhancedTable = (props: CertificatesTableProps) => {
    const gridRef = useRef<AgGridReact>(null);
    const [includeExpired, setIncludeExpired] = useState(true);
    const [certificates, setCertificates] = useState(props.data);
    const [validCertificates, setValidCertificates] = useState({} as ICertificate[]);

    useEffect(() => {
        setCertificates(props.data);

        const processBatch = async (batch: Array<ICertificate>) => {
            const today = new Date();
            const promises = batch.map((certificate) => {
                return new Promise<ICertificate>((resolve, reject) => {
                    setTimeout(() => {
                        const expirationDate = stringToDate(certificate.validTo);
                        const isExpired = today >= (expirationDate as Date);
                        if (!isExpired) {
                            resolve(certificate);
                        }
                        reject(certificate);
                    }, 100);
                });
            });
            return Promise.allSettled(promises);
        };
        const filterValidCertificates = async () => {
            const validCerts = Array<ICertificate>();
            const batchSize = 1000;
            for (let i = 0; i < props.data.length; i += batchSize) {
                const batch = props.data.slice(i, i + batchSize);
                try {
                    const results = await processBatch(batch).then((promiseResults) => {
                        return promiseResults.filter((r) => r.status === 'fulfilled').map((r) => (r as PromiseFulfilledResult<ICertificate>).value);
                    });
                    validCerts.push(...results);
                } catch (error) {
                    console.error(`Error processing batch ${i / batchSize + 1}:`, error);
                }
            }

            setValidCertificates(validCerts);
        };

        filterValidCertificates().catch(console.error);
    }, [props.data]);

    function renderServers(params: ICellRendererParams) {
        const serverCertificates = params.data.serverCertificates;
        if (!serverCertificates) {
            return '';
        }

        const serverNames: string[] = [];
        serverCertificates.forEach((element: SimplifiedServerCertificate) => {
            if (element !== undefined) {
                serverNames.push(element.server.name);
            }
        });
        const serverNamesToString = serverNames.join(', ');
        const mappedNames = serverNames.map((name: string, idx: number, array: string[]) => {
            if (idx === array.length - 1) {
                return (
                    <>
                        <Link to={`/servers/${name}`}>{name} </Link>
                    </>
                );
            } else {
                return (
                    <>
                        <Link to={`/servers/${name}`}>{name}, </Link>
                    </>
                );
            }
        });
        return (
            <>
                <span title={serverNamesToString}>{mappedNames}</span>
            </>
        );
    }

    function getValueServerNames(serverCertificates: SimplifiedServerCertificate[] | undefined) {
        const serverCertsLength = serverCertificates?.length || 0;
        const serverNames: string[] = [];

        if (!serverCertificates) {
            return serverNames;
        }

        if (serverCertsLength > 0) {
            serverCertificates?.forEach((params: { server: { name: string } }) => {
                if (params.server.name !== null) {
                    serverNames.push(params.server.name);
                }
            });
            return serverNames;
        }
    }

    const [colDefs] = useState<ColDef<IRow>[]>([
        {
            field: 'friendlyName',
            sortable: true,
            filter: true,
            headerName: 'Name',
            flex: 3,
            valueGetter: (params) => {
                return getCoalescedName(params.data?.friendlyName, params.data?.commonName);
            },
            cellRenderer: function (params: ICellRendererParams) {
                const thumbprint = params.data.thumbprint;
                return (
                    <>
                        <Link title={params.value} to={`/certificates/${thumbprint}`}>
                            {getCoalescedName(params.data?.friendlyName, params.data?.commonName)}
                        </Link>
                    </>
                );
            },
        },
        {
            field: 'thumbprint',
            sortable: true,
            filter: true,
            flex: 1,
            cellRenderer: function (params: ICellRendererParams) {
                return (
                    <>
                        <span title={params.value}>{getShortThumbprint(params.value)}</span>
                    </>
                );
            },
        },
        {
            field: 'validFrom',
            sortable: true,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            headerName: 'Valid From',
            cellDataType: 'date',
            flex: 2,
            getQuickFilterText: (params) => {
                const certDate = stringToDate(params.value);
                const formattedDate = dateToLocal(certDate);
                return formattedDate;
            },
            cellRenderer: function (params: ICellRendererParams) {
                return (
                    <>
                        <ValidFromDateDisplay date={params.value} />
                    </>
                );
            },
        },
        {
            field: 'validTo',
            sortable: true,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            headerName: 'Expiration',
            cellDataType: 'date',
            flex: 2,
            getQuickFilterText: (params) => {
                const certDate = stringToDate(params.value);
                const formattedDate = dateToLocal(certDate);
                return formattedDate;
            },
            cellRenderer: function (params: ICellRendererParams) {
                return (
                    <>
                        <ValidToDateDisplay date={params.value} />
                    </>
                );
            },
        },
        {
            field: 'serverCertificates',
            headerName: 'Servers',
            sortable: true,
            flex: 3,
            filter: true,
            hide: !props.showServers,
            valueGetter: (params: any) => {
                return getValueServerNames(params.data?.serverCertificates);
            },
            cellRenderer: function (params: ICellRendererParams) {
                return renderServers(params);
            },
        },
        {
            field: 'issuer',
            flex: 1,
            sortable: true,
            filter: true,
            cellRenderer: getCellValueWithTooltip,
        },
        {
            field: 'email',
            flex: props.showServers ? 1 : 2,
            sortable: true,
            filter: true,
            cellRenderer: getCellValueWithTooltip,
        },
        {
            field: 'keyUsages',
            flex: 2,
            sortable: true,
            filter: true,
            cellRenderer: getCellValueWithTooltip,
        },
    ]);

    const gridOptions: GridOptions = {
        domLayout: props.useAutoHeight ? 'autoHeight' : 'normal',
        pagination: true,
        paginationPageSize: props.useAutoHeight ? 10 : 100,
        paginationPageSizeSelector: props.useAutoHeight ? [10] : [20, 50, 100],
        columnDefs: colDefs,
        defaultColDef: { flex: 1 },
    };

    const filterId = 'filter-text-box';
    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption('quickFilterText', (document.getElementById(filterId) as HTMLInputElement).value);
    }, []);

    if (props.isLoading) {
        return <Loading />;
    }

    return (
        <div>
            <div className="example-header">
                {props.areCertsReceived ? '' : <Loading text="Certificates"></Loading>}
                <Row>
                    <Col style={{ flex: '10 1 0' }}>
                        <input className="form-control mb-2" type="text" id={filterId} placeholder="Search" onInput={onFilterTextBoxChanged} />
                    </Col>
                    <Col>
                        {includeExpired ? (
                            <ActionButton
                                disabled={false}
                                outlined={true}
                                icon="fa-eye-slash"
                                variant="btn-red container-fluid"
                                title="Exclude Expired"
                                onClick={() => setIncludeExpired(false)}
                            ></ActionButton>
                        ) : (
                            <ActionButton
                                disabled={false}
                                spinning={false}
                                outlined={true}
                                icon="fa-eye"
                                variant="btn-green container-fluid"
                                title="Include Expired"
                                onClick={() => setIncludeExpired(true)}
                            />
                        )}
                    </Col>
                </Row>
            </div>
            <div className={'ag-theme-quartz'} style={{ width: '100%', height: props.useAutoHeight ? '100%' : '75vh' }}>
                <AgGridReact ref={gridRef} gridOptions={gridOptions} rowData={includeExpired ? certificates : validCertificates} />
            </div>
        </div>
    );
};

export default CertificatesEnhancedTable;
