import AppManagerClient from '../../api/AppManagerClient';
import React, { useCallback, useEffect } from 'react';
import { PermissionsView } from './PermissionsView';
import signalREventsClient from '../../signalr/SignalREventsClient';
import { ServerPermissionClientEventSummary } from '../../signalr/models';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { PermissionItem } from './models';
import { IPermissionRequest, IServerPermission } from '../../api/models';
import { tryCallApi } from '../../api/Helpers';
import { Permission } from '../../permissions/permission';
import { RoleType } from '../../constants/RoleType';

interface ServerPermissionsProps {
    serverName: string;
    userPrincipal: UserPrincipal;
}

export const ServerPermissions = (props: ServerPermissionsProps) => {
    const [permissions, setPermissions] = React.useState(Array<PermissionItem>());
    const [isLoading, setLoadingStatus] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const serverName = props.serverName;
    const userPrincipal = props.userPrincipal;

    const apiAddPermission = useCallback(
        async (permission: PermissionItem): Promise<boolean> => {
            const permissionRequest = convertPermissionToRequest(serverName, permission);
            return await tryCallApi(async () => await AppManagerClient.postServerPermission(permissionRequest));
        },
        [serverName]
    );

    const apiUpdatePermission = useCallback(
        async (permission: PermissionItem, currentRole: string): Promise<boolean> => {
            const permissionRequest = convertPermissionToRequest(serverName, permission);
            return await tryCallApi(async () => await AppManagerClient.putServerPermission(permissionRequest, currentRole));
        },
        [serverName]
    );

    const apiDeletePermission = useCallback(
        async (permission: PermissionItem): Promise<boolean> => {
            const permissionRequest = convertPermissionToRequest(serverName, permission);
            return await tryCallApi(async () => await AppManagerClient.deleteServerPermission(permissionRequest));
        },
        [serverName]
    );

    const getPermissions = useCallback(async (serverName: string) => {
        try {
            const permissionsResult = await AppManagerClient.getServerPermissionsByServerName(serverName);
            const permissions = permissionsResult.map((x) => convertPermissionToPermissionItem(x));
            setPermissions(permissions);
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setLoadingStatus(false);
        }
    }, []);

    useEffect(() => {
        getPermissions(serverName);
    }, [getPermissions, serverName]);

    useEffect(() => {
        function isForMe(data: ServerPermissionClientEventSummary) {
            return data.targetServer === serverName;
        }

        signalREventsClient.onServerPermissionEvent.setHook(`ServerPermissions_${serverName}`, (data) => {
            if (isForMe(data)) {
                getPermissions(data.targetServer);
            }
        });
    }, [getPermissions, serverName]);

    return (
        <PermissionsView
            userPrincipal={userPrincipal}
            permissions={permissions}
            isLoading={isLoading}
            errorMessage={errorMessage}
            isDataNotFound={false}
            objectName={serverName}
            getNeededModifyPermission={getNeededModifyPermission}
            apiAddPermission={apiAddPermission}
            apiUpdatePermission={apiUpdatePermission}
            apiDeletePermission={apiDeletePermission}
        />
    );
};

const getNeededModifyPermission = (roleName: string): Permission => {
    switch (roleName) {
        case RoleType.Owner:
            return Permission.Server_ChangeOwners;
        case RoleType.Contributor:
            return Permission.Server_ChangeContributors;
        default:
            return Permission.Unknown;
    }
};

const convertPermissionToRequest = (serverName: string, permission: PermissionItem): IPermissionRequest => {
    const persmissionRequest = {
        role: permission.role,
        groupSecurityId: permission.activeDirectoryGroupSecurityId,
        name: serverName,
    } as IPermissionRequest;

    return persmissionRequest;
};

const convertPermissionToPermissionItem = (permission: IServerPermission): PermissionItem => {
    const permissionItem = {
        id: permission.id,
        activeDirectoryGroupSecurityId: permission.groupSecurityId,
        activeDirectoryGroupName: permission.groupName,
        role: permission.role,
    };

    return permissionItem;
};
