import { Link } from 'react-router-dom';
import { ApplicationTableButtons, ApplicationTableColumns } from '../../constants/ApplicationTableItems';
import { AppType } from '../../constants/AppType';
import { InfoLink } from '../UIExtentions/InfoLink';
import ApplicationsDelete from './ApplicationsDelete';

interface ApplicationsTableRowProps {
    name: string;
    displayName: string;
    type: string;
    deleteAppHandler: (appName: string) => void;
    hiddenColumns?: [columnName: ApplicationTableColumns];
    hiddenButtons?: [buttonName: ApplicationTableButtons];
}

export function ApplicationsTableRow(props: ApplicationsTableRowProps) {
    const hiddenColumns = props.hiddenColumns;
    const hiddenButtons = props.hiddenButtons;

    const CheckIfColumnShouldBeHidden = (columnNm: ApplicationTableColumns) => {
        if (hiddenColumns) {
            return hiddenColumns.includes(columnNm);
        }

        return false;
    };

    const CheckIfButtonShouldBeHidden = (buttonNm: ApplicationTableButtons) => {
        if (hiddenButtons) {
            return hiddenButtons.includes(buttonNm);
        }

        return false;
    };

    return (
        <tr>
            <td></td>
            {CheckIfColumnShouldBeHidden(ApplicationTableColumns.Name) ? (
                <></>
            ) : (
                <td>
                    <Link to={`/applications/${props.type}/${props.name}`}>{props.name}</Link>
                </td>
            )}
            {CheckIfColumnShouldBeHidden(ApplicationTableColumns.DisplayName) ? <></> : <td>{props.displayName}</td>}
            {CheckIfColumnShouldBeHidden(ApplicationTableColumns.Type) ? <></> : <td>{props.type}</td>}
            {CheckIfColumnShouldBeHidden(ApplicationTableColumns.Actions) ? (
                <></>
            ) : (
                <td>
                    <InfoLink to={`/applications/${props.type}/${props.name}`} title={`Details of ${props.name}`} />
                    {props.type === AppType.WindowsService && !CheckIfButtonShouldBeHidden(ApplicationTableButtons.Delete) ? (
                        <>
                            |
                            <ApplicationsDelete name={props.name} deleteAppHandler={props.deleteAppHandler} />
                        </>
                    ) : (
                        <></>
                    )}
                </td>
            )}
        </tr>
    );
}
