export interface ValidDateDisplayProps {
    textColor: string;
    icon: string;
    title: string;
    certDate: Date;
    formattedDate: string;
}

export const ValidCertificateDateDisplay = ({ textColor, icon, title, certDate, formattedDate }: ValidDateDisplayProps) => {
    return (
        <>
            <div className={textColor}>
                <span>
                    <i className={icon} title={title}></i>
                    &nbsp;
                    <span title={`${certDate}`}>{`${formattedDate}`}</span>
                </span>
            </div>
        </>
    );
};
