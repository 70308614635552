import { PageHeading } from '@quad/bootstrap-react';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { AccordionWrapper } from '../../components/UIExtentions/Accordion';
import ScrollPageContent from '../shared/ScrollPageContent';
import withHasPermission from '../../hoc/withHasPermission';
import { Permission } from '../../permissions/permission';
import { useParams } from 'react-router-dom';
import { ServerCertificateManagement } from '../../components/CertificateManagement/ServerCertificateManagement';
import { CertificateInfo } from '../../components/CertificateInfo/CertificateInfo';
import { useState } from 'react';
import { getShortThumbprint } from '../../helpers/DisplayHelpers';

export const CertificateDetails = withHasPermission(() => {
    const thumbprint = useParams<'thumbprint'>().thumbprint!;
    const [friendlyName, setFriendlyName] = useState(undefined);
    const [commonName, setCommonName] = useState('');
    const displayName = friendlyName || commonName;

    return (
        <ScrollPageContent>
            <PageHeading>
                <div className="col">
                    <h1>
                        <i className="fa-solid fa-scroll"></i> {`${displayName} (${getShortThumbprint(thumbprint)})`} Details
                    </h1>
                </div>
            </PageHeading>

            <Stack gap={3}>
                <Container>
                    <AccordionWrapper isDefaultOpen={true} headerIcon="fa-info-circle" headerText="Certificate Details">
                        <Container>
                            <Row>
                                <Col sm={12} md={12}>
                                    <CertificateInfo thumbprint={thumbprint} setFriendlyName={setFriendlyName} setCommonName={setCommonName} />
                                </Col>
                            </Row>
                        </Container>
                    </AccordionWrapper>
                </Container>

                <Container>
                    <ServerCertificateManagement thumbprint={thumbprint} displayName={displayName} />
                </Container>
            </Stack>
        </ScrollPageContent>
    );
}, Permission.Root_Certificates);
