import { PropsWithChildren } from 'react';
import { Button, CloseButton, Modal } from 'react-bootstrap';

export type InfoModalProps = PropsWithChildren<{
    show: boolean;
    title: string;
    onHide: () => void;
    includeClose?: boolean;
    icon?: string;
}>;

export function InfoModal(props: InfoModalProps) {
    return (
        <>
            <Modal size="lg" centered show={props.show}>
                <Modal.Header>
                    <Modal.Title>
                        {props.icon ? <i className={'fa ' + props.icon}></i> : ''}
                        &nbsp;
                        {props.title}
                    </Modal.Title>
                    <CloseButton onClick={props.onHide} />
                </Modal.Header>
                <Modal.Body>{props.children}</Modal.Body>
                {props.includeClose ? (
                    <Modal.Footer>
                        <Button variant="light" onClick={() => props.onHide()}>
                            Close
                        </Button>
                    </Modal.Footer>
                ) : (
                    ''
                )}
            </Modal>
        </>
    );
}
