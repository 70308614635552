import { loginRequest } from '../appConfig';
import { msalInstance } from '../store';
import ClientBase from './ClientBase';
import { IApiRequest } from './models';

class MsalClientBase extends ClientBase {
    constructor(protected baseUrl: string) {
        super(baseUrl);
    }

    private getActiveAccount() {
        let account = msalInstance.getActiveAccount();
        if (!account) {
            const allAccounts = msalInstance.getAllAccounts();
            if (allAccounts && allAccounts.length > 0) {
                msalInstance.setActiveAccount(allAccounts[0]);
            }
        }
        account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
        }
        return account;
    }

    getAccessToken = async () => {
        const account = this.getActiveAccount();
        const request = { ...loginRequest, account: account };
        let token = '';
        await msalInstance
            .acquireTokenSilent(request)
            .then((response) => {
                token = response.accessToken;
            })
            .catch(async (e) => {
                await msalInstance.acquireTokenPopup(request).then((response) => {
                    token = response.accessToken;
                });
            });

        return token;
    };

    protected fetchJsonNullable = async <T = any>(request: IApiRequest): Promise<T | null> => {
        const rewrote = { ...request, accessToken: await this.getAccessToken() };

        return await this.appFetchJsonNullable<T>(rewrote);
    };

    protected fetchJson = async <T = any>(request: IApiRequest): Promise<T> => {
        const rewrote = { ...request, accessToken: await this.getAccessToken() };

        return await this.appFetchJson<T>(rewrote);
    };

    protected fetch = async (request: IApiRequest) => {
        const rewrote = { ...request, accessToken: await this.getAccessToken() };

        return await this.appFetch(rewrote);
    };
}

export default MsalClientBase;
