import { PageHeading } from '@quad/bootstrap-react';
import { Container } from 'react-bootstrap';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import appConfig from '../../appConfig';
import ScrollPageContent from '../shared/ScrollPageContent';
import AppManagerClient from '../../api/AppManagerClient';

export const Swagger = () => {
    const requestInterceptor = async (req: any) => {
        return {
            ...req,
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json;charset=utf-8',
                Authorization: `Bearer ${await AppManagerClient.getAccessToken()}`,
            },
        };
    };

    const onComplete = () => {
        const authBtnContainer = document.getElementsByClassName('scheme-container');
        if (authBtnContainer.length > 0) {
            // hide the auth button
            authBtnContainer[0].setAttribute('hidden', 'true');
        }
    };

    return (
        <ScrollPageContent>
            <PageHeading>
                <div className="col">
                    <h1>
                        <i className="fa fa-code"></i> Swagger
                    </h1>
                </div>
            </PageHeading>

            <Container>
                <SwaggerUI
                    url={`${appConfig.apiBaseUrl}/swagger/v1/swagger.json`}
                    requestInterceptor={requestInterceptor}
                    onComplete={onComplete}
                    tryItOutEnabled={true}
                ></SwaggerUI>
            </Container>
        </ScrollPageContent>
    );
};
