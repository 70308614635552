import AppManagerClient from '../../api/AppManagerClient';
import { IApplicationPermissionRequest } from '../../api/models';
import React, { useEffect } from 'react';
import { PermissionsView } from './PermissionsView';
import { UserPrincipal } from '../../permissions/userPrincipal';
import signalREventsClient from '../../signalr/SignalREventsClient';
import { ApplicationPermissionClientEventSummary } from '../../signalr/models';
import { tryCallApi } from '../../api/Helpers';
import { PermissionItem } from './models';
import { RoleType } from '../../constants/RoleType';
import { Permission } from '../../permissions/permission';

interface ApplicationPermissionsProps {
    applicationName: string;
    applicationType: string;
    userPrincipal: UserPrincipal;
}

export const ApplicationPermissions = (props: ApplicationPermissionsProps) => {
    const [permissions, setPermissions] = React.useState(Array<PermissionItem>());
    const [isLoading, setLoadingStatus] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const applicationName = props.applicationName;
    const applicationType = props.applicationType;
    const userPrincipal = props.userPrincipal;

    const getNeededModifyPermission = (roleName: string): Permission => {
        switch (roleName) {
            case RoleType.Owner:
                return Permission.Application_ChangeOwners;
            case RoleType.Contributor:
                return Permission.Application_ChangeContributors;
            default:
                return Permission.Unknown;
        }
    };

    const convertPermissionToRequest = (permission: PermissionItem): IApplicationPermissionRequest => {
        const persmissionRequest = {
            role: permission.role,
            groupSecurityId: permission.activeDirectoryGroupSecurityId,
            name: applicationName,
            type: applicationType,
        } as IApplicationPermissionRequest;

        return persmissionRequest;
    };

    const apiAddPermission = async (permission: PermissionItem): Promise<boolean> => {
        const permissionRequest = convertPermissionToRequest(permission);
        return await tryCallApi(async () => await AppManagerClient.postApplicationPermission(permissionRequest));
    };

    const apiUpdatePermission = async (permission: PermissionItem, currentRole: string): Promise<boolean> => {
        const permissionRequest = convertPermissionToRequest(permission);
        return await tryCallApi(async () => await AppManagerClient.putApplicationPermission(permissionRequest, currentRole));
    };

    const apiDeletePermission = async (permission: PermissionItem): Promise<boolean> => {
        const permissionRequest = convertPermissionToRequest(permission);
        return await tryCallApi(async () => await AppManagerClient.deleteApplicationPermission(permissionRequest));
    };

    const getPermissions = async (appName: string, appType: string) => {
        try {
            const permissionsResult = await AppManagerClient.getApplicationPermissionsByAppNameAndType(appName, appType);
            setPermissions(permissionsResult);
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setLoadingStatus(false);
        }
    };

    useEffect(() => {
        getPermissions(applicationName, applicationType);
    }, [applicationName, applicationType]);

    useEffect(() => {
        function isForMe(data: ApplicationPermissionClientEventSummary) {
            return data.targetApplication === applicationName;
        }

        signalREventsClient.onApplicationPermissionEvent.setHook(`Permissions_${applicationName}`, (data) => {
            if (isForMe(data)) {
                getPermissions(data.targetApplication, applicationType);
            }
        });
    }, [applicationName, applicationType]);

    return (
        <PermissionsView
            userPrincipal={userPrincipal}
            permissions={permissions}
            isLoading={isLoading}
            errorMessage={errorMessage}
            isDataNotFound={false}
            objectName={applicationName}
            getNeededModifyPermission={getNeededModifyPermission}
            apiAddPermission={apiAddPermission}
            apiUpdatePermission={apiUpdatePermission}
            apiDeletePermission={apiDeletePermission}
        />
    );
};
