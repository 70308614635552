import { ButtonGroup, Col, Row } from 'react-bootstrap';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { ButtonWithIcon } from '../UIExtentions/Buttons';
import { Permission as PermissionString } from '../../permissions/permission';
import React, { useCallback, useEffect } from 'react';
import { ScopedUserPrincipal } from '../../permissions/ScopedUserPrincipal';

interface WindowsServicesServerManagementButtonsProps {
    selectedServers: string[];
    userPrincipal: UserPrincipal;
    serversUserPrincipal: ScopedUserPrincipal;
    onClickCallback: Function;
}
// big buttons
export const WindowsServicesServerManagementButtons = (props: WindowsServicesServerManagementButtonsProps) => {
    const selectedServers = props.selectedServers;
    const userPrincipal = props.userPrincipal;
    const serversUserPrincipal = props.serversUserPrincipal;
    const onClickCallback = props.onClickCallback;

    const getEnableState = useCallback(
        function (permission: PermissionString): boolean {
            return !!selectedServers.length && (userPrincipal.hasPermission(permission) || serversUserPrincipal?.hasPermission(selectedServers, permission));
        },
        [selectedServers, serversUserPrincipal, userPrincipal]
    );

    const [canStart, setCanStart] = React.useState(getEnableState(PermissionString.WindowsService_Start));
    const [canStop, setCanStop] = React.useState(getEnableState(PermissionString.WindowsService_Stop));
    const [canChangeStartupType, setCanChangeStartupType] = React.useState(getEnableState(PermissionString.WindowsService_ChangeStartupType));
    const [canRestart, setCanRestart] = React.useState(getEnableState(PermissionString.WindowsService_Restart));
    const [canRefresh, setCanRefresh] = React.useState(getEnableState(PermissionString.WindowsService_Refresh));

    const UpdatePermissions = useCallback(() => {
        setCanStart(getEnableState(PermissionString.WindowsService_Start));
        setCanStop(getEnableState(PermissionString.WindowsService_Stop));
        setCanChangeStartupType(getEnableState(PermissionString.WindowsService_ChangeStartupType));
        setCanRefresh(getEnableState(PermissionString.WindowsService_Refresh));
        setCanRestart(getEnableState(PermissionString.WindowsService_Restart));
    }, [getEnableState]);

    useEffect(() => {
        UpdatePermissions();
    }, [selectedServers, userPrincipal, serversUserPrincipal, UpdatePermissions]);

    return (
        <Row style={{ marginBottom: '5px' }}>
            <Col>
                <ButtonGroup style={{ width: '100%' }}>
                    <ButtonWithIcon
                        disabled={!canStart}
                        variant="green"
                        icon="fa-play-circle"
                        textColor="white"
                        text="Start"
                        onClick={async () => await onClickCallback('start', undefined)}
                    />
                    <ButtonWithIcon
                        disabled={!canStop}
                        variant="red"
                        icon="fa-stop-circle"
                        textColor="white"
                        text="Stop"
                        onClick={async () => await onClickCallback('stop', undefined)}
                    />
                    <ButtonWithIcon
                        disabled={!canRestart}
                        variant="blue"
                        icon="fa-step-forward"
                        textColor="white"
                        text="Restart"
                        onClick={async () => await onClickCallback('restart', undefined)}
                    />
                </ButtonGroup>
            </Col>
            <Col>
                <ButtonGroup style={{ width: '100%' }}>
                    <ButtonWithIcon
                        disabled={!canChangeStartupType}
                        variant="grey-blue"
                        icon="fa-hand-o-up"
                        textColor="white"
                        text="Set Manual"
                        onClick={async () => await onClickCallback('changeStartupType', 'Manual')}
                    />
                    <ButtonWithIcon
                        disabled={!canChangeStartupType}
                        variant="orange"
                        icon="fa-bolt"
                        textColor="white"
                        text="Set Auto"
                        onClick={async () => await onClickCallback('changeStartupType', 'Automatic')}
                    />
                    <ButtonWithIcon
                        disabled={!canChangeStartupType}
                        variant="dark-grey"
                        icon="fa-ban"
                        textColor="white"
                        text="Set Disabled"
                        onClick={async () => await onClickCallback('changeStartupType', 'Disabled')}
                    />
                </ButtonGroup>
            </Col>
            <Col>
                <ButtonGroup style={{ width: '100%' }}>
                    <ButtonWithIcon
                        disabled={!canRefresh}
                        variant="dark-blue"
                        icon="fa-refresh"
                        textColor="white"
                        text="Refresh"
                        onClick={async () => await onClickCallback('refresh', undefined)}
                    />
                </ButtonGroup>
            </Col>
        </Row>
    );
};
