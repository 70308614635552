import { PageHeading } from '@quad/bootstrap-react';
import { Badge, Container } from 'react-bootstrap';
import ScrollPageContent from '../../areas/shared/ScrollPageContent';
import AppManagerClient from '../../api/AppManagerClient';
import { useState, useEffect } from 'react';
import { ServerNameSort } from './ServerSorting';
import { ServersEnhancedTable } from './ServersEnhancedTable';
import { Server } from './ServersTable';
import { NavBar, NavBarItem } from '../Shared/NavBar';

interface ServersProps {
    title: string;
    icon: string;
}
function Servers(props: ServersProps) {
    const title = props.title;
    const icon = 'fa ' + props.icon;

    const [allServers, setAllServers] = useState(Array<Server>());
    const [allServersAreLoading, setAllServersAreLoading] = useState(true);
    const [userServers, setUserServers] = useState(Array<Server>());
    const [userServersAreLoading, setUserServersAreLoading] = useState(true);

    const loadAllServers = async () => {
        try {
            const servers = await AppManagerClient.getAllServers();
            setAllServers(servers.sort(ServerNameSort));
        } finally {
            setAllServersAreLoading(false);
        }
    };
    const loadUserServers = async () => {
        try {
            const servers = await AppManagerClient.getUserServers();
            setUserServers(servers.sort(ServerNameSort));
        } finally {
            setUserServersAreLoading(false);
        }
    };

    useEffect(() => {
        loadAllServers();
        loadUserServers();
    }, []);

    return (
        <>
            <ScrollPageContent>
                <PageHeading>
                    <div className="col">
                        <h1>
                            <i className={icon}></i> {title}
                        </h1>
                    </div>
                </PageHeading>

                <Container>
                    <NavBar>
                        <NavBarItem target="user-servers" active={true}>
                            <i className="fa fa-star"></i>
                            <span> My Servers </span>
                            <Badge className="row-count">{userServers.length}</Badge>
                        </NavBarItem>
                        <NavBarItem target="all-servers">
                            <span> All Servers </span>
                            <Badge className="row-count">{allServers.length}</Badge>
                        </NavBarItem>
                    </NavBar>

                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active container" id="user-servers" role="tabpanel" aria-labelledby="user-servers-tab">
                            <ServersEnhancedTable isLoading={userServersAreLoading} data={userServers} />
                        </div>
                        <div className="tab-pane fade container" id="all-servers" role="tabpanel" aria-labelledby="all-servers-tab">
                            <ServersEnhancedTable isLoading={allServersAreLoading} data={allServers} />
                        </div>
                    </div>
                </Container>
            </ScrollPageContent>
        </>
    );
}

export default Servers;
