interface AppManagerWikiLinkProps {
    text?: string;
    pagePath: string;
    anchor?: string;
}
export function AppManagerWikiLink(props: AppManagerWikiLinkProps) {
    const wikiBasePath = 'https://dev.azure.com/QuadVSTS/IT/_wiki/wikis/IT.wiki?pagePath=/Software-Infrastructure-Applications/App-Manager/';
    let pageHref = wikiBasePath + props.pagePath;
    pageHref += props.anchor ? '&anchor=' + props.anchor : '';

    return (
        <a href={pageHref} className="fa fa-arrow-up-right-from-square" title="Go to Wiki" target={'_blank'} rel="noreferrer">
            {props.text}
        </a>
    );
}
