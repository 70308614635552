import { PermissionRow } from './PermissionRow';
import { Table } from 'react-bootstrap';
import { ActionButton } from '../UIExtentions/Buttons';
import { PermissionAddModal } from './PermissionAddModal';
import React, { useCallback, useState } from 'react';
import { EmptyDataRow } from '../UIExtentions/TableUtilities';
import { PermissionDragItem, permissionDragItemType, PermissionItem } from './models';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';

interface PermissionsTableProps {
    canModify: boolean;
    roleName: string;
    permissions: PermissionItem[];
    onDelete: (roleName: string, activeDirectoryGroupSecurityId: string) => void;
    onAdd: (permission: PermissionItem) => void;
    onMove: (sourceRoleName: string, destinationRoleName: string, activeDirectoryGroupSecurityId: string) => void;
}
export function PermissionsTable(props: PermissionsTableProps) {
    const [showAddModal, setShowAddModal] = useState(false);
    const roleName = props.roleName;
    const permissions = props.permissions;
    const canModify = props.canModify;
    const onDelete = props.onDelete;
    const onAdd = props.onAdd;

    const onDrop = useCallback(
        ({ roleName, activeDirectoryGroupSecurityId }: PermissionDragItem) => {
            props.onMove(roleName, props.roleName, activeDirectoryGroupSecurityId);
        },
        [props]
    );

    const [{ isOver, canDrop }, dropTargetConnector] = useDrop<PermissionDragItem, void, { isOver: boolean; canDrop: boolean }>({
        accept: permissionDragItemType,
        canDrop: (item) => {
            return canModify && item.roleName !== roleName;
        },
        drop(item, monitor) {
            if (!monitor.didDrop()) {
                if (canModify) {
                    onDrop(item);
                }
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    return (
        <div ref={dropTargetConnector} className={classNames({ 'droppable-hover': isOver && canDrop })}>
            <Table striped>
                <thead className="thead-white">
                    <tr>
                        <th scope="col" className="col-12">
                            {roleName}s{' '}
                            <ActionButton
                                disabled={!canModify}
                                icon="fa-user-plus"
                                variant="btn-dark-blue"
                                title="Add"
                                onClick={() => {
                                    setShowAddModal(true);
                                }}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {permissions.map((permission) => (
                        <PermissionRow key={permission.id} canModify={canModify} permission={permission} onDelete={onDelete} />
                    ))}
                    {!permissions.length && <EmptyDataRow />}
                </tbody>
            </Table>
            <PermissionAddModal
                show={showAddModal}
                onHide={() => {
                    setShowAddModal(false);
                }}
                roleName={roleName}
                onPermissionAdded={onAdd}
            />
        </div>
    );
}
