interface SearchProps {
    onUpdate: (value: string) => void;
}

export const Search = ({ onUpdate }: SearchProps) => {
    return (
        <div>
            <input className="form-control mb-2" placeholder="Search" onChange={(e) => onUpdate(e.target.value)} />
        </div>
    );
};
