import { Row, Col } from 'react-bootstrap';
import { IManagingScheduledTasksInstance } from '../../api/models';
import { ApiDataWrapper } from '../UIExtentions/ApiDataWrapper';

export const ScheduledTasksInfoView = (props: any) => {
    return (
        <>
            <ApiDataWrapper
                objectType="Install Info"
                objectName={props.applicationName}
                isLoading={props.isLoading}
                isDataNotFound={props.isDataNotFound}
                errorMessage={props.errorMessage}
            >
                <ServerScheduledTaskDetails serverApp={props.serverApps} />
            </ApiDataWrapper>
        </>
    );
};

export function ServerScheduledTaskDetails(props: any) {
    const serverApp = props.serverApp as IManagingScheduledTasksInstance;
    const lastResultHex = `0x${serverApp.lastResult.toString(16).padStart(8, '0')}`;
    return (
        <>
            <Row>
                <Col sm={6}>Schedule type:</Col>
                <Col>{serverApp.scheduleType}</Col>
            </Row>
            <Row>
                <Col sm={6}>Trigger:</Col>
                <Col>{serverApp.trigger}</Col>
            </Row>
            <Row>
                <Col sm={6}>Next run time:</Col>
                <Col>{serverApp.nextRunTime}</Col>
            </Row>
            <Row>
                <Col sm={6}>Status:</Col>
                <Col>{`${serverApp.status}`}</Col>
            </Row>
            <Row>
                <Col sm={6}>Last run time:</Col>
                <Col>{serverApp.lastRunTime}</Col>
            </Row>
            <Row>
                <Col sm={6}>Last result (in HEX):</Col>
                <Col>{lastResultHex}</Col>
            </Row>
            <Row>
                <Col sm={6}>Task to run:</Col>
                <Col>{serverApp.taskToRun}</Col>
            </Row>
            <Row>
                <Col sm={6}>Task state:</Col>
                <Col>{serverApp.taskState}</Col>
            </Row>
            <Row>
                <Col sm={6}>Run as user:</Col>
                <Col>{serverApp.runAsUser}</Col>
            </Row>
        </>
    );
}
