import { PropsWithChildren } from 'react';
import { Error404 } from '../Shared/Error404NotFound';
import { Loading } from '../Shared/Loading';
import { UnhandledError } from '../Shared/UnhandledError';

export type ApiDataWrapperProps = PropsWithChildren<{
    objectType: string;
    objectName: string | undefined;
    isLoading: boolean;
    isDataNotFound: boolean;
    errorMessage: string;
}>;
export const ApiDataWrapper = (props: ApiDataWrapperProps) => {
    const objectType = props.objectType;
    const objectName = props.objectName;
    const isLoading = props.isLoading;
    const isDataNotFound = props.isDataNotFound;
    const errorMessage = props.errorMessage;

    return (
        <>
            {isLoading ? (
                <Loading text={objectType} />
            ) : isDataNotFound ? (
                <>
                    <Error404 objectName={objectType + ' ' + objectName} />
                </>
            ) : errorMessage && errorMessage.length > 0 ? (
                <UnhandledError message={errorMessage} />
            ) : (
                <>{props.children}</>
            )}
        </>
    );
};
