import { useMemo, useCallback } from 'react';
import { IManagingApplicationInstance } from '../../api/models';
import { ApplicationStatusModel } from './models';
import { TooltipTable } from '../Shared/TooltipTable';
import { SortIgnoringDataCenterPrefix } from '../Servers/ServerSorting';
import { useGetUniqueId } from '@quad/bootstrap-react';
import { StatusIcon, StatusIconProps } from '../Shared/StatusIcon';
import { stringToDate } from '../../helpers/DateHelpers';

export interface VersionDisplayProps {
    application: ApplicationStatusModel;
    environment: string;
    postEnvironment?: string;
}

interface ServerVersion {
    version?: string;
    versionDiscoveredTimestamp?: Date;
}

interface DisplayInformation {
    display: JSX.Element;
    iconProps?: StatusIconProps;
    tableHeaders: string[];
    tableRows: (string | JSX.Element)[][];
}

interface EnvironmentAppInstances {
    currentEnvironment: IManagingApplicationInstance[];
    postEnvironment?: IManagingApplicationInstance[];
}

interface VersionInfo {
    version: [string, ServerVersion][];
    postVersion?: [string, ServerVersion][];
}

export const VersionDisplay = (props: VersionDisplayProps) => {
    const { application, environment, postEnvironment } = props;

    const getInstances = useCallback((): EnvironmentAppInstances => {
        if (application.appInstances === undefined) {
            return { currentEnvironment: [] };
        }

        return {
            currentEnvironment: application.appInstances.filter((instance) => instance.environment && instance.environment.toLowerCase() === environment.toLowerCase()),
            postEnvironment: application.appInstances.filter((instance) => instance.environment && instance.environment.toLowerCase() === postEnvironment?.toLowerCase()),
        };
    }, [application, environment, postEnvironment]);

    const elementId = useGetUniqueId('version-');

    const getServerVersions = useCallback((): VersionInfo => {
        const instancesForEnvironment = getInstances();
        if (instancesForEnvironment.currentEnvironment.length === 0) {
            return { version: [] };
        }

        const serverVersions = new Map<string, ServerVersion>();
        const postEnvironmentServerVersions = new Map<string, ServerVersion>();

        for (const appInstance of instancesForEnvironment.currentEnvironment) {
            serverVersions.set(appInstance.serverName, { version: appInstance.version, versionDiscoveredTimestamp: stringToDate(appInstance.versionDiscoveredTimestamp) });
        }

        if (instancesForEnvironment.postEnvironment && instancesForEnvironment.postEnvironment.length > 0) {
            for (const appInstance of instancesForEnvironment.postEnvironment) {
                postEnvironmentServerVersions.set(appInstance.serverName, {
                    version: appInstance.version,
                    versionDiscoveredTimestamp: stringToDate(appInstance.versionDiscoveredTimestamp),
                });
            }
        }

        const versionEntries = Array.from(serverVersions.entries());
        versionEntries.sort((a, b) => SortIgnoringDataCenterPrefix(a, b, (x) => x[0]));

        const postEnvironmentVersionEntries = Array.from(postEnvironmentServerVersions.entries());
        postEnvironmentVersionEntries.sort((a, b) => SortIgnoringDataCenterPrefix(a, b, (x) => x[0]));

        return { version: versionEntries, postVersion: postEnvironmentVersionEntries };
    }, [getInstances]);

    const displayInformation = useMemo((): DisplayInformation | null => {
        const versionEntries = getServerVersions();

        if (versionEntries.version.length === 0) {
            return null;
        }

        const versions = new Set<string>();

        const rowElements = versionEntries.version.map(([serverName, serverVersion]) => {
            const version = serverVersion.version ?? 'Unknown';
            versions.add(version);

            return [serverName, version, serverVersion.versionDiscoveredTimestamp?.toLocaleString() ?? ''];
        });

        const postEnvVersions = new Set<string>();
        versionEntries.postVersion?.forEach(([_, serverVersion]) => {
            const version = serverVersion.version ?? 'Unknown';
            postEnvVersions.add(version);
        });

        let displayWarningIcon = true;
        let statusText = 'Multiple';
        let tooltip = 'Multiple versions found';
        if (versions.size === 1) {
            statusText = Array.from(versions.keys())[0];

            tooltip = 'Version out of sync with ' + props.postEnvironment;
            if (postEnvVersions.size === 0 || (postEnvVersions.size === 1 && postEnvVersions.has(statusText))) {
                displayWarningIcon = false;
                tooltip = '';
            }
        }

        return {
            display: <>{statusText}</>,
            iconProps: displayWarningIcon ? { statusType: 'infoWarning', displayText: tooltip } : undefined,
            tableHeaders: ['Server', 'Version', 'First Discovered'],
            tableRows: rowElements,
        };
    }, [getServerVersions, props.postEnvironment]);

    return (
        <>
            {displayInformation ? (
                <>
                    <span id={elementId}>
                        {displayInformation.display}
                        <TooltipTable elementId={elementId} headers={displayInformation.tableHeaders} rows={displayInformation.tableRows} />
                    </span>
                    {displayInformation?.iconProps !== undefined ? <StatusIcon {...displayInformation.iconProps} className={'ps-1'} /> : <></>}
                </>
            ) : (
                <> - </>
            )}
        </>
    );
};
