import { useEffect, useRef } from 'react';

export type ImutableRefObject<T> = Readonly<{
    current: T | undefined;
}>;

/**
 * A hook that will wrap a property in a ref, keep it up to date, and clean it up for you.
 * @param propertyValue
 * @returns
 */
const useRefedProp = <T>(propertyValue: T | undefined) => {
    const ref = useRef<T | undefined>(propertyValue);

    useEffect(() => {
        ref.current = propertyValue;

        return () => (ref.current = undefined);
    }, [propertyValue]);

    const immutable: ImutableRefObject<T> = ref;

    return immutable;
};

export default useRefedProp;
