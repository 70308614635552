import { useEffect, useState } from 'react';
import { ActionButton } from '../UIExtentions/Buttons';
import { ConfirmationModal, VerbiageType } from '../Shared/ConfirmationModal';
import { ValidatableInput } from '../Shared/ValidatableInput';
import { Row } from 'react-bootstrap';
import AppManagerClient from '../../api/AppManagerClient';
import { AdGroupSearch } from '../Permissions/AdGroupSearch';
import { tryCallApi } from '../../api/Helpers';
import { useNavigate } from 'react-router';

interface ApplicationCreateProps {
    applicationType: string;
    disabled: boolean;
    spining: boolean;
}

function ApplicationsCreate(props: ApplicationCreateProps) {
    const [showCreateApp, setShowCreateApp] = useState(false);
    const [appName, setAppName] = useState<string>('');
    const [displayName, setDisplayName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [ownerSid, setOwnerSid] = useState<string>('');
    const [fieldsValidity, setFieldsValidity] = useState(new Map<string, boolean>());
    const [createEnabled, setCreateEnabled] = useState(false);
    const navigate = useNavigate();

    const setFieldValidity = function (fieldName: string, valid: boolean) {
        const fieldsValidityCopy = new Map(fieldsValidity);
        fieldsValidityCopy.set(fieldName, valid);
        setFieldsValidity(fieldsValidityCopy);
    };

    useEffect(() => {
        const enabled = Array.from(fieldsValidity.values()).every((valid) => valid);
        setCreateEnabled(enabled);
    }, [fieldsValidity]);

    const createAppHandler = async () => {
        const success = await tryCallApi(async () => await AppManagerClient.createApplication(appName, displayName, description, props.applicationType, ownerSid));
        if (success) {
            navigate(`/applications/${props.applicationType}/${appName}`);
        }
        setShowCreateApp(false);
    };

    return (
        <div>
            <ActionButton
                disabled={props.disabled}
                spinning={props.spining}
                outlined={true}
                icon="fa-plus"
                variant="btn-green container-fluid"
                title="Create new application"
                onClick={async () => {
                    setAppName('');
                    setOwnerSid('');
                    setDescription('');
                    setDisplayName('');
                    setFieldValidity('ApplicationName', false);
                    setFieldValidity('OwnerGroup', false);
                    setShowCreateApp(true);
                }}
            />
            <ConfirmationModal
                show={showCreateApp}
                enabled={createEnabled}
                title="Create new application"
                verbiageType={VerbiageType.OkCancel}
                onCancel={() => setShowCreateApp(false)}
                onConfirm={() => {
                    createAppHandler();
                }}
            >
                <Row>
                    <ValidatableInput
                        fieldName="Application Name*"
                        value={appName}
                        onChange={(value: string) => {
                            setAppName(value);
                        }}
                        validate={(value: string) => {
                            const errors = [];
                            if (value === undefined || value.length === 0) {
                                errors.push('Application name is required');
                            }
                            setFieldValidity('ApplicationName', errors.length === 0);
                            return errors;
                        }}
                    />
                    <ValidatableInput fieldName="Display Name" value={displayName} onChange={(value: string) => setDisplayName(value)} validate={() => []} />
                    <ValidatableInput fieldName="Description" value={description} onChange={(value: string) => setDescription(value)} validate={() => []} />
                    <div className="form-group">
                        <label>Owner</label>
                        <AdGroupSearch
                            focus={false}
                            onChange={(adGroupName: string, adGroupSid: string) => {
                                setOwnerSid(adGroupSid);
                                const isOwnerValid = !(adGroupSid === undefined || adGroupSid.length === 0);
                                setFieldValidity('OwnerGroup', isOwnerValid);
                            }}
                        />
                    </div>
                </Row>
            </ConfirmationModal>
        </div>
    );
}

export default ApplicationsCreate;
