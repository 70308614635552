import { Link } from 'react-router-dom';
import { IServerCertificate } from '../../api/models';
import { ActionButton } from '../UIExtentions/Buttons';
import { InfoModal } from '../Shared/InfoModal';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { DateDisplay } from '../Shared/DateDisplay';
import { GetCertificateDiscoveryStatus } from '../ApplicationServerManagement/ApplicationServerDiscoveryStatus';
import { useState } from 'react';

interface ServerCertificateLastDiscoveredCellProps {
    serverCertificate: IServerCertificate;
    displayName: string;
}
export function ServerCertificateLastDiscoveredCell({ serverCertificate, displayName }: ServerCertificateLastDiscoveredCellProps) {
    const [show, setShow] = useState(false);

    const serverName = serverCertificate.server.name;

    const status = GetCertificateDiscoveryStatus(serverCertificate);

    const clickHandler = async () => {
        setShow(true);
    };

    const statusCls = classNames('col-9', status.serverCertificate.view.colorText);
    const iconModal = classNames(status.serverCertificate.view.icon, status.serverCertificate.view.colorText);
    const infoModalTitle = `State of ${displayName} on ${serverName}`;
    return (
        <>
            <ActionButton
                disabled={false}
                spinning={false}
                icon={status.serverCertificate.view.icon}
                variant={status.serverCertificate.view.colorBtn}
                title="View discovery details"
                onClick={clickHandler}
            />
            &nbsp;
            <Link to={`/servers/${serverName}`}>{serverName}</Link>
            <InfoModal title={infoModalTitle} show={show} onHide={() => setShow(false)} includeClose={true} icon={iconModal}>
                <Row>
                    <Col className="col-3">Status</Col>
                    <Col className={statusCls}>{status.serverCertificate.message}</Col>
                </Row>
                <Row>
                    <Col className="col-3">Server Last Discovery</Col>
                    <Col className="col-9">
                        <DateDisplay dateAsString={serverCertificate.server.lastDiscoveredTimestamp} />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-3">Certificate Last Discovery</Col>
                    <Col className="col-9">
                        <DateDisplay dateAsString={serverCertificate.lastDiscoveredTimestamp} />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-3">Actions</Col>
                    <Col className={statusCls}>{status.actions}</Col>
                </Row>
            </InfoModal>
        </>
    );
}
