import { Collection } from 'lodash';
import { IManagingAppPoolsInstance } from '../../api/models';
import { ApplicationInstanceEnvironmentComparison } from './ApplicationInstanceEnvironmentComparison';
import { RowDefinition } from './ComparisonTable';
interface AppPoolsEnvironmentComparisonProps {
    data: IManagingAppPoolsInstance[];
}

export const AppPoolsEnvironmentComparison = ({ data }: AppPoolsEnvironmentComparisonProps) => {
    const rows = [
        { rowName: 'State', property: 'state' },
        { rowName: 'Auto Start', property: 'autoStart' },
        { rowName: 'Enable 32Bit', property: 'enable32Bit' },
        { rowName: 'Managed Runtime Version', property: 'managedRuntimeVersion' },
        { rowName: 'Identity Type', property: 'identityType' },
        { rowName: 'UserName', property: 'userName' },
        { rowName: 'Load User Profile', property: 'loadUserProfile' },
        { rowName: 'Idle Timeout', property: 'idleTimeout' },
        { rowName: '-', property: '', customDisplayValueFunction: (values: Collection<{ value: string; count: number }>) => '' },
    ] as RowDefinition<IManagingAppPoolsInstance>[];

    return <ApplicationInstanceEnvironmentComparison data={data} rows={rows} />;
};
