import { PageContent, PageFooter, PageFooterLegal, PageFooterVersion } from '@quad/bootstrap-react';
import { HtmlHTMLAttributes, ReactNode } from 'react';
import packageJson from '../../../package.json';

interface ScrollPageContentProps {
    containerProps?: HtmlHTMLAttributes<HTMLDivElement>;
    children: ReactNode;
}

function ScrollPageContent({ containerProps, children }: ScrollPageContentProps) {
    return (
        <PageContent
            scrollType="ScrollContentAndFooter"
            containerProps={containerProps}
            footer={<PageFooter pageFooterLegal={<PageFooterLegal />} version={<PageFooterVersion version={packageJson.version} />} />}
        >
            {children}
        </PageContent>
    );
}

export default ScrollPageContent;
