import { PageHeading } from '@quad/bootstrap-react';
import { useState, useEffect } from 'react';
import { Container, Row, Stack, Button, ListGroup, Toast, ToastContainer } from 'react-bootstrap';
import appConfig from '../../appConfig';
import { AppEnvironment } from '../../constants/AppEnvironment';
import { AdGroupSearch } from '../../components/Permissions/AdGroupSearch';
import { ActionAlert } from '../../components/UIExtentions/ActionAlert';
import ScrollPageContent from '../shared/ScrollPageContent';
import AppManagerClient from '../../api/AppManagerClient';

export const AgentInstallationView = (props: any) => {
    const baseUrl = appConfig.apiBaseUrl;
    const isProduction = appConfig.appEnvironment === AppEnvironment.Production;
    const [installScript, setInstallScript]: [string, Function] = useState('');
    const [showCopyToast, setShowCopyToast]: [boolean, Function] = useState(false);
    const [serverOwnerSid, setServerOwnerSid] = useState<string>('');
    const [serverOwnerSidValid, setServerOwnerSidValid] = useState<boolean>(false);

    const baseScript =
        '$ProgressPreference = \'SilentlyContinue\'; $confirm = Read-Host "AppManager Agent will be installed inside \'$PWD\\pathEnvPrefix-placeholderquad.appmanager.agent.{version}\'. Are you sure you want to continue? (y/n)"; if($confirm -ne \'y\'){ Write-Host "User cancelled"; return; } Write-Host "Downloading Installer..."; iwr -uri "baseUri-placeholder/Agents/installer/download/latest" -OutFile .\\installer.exe -Headers @{ "Authorization" = "Bearer jwt-placeholder" }; if(-not (Test-Path -Path .\\Installer.exe)) { Write-Warning "Downloaded Installer.exe not found"; return; } Write-Host "Running installer..."; .\\Installer.exe jwt="jwt-placeholder" serverOwnerSID="serverOwnerSID-placeholder"';

    function copyScript() {
        navigator.clipboard.writeText(installScript);
        setShowCopyToast(true);
    }

    function determineUrlEnvPrefix() {
        switch (appConfig.appEnvironment) {
            case AppEnvironment.Development:
                return 'dev';
            case AppEnvironment.Beta:
                return 'beta';
            default:
                return '';
        }
    }

    useEffect(() => {
        const constructInstallScript = async () => {
            const tokenResult = await AppManagerClient.getAccessToken();
            let script = baseScript.replaceAll('jwt-placeholder', tokenResult).replaceAll('baseUri-placeholder', baseUrl).replaceAll('serverOwnerSID-placeholder', serverOwnerSid);
            if (isProduction) {
                script = script.replaceAll('pathEnvPrefix-placeholder', '');
            } else {
                script = script.replaceAll('pathEnvPrefix-placeholder', determineUrlEnvPrefix() + '.').concat(' apiBaseEndpoint='.concat(appConfig.apiBaseUrl).concat('/'));
            }
            setInstallScript(script);
        };

        constructInstallScript();
    }, [isProduction, baseUrl, baseScript, serverOwnerSid]);

    return (
        <ScrollPageContent>
            <PageHeading>
                <div className="col">
                    <h1>
                        <i className="fa fa-download"></i> Agent Installation
                    </h1>
                </div>
            </PageHeading>
            <Stack>
                <Container>
                    <ToastContainer style={{ zIndex: 999 }} position="middle-center">
                        <Toast onClose={() => setShowCopyToast(false)} show={showCopyToast} delay={4000} autohide bg="white">
                            <Toast.Header>
                                <strong className="me-auto">Success!</strong>
                            </Toast.Header>
                            <Toast.Body>Installation script copied successfully.</Toast.Body>
                        </Toast>
                    </ToastContainer>
                    <Row>
                        <ListGroup as="ol" numbered>
                            <ListGroup.Item as="li">
                                Select a server owner to generate the installation script.
                                <div className="form-group" style={{ marginTop: '10px' }}>
                                    <AdGroupSearch
                                        focus={true}
                                        onChange={(adGroupName: string, adGroupSid: string) => {
                                            setServerOwnerSid(adGroupSid);
                                            const isServerOwnerValid = !(adGroupSid === undefined || adGroupSid.length === 0);
                                            setServerOwnerSidValid(isServerOwnerValid);
                                        }}
                                    />
                                </div>
                                <ActionAlert key="owner-alert" variant="warning">
                                    Only group members one level deep will be set as server owners. Sub-group members will not be included.
                                </ActionAlert>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" hidden={!serverOwnerSidValid}>
                                Click
                                <div className="shell-command">
                                    <Button className="command-text" variant="green" size="sm" onClick={copyScript}>
                                        {installScript}
                                    </Button>
                                </div>
                                to copy the installation script.
                                <ActionAlert key="PAT-alert" variant="warning">
                                    The Personal Access Token of the logged in user is pre-inserted in the script and will expire 1 hour after the start of your session, rendering
                                    the copied script unusable thereon.
                                </ActionAlert>
                            </ListGroup.Item>

                            <ListGroup.Item as="li" hidden={!serverOwnerSidValid}>
                                Run the script in administrative powershell.
                            </ListGroup.Item>

                            <ListGroup.Item as="li" hidden={!serverOwnerSidValid}>
                                Once installed, the agent will immediately discover applications and make them available at <a href={baseUrl}>{baseUrl}</a>.
                            </ListGroup.Item>
                        </ListGroup>
                    </Row>
                </Container>
            </Stack>
        </ScrollPageContent>
    );
};
