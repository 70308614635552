import { useEffect, useState, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import AppManagerClient from '../../api/AppManagerClient';
import { tryCallApi } from '../../api/Helpers';
import { IActiveDirectoryGroup } from '../../api/models';
import { ValidationErrors } from '../Shared/ValidationErrors';

const apiGetOptions = async (inputValue: string): Promise<any> => {
    let permissionGroups = new Array<IActiveDirectoryGroup>();
    if (
        !inputValue ||
        (await tryCallApi(async () => {
            permissionGroups = await AppManagerClient.getPermissionGroups(inputValue);
        }))
    ) {
        const options = permissionGroups.map((permissionGroup) => ({
            label: `${permissionGroup.name} (${permissionGroup.samAccountName})`,
            value: permissionGroup.sid,
        }));
        return options;
    }
    return undefined;
};

interface AdGroupSearchProps {
    onChange: Function;
    focus: boolean;
}
export function AdGroupSearch(props: AdGroupSearchProps) {
    const errorMessage = 'AD Group is required';
    const [getOptionsCall, setGetOptionsCall] = useState<any>('');
    const [errors, setErrors] = useState([errorMessage]);
    const select: any = useRef(null);

    useEffect(() => {
        if (props.focus) {
            select.current.focus();
        }
    }, [props.focus]);

    const promiseOptions = (searchString: string) =>
        new Promise<any>((resolve) => {
            clearTimeout(getOptionsCall);
            const call = setTimeout(() => {
                resolve(apiGetOptions(searchString));
            }, 500);
            setGetOptionsCall(call);
        });

    return (
        <>
            <AsyncSelect
                className={`required ${errors.length === 0 ? '' : 'validation-error'}`}
                ref={select}
                defaultOptions
                loadOptions={promiseOptions}
                onChange={(newValue: any) => {
                    setErrors(newValue.value ? [] : [errorMessage]);
                    props.onChange(newValue.label, newValue.value);
                }}
            />
            <ValidationErrors errors={errors} />
        </>
    );
}
