import React from 'react';
import { StringUtils } from '../../shared/StringUtils';

export const TextWithIcon = (props : ITextWithIcon) => {
    const text = props.shouldCapitalizeFirstLetter ? StringUtils.capitalizeFirstLetter(props.text) : props.text;
    const icon = <i className={`fa ${props.icon ?? 'fa-question-circle'} `}></i>;
    return <>
        <span className={props.className ?? ''}>
            {icon} {text}
        </span>
    </>;
};

export const StyledText = (props : IStyledText) => {
    const text = props.shouldCapitalizeFirstLetter ? StringUtils.capitalizeFirstLetter(props.text) : props.text;
    return <>
        <span className={props.className ?? ''}>
            {text}
        </span>
    </>;
};

export interface ITextWithIcon
{
    text: string,
    icon?: string | null,
    className?: string | null
    shouldCapitalizeFirstLetter? : boolean | null
}

export interface IStyledText
{
    text: string,
    className?: string | null
    shouldCapitalizeFirstLetter? : boolean | null
}