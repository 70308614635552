import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { ConfirmationModal, VerbiageType } from '../Shared/ConfirmationModal';
import { ValidatableInput } from '../Shared/ValidatableInput';
import { AllowedSystemLocationItem } from './AllowedSystemLocationItem';

interface AllowedSystemLocationsAddModalProps {
    show: boolean;
    onHide: () => void;
    onAdded: (locationItem: AllowedSystemLocationItem) => void;
}
export function AllowedSystemLocationsAddModal(props: AllowedSystemLocationsAddModalProps) {
    const [show, setShow] = useState(false);
    const [locationFocus, setLocationFocus] = useState(false);
    const [locationPath, setLocationPath] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [fieldsValidity, setFieldsValidity] = useState(new Map<string, boolean>());

    const onHide = props.onHide;
    const onAdded = props.onAdded;

    const setFieldValidity = function (fieldName: string, valid: boolean) {
        const fieldsValidityCopy = new Map(fieldsValidity);
        fieldsValidityCopy.set(fieldName, valid);
        setFieldsValidity(fieldsValidityCopy);
    };

    useEffect(() => {
        const valid = Array.from(fieldsValidity.values()).every((valid) => valid);
        setIsValid(valid);
    }, [fieldsValidity]);

    useEffect(() => {
        setLocationPath('');
        setLocationFocus(true);
        setShow(props.show);
    }, [props.show]);

    const addLocation = () => {
        if (!isValid) {
            return;
        }

        const location = new AllowedSystemLocationItem(locationPath);
        onAdded(location);
        onHide();
    };

    return (
        <>
            <ConfirmationModal show={show} enabled={isValid} title="Add allowed system location" verbiageType={VerbiageType.OkCancel} onCancel={onHide} onConfirm={addLocation}>
                <Row>
                    <ValidatableInput
                        focus={locationFocus}
                        fieldName={'Location'}
                        value={locationPath}
                        placeholder={"Location path e.g. 'C:\\QuadServices'"}
                        onChange={(value: string) => setLocationPath(value)}
                        validate={(value: string) => {
                            const errors = [];
                            if (value === undefined || value.length === 0) {
                                errors.push('Location is required.');
                            } else if (!value.toUpperCase().startsWith('C:\\')) {
                                errors.push('Location must start with system drive letter.');
                            }

                            setFieldValidity('Location', errors.length === 0);
                            return errors;
                        }}
                        onEnterKeyDown={addLocation}
                    />
                </Row>
            </ConfirmationModal>
        </>
    );
}
