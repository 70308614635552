import { IServerCertificate } from '../../api/models';
import { ServerCertificateLastDiscoveredCell } from './ServerCertificateLastDiscoveredCell';
import { ServerCertificateTableRowActions } from './ServerCertificateTableRowActions';

export interface ServerCertificateTableRowProps {
    serverCertificate: IServerCertificate;
    displayName: string;
}

export function ServerCertificateTableRow(props: ServerCertificateTableRowProps) {
    const serverCertificate = props.serverCertificate;
    const displayName = props.displayName;

    return (
        <tr>
            <td className="details-link">{<ServerCertificateLastDiscoveredCell serverCertificate={serverCertificate} displayName={displayName} />}</td>
            <td>{serverCertificate.server.environment}</td>
            <td>{serverCertificate.storeLocation}</td>
            <td>{serverCertificate.storeName}</td>
            <td>
                <ServerCertificateTableRowActions serverCertificate={serverCertificate} displayName={displayName}></ServerCertificateTableRowActions>
            </td>
        </tr>
    );
}
