import AppManagerClient from '../../api/AppManagerClient';
import { IApplication } from '../../api/models';
import React, { useCallback, useEffect } from 'react';
import { AppPoolApplicationListingView } from './AppPoolApplicationListingView';
import { ApplicationNameSort } from '../Applications/ApplicationSorting';

interface AppPoolApplicationListingProps {
    appPoolName: string;
}

export const AppPoolApplicationListing = (props: AppPoolApplicationListingProps) => {
    const [apps, setApps]: [IApplication[], Function] = React.useState([]);
    const [isLoading, setLoadingStatus] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const appPoolName = props.appPoolName;

    const getApplications = useCallback(async (appPoolName: string) => {
        try {
            const applicationsResult = await AppManagerClient.getVirtualAppsByAppPoolName(appPoolName);
            setApps(applicationsResult.sort(ApplicationNameSort));
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setLoadingStatus(false);
        }
    }, []);

    useEffect(() => {
        getApplications(appPoolName);
    }, [getApplications, appPoolName]);

    return <AppPoolApplicationListingView applications={apps} isLoading={isLoading} errorMessage={errorMessage} isDataNotFound={apps.length === 0} appPoolName={appPoolName} />;
};
