import { Table } from 'react-bootstrap';
import { IServerCertificate } from '../../api/models';
import { EmptyDataRow } from '../UIExtentions/TableUtilities';
import { ServerCertificateTableRow } from './ServerCertificateTableRow';

interface ServerCertificateTableProps {
    displayName: string;
    serverCertificates: IServerCertificate[];
}
export const ServerCertificateTable = ({ displayName, serverCertificates }: ServerCertificateTableProps) => {
    return (
        <Table striped size="sm">
            <thead>
                <tr>
                    <th scope="col" className="col-4">
                        Name
                    </th>
                    <th scope="col" className="col-1">
                        Env.
                    </th>
                    <th scope="col" className="col-1">
                        Store Location
                    </th>
                    <th scope="col" className="col-1">
                        Store Name
                    </th>
                    <th scope="col" className="col-1">
                        Access Rights
                    </th>
                </tr>
            </thead>
            <tbody>
                {serverCertificates !== null && serverCertificates.length > 0 ? (
                    serverCertificates.map(function (serverCert: IServerCertificate) {
                        return <ServerCertificateTableRow serverCertificate={serverCert} displayName={displayName} key={serverCert.serverId} />;
                    })
                ) : (
                    <EmptyDataRow colSpan={5} />
                )}
            </tbody>
        </Table>
    );
};
