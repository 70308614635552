import { PageHeading } from '@quad/bootstrap-react';
import { useEffect, useState } from 'react';
import { Container, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import AppManagerClient from '../../api/AppManagerClient';
import { ServerEventLog } from '../../components/EventLog/ServerEventLog';
import { ServerPermissions } from '../../components/Permissions/ServerPermissions';
import { ServerAgents } from '../../components/ServerAgents/ServerAgents';
import { ServerApplicationListing } from '../../components/ServerApplicationListing/ServerApplicationListing';
import { AccordionWrapper } from '../../components/UIExtentions/Accordion';
import withHasPermission from '../../hoc/withHasPermission';
import { Permission } from '../../permissions/permission';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { ServerPermissionClientEventSummary } from '../../signalr/models';
import signalREventsClient from '../../signalr/SignalREventsClient';
import ScrollPageContent from '../shared/ScrollPageContent';
import CertificatesEnhancedTable from '../../components/Certificates/CertificateEnhancedTable';
import { ICertificate, IServer } from '../../api/models';
import { ServerInfoView } from '../../components/ServerDetails/ServerInfoView';

export const ServerDetails = withHasPermission(() => {
    const serverName = useParams<'name'>().name!;
    const [userPrincipal, setUserPrincipal] = useState(new UserPrincipal([]));
    const [serversAreLoading, setServersAreLoading] = useState(true);
    const [serverErrorMessage, setServerErrorMessage] = useState('');
    const [server, setServer] = useState({} as IServer);
    const [certificates, setCertificates] = useState(Array<ICertificate>());
    const [certificatesAreLoading, setCertificatesAreLoading] = useState(true);

    const getUserPrincipal = async (serverName: string) => {
        const permissionsResult = await AppManagerClient.getServerUserPermissions(serverName);
        const myUserPrincipal = new UserPrincipal(permissionsResult);
        setUserPrincipal(myUserPrincipal);
    };

    const getServer = async (serverName: string) => {
        try {
            const serverResult = await AppManagerClient.getServerByName(serverName);
            if (serverResult) {
                setServer(serverResult);
            }
        } catch (err: any) {
            setServerErrorMessage(err.message);
        } finally {
            setServersAreLoading(false);
        }
    };

    const loadCertificates = async (serverId: number) => {
        try {
            const certs = await AppManagerClient.getCertificates({
                top: null,
                select: 'friendlyName,commonName,thumbprint,validFrom,validTo,issuer,email,keyUsages',
                expand: null,
                filter: `serverCertificates/any(c: c/serverId eq ${serverId})`,
                orderBy: null,
                skip: null,
            });
            setCertificates(certs);
        } finally {
            setCertificatesAreLoading(false);
        }
    };

    useEffect(() => {
        getUserPrincipal(serverName);
        getServer(serverName);

        function isForMe(data: ServerPermissionClientEventSummary) {
            return data.targetServer === serverName;
        }

        signalREventsClient.onServerPermissionEvent.setHook(`ServerDetails_${serverName}`, (data) => {
            if (isForMe(data)) {
                getUserPrincipal(data.targetServer);
            }
        });
    }, [serverName]);

    useEffect(() => {
        if (server && server.id) {
            loadCertificates(server.id);
        }
    }, [server]);

    return (
        <ScrollPageContent>
            <PageHeading>
                <div className="col">
                    <h1>
                        <i className="fa fa-server"></i> {serverName} Details
                    </h1>
                </div>
            </PageHeading>

            <Stack gap={3}>
                <Container>
                    <AccordionWrapper isDefaultOpen={false} headerIcon="fa-info-circle" headerText="Server Details">
                        <ServerInfoView userPrincipal={userPrincipal} server={server} isLoading={serversAreLoading} errorMessage={serverErrorMessage} isDataNotFound={!server} />
                    </AccordionWrapper>
                </Container>

                <Container>
                    <ServerPermissions serverName={serverName} userPrincipal={userPrincipal} />
                </Container>

                <Container>
                    <ServerAgents serverName={serverName} />
                </Container>

                <Container>
                    <AccordionWrapper
                        isDefaultOpen={false}
                        headerIcon="fa-scroll"
                        headerText="Certificates"
                        objectName={serverName}
                        isLoading={certificatesAreLoading}
                        isDataNotFound={certificates?.length === 0}
                        errorMessage={serverErrorMessage}
                        fitToSize={true}
                    >
                        <CertificatesEnhancedTable
                            isLoading={certificatesAreLoading}
                            data={certificates}
                            showServers={false}
                            useAutoHeight={true}
                            areCertsReceived={!certificatesAreLoading}
                        />
                    </AccordionWrapper>
                </Container>

                <Container>
                    <ServerApplicationListing serverName={serverName} />
                </Container>

                <Container>
                    <ServerEventLog serverName={serverName} />
                </Container>
            </Stack>
        </ScrollPageContent>
    );
}, Permission.Root_Servers);
