interface ValidationErrorsProps {
    errors: string[];
}
export function ValidationErrors(props: ValidationErrorsProps) {
    return (
        <>
            <p className="text-danger">
                {props.errors.map((error, i) => (
                    <span key={i} style={{ display: 'inline-block', width: '100%' }}>
                        {error}
                    </span>
                ))}
            </p>
        </>
    );
}
