import { useGetUniqueId } from '@quad/bootstrap-react';
import { useCallback, useMemo } from 'react';
import { IManagingApplicationInstance } from '../../api/models';
import { isAppPoolsInstance, isScheduledTaskInstance, isWindowsServiceInstance } from '../../helpers/AppInstanceHelper';
import { AppType } from '../../constants/AppType';
import { SortIgnoringDataCenterPrefix } from '../Servers/ServerSorting';
import { TooltipTable } from '../Shared/TooltipTable';
import { ApplicationStatusModel } from './models';
import { StatusIcon, StatusIconProps } from '../Shared/StatusIcon';

export interface StatusDisplayProps {
    application: ApplicationStatusModel;
    environment: string;
}

interface ServerStatus {
    isRunning: boolean;
    display: string;
    state: string;
}

interface DisplayInformation {
    iconProps: StatusIconProps;
    display: JSX.Element;
    tableHeaders: string[];
    tableRows: (string | JSX.Element)[][];
}

const getWindowsServiceStatus = (instancesForEnvironment: IManagingApplicationInstance[]): Map<string, ServerStatus> => {
    const serverStatus = new Map<string, ServerStatus>();

    for (const appInstance of instancesForEnvironment) {
        if (isWindowsServiceInstance(appInstance) && appInstance.state) {
            const isRunning = appInstance.state === 'ServiceRunning';
            serverStatus.set(appInstance.serverName, {
                isRunning: isRunning,
                display: isRunning ? 'Running' : 'Not Running',
                state: appInstance.state.replace('Service', ''),
            });
        }
    }

    return serverStatus;
};

const getScheduledTaskStatus = (instancesForEnvironment: IManagingApplicationInstance[]): Map<string, ServerStatus> => {
    const serverStatus = new Map<string, ServerStatus>();

    for (const appInstance of instancesForEnvironment) {
        if (isScheduledTaskInstance(appInstance) && appInstance.taskState) {
            const isRunning = appInstance.taskState === 'Enabled';
            serverStatus.set(appInstance.serverName, {
                isRunning: isRunning,
                display: isRunning ? 'Enabled' : 'Disabled',
                state: isRunning ? `${appInstance.taskState}: ${appInstance.status}` : appInstance.taskState,
            });
        }
    }

    return serverStatus;
};

const getAppPoolStatus = (instancesForEnvironment: IManagingApplicationInstance[]): Map<string, ServerStatus> => {
    const serverStatus = new Map<string, ServerStatus>();

    for (const appInstance of instancesForEnvironment) {
        if (isAppPoolsInstance(appInstance) && appInstance.state) {
            const isRunning = appInstance.state === 'Started';
            serverStatus.set(appInstance.serverName, { isRunning: isRunning, display: isRunning ? 'Started' : 'Stopped', state: appInstance.state });
        }
    }

    return serverStatus;
};

export const StatusDisplay = (props: StatusDisplayProps) => {
    const { application, environment } = props;

    const getInstances = useCallback((): IManagingApplicationInstance[] => {
        if (!application.appInstances) {
            return [];
        }

        if (application.type === AppType.VirtualApp) {
            return application.appPools?.filter((instance) => instance.environment && instance.environment.toLowerCase() === environment.toLowerCase()) ?? [];
        }

        return application.appInstances.filter((instance) => instance.environment && instance.environment.toLowerCase() === environment.toLowerCase());
    }, [application, environment]);

    const elementId = useGetUniqueId('display-');

    const getServerStatus = useCallback(() => {
        const instancesForEnvironment = getInstances();
        if (!instancesForEnvironment.length) {
            return [];
        }

        let serverStatus: Map<string, ServerStatus>;
        if (application.type === AppType.WindowsService) {
            serverStatus = getWindowsServiceStatus(instancesForEnvironment);
        } else if (application.type === AppType.ScheduledTask) {
            serverStatus = getScheduledTaskStatus(instancesForEnvironment);
        } else {
            serverStatus = getAppPoolStatus(instancesForEnvironment);
        }

        const statusEntries = Array.from(serverStatus.entries());
        statusEntries.sort((a, b) => SortIgnoringDataCenterPrefix(a, b, (x) => x[0]));

        return statusEntries;
    }, [application.type, getInstances]);

    const displayInformation = useMemo((): DisplayInformation | null => {
        const statusEntries = getServerStatus();

        if (!statusEntries.length) {
            return null;
        }

        const runningEntries = statusEntries.filter((entry) => entry[1].isRunning);
        const runningCount = runningEntries.reduce((count) => count + 1, 0);
        const totalCount = statusEntries.length;

        let statusText;
        let statusType: StatusIconProps['statusType'];
        if (runningEntries.length) {
            statusType = 'success';
            statusText = runningEntries[0][1].display;
        } else {
            statusType = 'error';
            statusText = statusEntries[0][1].display;
        }

        const countHtml = <span className={runningCount !== 0 && runningCount !== totalCount ? 'text-warning' : ''}>{` (${runningCount}/${totalCount})`}</span>;
        const rowElements = statusEntries.map(([serverName, serverStatus]) => {
            const color = serverStatus.isRunning ? 'lightgreen' : 'salmon';

            const rowEntry: (string | JSX.Element)[] = [
                serverName,
                <div key={elementId + '-' + serverName} style={{ color }}>
                    {serverStatus.state}
                </div>,
            ];

            return rowEntry;
        });

        return {
            iconProps: { statusType },
            display: (
                <>
                    {statusText}
                    {countHtml}
                </>
            ),
            tableHeaders: ['Server', 'Status'],
            tableRows: rowElements,
        };
    }, [elementId, getServerStatus]);

    return (
        <>
            {displayInformation ? (
                <span id={elementId}>
                    <StatusIcon {...displayInformation.iconProps} className={'pe-1'} />
                    {displayInformation.display}
                    <TooltipTable elementId={elementId} headers={displayInformation.tableHeaders} rows={displayInformation.tableRows} />
                </span>
            ) : (
                <> - </>
            )}
        </>
    );
};
