import { IManagingScheduledTasksInstance } from '../../api/models';
import { AppEnvironment } from '../../constants/AppEnvironment';
import { ScheduledTaskStatus } from '../../constants/ScheduledTaskStatus';
import { IStyledText, StyledText } from '../UIExtentions/Text';

function GetStatusProps(status: string, environment: AppEnvironment): IStyledText {
    switch (status) {
        case ScheduledTaskStatus.Ready:
            return {
                text: 'Ready',
                className: 'text-success',
            };
        case ScheduledTaskStatus.Disabled:
            return {
                text: 'Disabled',
                className: environment === AppEnvironment.Production ? 'text-danger' : null,
            };
        case ScheduledTaskStatus.Running:
            return {
                text: 'Running',
                className: 'text-success',
            };
        case ScheduledTaskStatus.Queued:
            return {
                text: 'Queued',
                className: 'text-orange',
            };
        case ScheduledTaskStatus.Unknown:
            return {
                text: 'Unknown',
                className: 'text-muted',
            };
        default:
            return {
                text: status,
            };
    }
}

export default function ScheduledTasksStatus(serverApp: IManagingScheduledTasksInstance) {
    const stateProps = GetStatusProps(serverApp.status, serverApp.environment as AppEnvironment);

    return <StyledText {...stateProps} />;
}
