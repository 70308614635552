import { GetServerDiscoveryStatus } from '../ApplicationServerManagement/ApplicationServerDiscoveryStatus';
import { AppManagerWikiLink } from './AppManagerWikiLink';
import { DateDisplay } from './DateDisplay';

interface ServerLastDiscoveredTimestampProps {
    lastDiscoveredTimestamp?: string;
}
export function ServerLastDiscoveredTimestamp(props: ServerLastDiscoveredTimestampProps) {
    const lastDiscoveredTimestamp = props.lastDiscoveredTimestamp;
    const status = GetServerDiscoveryStatus(lastDiscoveredTimestamp);

    return (
        <p className={status.view.colorText}>
            <i className={'fa ' + status.view.icon} title={status.message}></i> <DateDisplay dateAsString={lastDiscoveredTimestamp} />
            <AppManagerWikiLink pagePath="Manage-Servers" anchor="last-discovery" />
        </p>
    );
}
