import React, { useEffect } from 'react';

import { Checkbox } from '../../areas/app/Checkbox';
import { WebSitesServerRowActions, WebSitesServerRowActionsProps } from './WebSitesServerRowActions';
import WebSitesState from './WebSitesState';
import { ApplicationLastDiscoveredTimestamp } from '../ApplicationServerManagement/ApplicationLastDiscoveredTimestamp';

export interface WebSiteserverRowProps extends WebSitesServerRowActionsProps {
    selected: boolean;
    selectionChangedCallback: Function;
}

export function WebSitesServerRow(props: WebSiteserverRowProps) {
    const [selected, setSelected]: [any, Function] = React.useState(false);

    const serverApp = props.serverApp;

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    return (
        <tr>
            <td>
                <Checkbox selected={selected} selectionChangedCallback={(checked: boolean) => props.selectionChangedCallback(checked, serverApp.serverName)} />
            </td>
            <td className="details-link">
                <ApplicationLastDiscoveredTimestamp serverApp={serverApp} />
            </td>
            <td>{serverApp.environment}</td>
            <td>
                <WebSitesState {...serverApp} />
            </td>
            <WebSitesServerRowActions {...props}></WebSitesServerRowActions>
        </tr>
    );
}
