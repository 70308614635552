import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Loading } from '../Shared/Loading';
import { TableFilter } from '../Shared/TableFilter';
import ServersTable, { Server } from './ServersTable';

export interface ServersEnhancedTableProps {
    data: Server[];
    isLoading: boolean;
}

export function ServersEnhancedTable(props: ServersEnhancedTableProps) {
    const [state, setState] = useState({ search: '' });

    function handleChange(event: any) {
        const searchValue = event.target.value;
        setState({ search: searchValue });
    }

    let searchedData = props.data;
    const searchString = state.search.trim().toLowerCase();

    if (searchString.length > 0) {
        searchedData = searchedData.filter(
            (e: Server) =>
                e.name?.toLowerCase().match(searchString) || e.environment?.toLowerCase().match(searchString) || e.lastDiscoveredTimestamp?.toLowerCase().match(searchString)
        );
    }

    if (props.isLoading) {
        return <Loading />;
    }

    return (
        <div>
            <Row>
                <Col>
                    <TableFilter update={(e: any) => handleChange(e)} />
                </Col>
            </Row>
            <ServersTable data={searchedData} />
        </div>
    );
}
