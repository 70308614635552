import { useCallback, useMemo } from 'react';
import { useGetUniqueId } from '@quad/bootstrap-react';
import { StatusIcon, StatusIconProps } from '../Shared/StatusIcon';
import { TooltipTable } from '../Shared/TooltipTable';
import _, { Collection } from 'lodash';
import { DetailsColor } from './DetailsColor';

export interface ValueDisplayProps<T> {
    valueProperty: keyof T;
    detailsByProperty: keyof T;
    detailsByPropertyDisplayName: string;
    data: T[];
    filterProperty: keyof T;
    filterPropertyValue: string;
    compareToFilterPropertyValue: string;
    customDisplayValueFunction?: (values: Collection<{ value: string; count: number }>) => string;
    customDisplayDetailsValueFunction?: (value: string) => string;
    customStatusFunction?: (value: string) => StatusIconProps['statusType'];
    customDetailsStatusColorFunction?: (value: string) => DetailsColor;
}

interface DisplayInformation {
    display: JSX.Element;
    iconProps: StatusIconProps;
    tableHeaders: string[];
    tableRows: (string | JSX.Element)[][];
}

export function ValueDisplay<T>({
    valueProperty,
    detailsByProperty,
    detailsByPropertyDisplayName,
    data,
    filterProperty,
    filterPropertyValue,
    compareToFilterPropertyValue,
    customDisplayValueFunction,
    customDisplayDetailsValueFunction,
    customStatusFunction,
    customDetailsStatusColorFunction,
}: ValueDisplayProps<T>) {
    const getInstances = useCallback(
        (propertyValue: string): T[] => {
            return _(data)
                .filter((instance: T) => `${instance[filterProperty]}`.toLowerCase() === propertyValue.toLowerCase())
                .orderBy(detailsByProperty, 'asc')
                .value();
        },
        [data, detailsByProperty, filterProperty]
    );

    const getDisplayValues = useCallback(
        (values: T[]): Collection<{ value: string; count: number }> => {
            const displayValues = _(values)
                .groupBy((instance) => {
                    return `${instance[valueProperty]}`.toLowerCase();
                })
                .map((items, value) => {
                    const result = `${items[0][valueProperty]}`;
                    return { value: result === 'null' ? '' : result, count: items.length };
                })
                .orderBy('count', 'desc');

            return displayValues;
        },
        [valueProperty]
    );

    const getIcon = useCallback(
        (displayValues: Collection<{ value: string; count: number }>, compareToDisplayValues: Collection<{ value: string; count: number }>): StatusIconProps['statusType'] => {
            if (displayValues.size() !== 1) {
                return 'error';
            }
            if (compareToDisplayValues.size() !== 1 || displayValues.first()?.value?.toLowerCase() !== compareToDisplayValues.first()?.value?.toLowerCase()) {
                return 'infoWarning';
            }

            return 'success';
        },
        []
    );

    const getDisplayValue = useCallback(
        (displayValues: Collection<{ value: string; count: number }>): string => {
            if (customDisplayValueFunction) {
                return customDisplayValueFunction(displayValues);
            }

            return displayValues.first()?.value ?? '';
        },
        [customDisplayValueFunction]
    );

    const getDisplayDetailsValue = useCallback(
        (displayValue: string): string => {
            if (customDisplayDetailsValueFunction) {
                return customDisplayDetailsValueFunction(displayValue);
            }
            return displayValue;
        },
        [customDisplayDetailsValueFunction]
    );

    const instances = getInstances(filterPropertyValue);
    const compareToInstances = getInstances(compareToFilterPropertyValue);

    const displayValues = getDisplayValues(instances);
    const compareToDisplayValues = getDisplayValues(compareToInstances);

    const displayValue = getDisplayValue(displayValues);

    const elementId = useGetUniqueId('display-');
    const displayInformation = useMemo((): DisplayInformation | null => {
        const rowElements = instances.map((instance) => {
            const rowLabel = `${instance[detailsByProperty]}`;
            const rowValue = getDisplayDetailsValue(`${instance[valueProperty]}`);
            const color = customDetailsStatusColorFunction ? customDetailsStatusColorFunction(rowValue) : rowValue === displayValue ? 'lightgreen' : 'salmon';

            const rowEntry: (string | JSX.Element)[] = [
                rowLabel,
                <div key={elementId + '-' + rowLabel} style={{ color }}>
                    {rowValue}
                </div>,
            ];

            return rowEntry;
        });

        const statusType = customStatusFunction ? customStatusFunction(displayValue) : getIcon(displayValues, compareToDisplayValues);

        return {
            display: <>{displayValue}</>,
            iconProps: { statusType: statusType },
            tableHeaders: ['Server', detailsByPropertyDisplayName],
            tableRows: rowElements,
        };
    }, [
        compareToDisplayValues,
        customDetailsStatusColorFunction,
        customStatusFunction,
        detailsByProperty,
        detailsByPropertyDisplayName,
        displayValue,
        displayValues,
        elementId,
        getDisplayDetailsValue,
        getIcon,
        instances,
        valueProperty,
    ]);

    return (
        <>
            {displayInformation ? (
                <span id={elementId}>
                    <div className="environment-comparison">
                        {displayValue ? <StatusIcon {...displayInformation.iconProps} className={'pe-1'} /> : <></>}
                        {displayInformation.display}
                        <TooltipTable elementId={elementId} headers={displayInformation.tableHeaders} rows={displayInformation.tableRows} />
                    </div>
                </span>
            ) : (
                <> - </>
            )}
        </>
    );
}
