import { useState, useEffect, useCallback } from 'react';
import { IApplication } from '../api/models';
import { AppType } from '../constants/AppType';
import AppManagerClient from '../api/AppManagerClient';

export const useApplications = (types: AppType[], userAppsOnly: boolean, sort?: { (x: IApplication, y: IApplication): number }) => {
    const [applications, setApplications] = useState(Array<IApplication>());
    const [isLoading, setLoading] = useState(true);

    const fetchApplications = useCallback(async () => {
        try {
            let apps: IApplication[] = [];
            if (types.length === 0) {
                apps = await (userAppsOnly ? AppManagerClient.getUserApplications() : AppManagerClient.getAllApplications());
            } else {
                for (const appType of types) {
                    apps = apps.concat(await (userAppsOnly ? AppManagerClient.getUserApplicationsByType(appType) : AppManagerClient.getApplicationsByType(appType)));
                }
            }

            if (sort) {
                apps = apps.sort(sort);
            }
            setApplications(apps);
        } finally {
            setLoading(false);
        }
    }, [types, userAppsOnly, sort]);

    useEffect(() => {
        fetchApplications();
    }, [fetchApplications]);

    return { applications, isLoading };
};
