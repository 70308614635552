import { Collection } from 'lodash';
import { IManagingWindowsServiceInstance } from '../../api/models';
import { ApplicationInstanceEnvironmentComparison } from './ApplicationInstanceEnvironmentComparison';
import { RowDefinition } from './ComparisonTable';

interface WindowsServiceEnvironmentComparisonProps {
    data: IManagingWindowsServiceInstance[];
}

export const WindowsServiceEnvironmentComparison = ({ data }: WindowsServiceEnvironmentComparisonProps) => {
    const rows = [
        { rowName: 'State', property: 'state' },
        { rowName: 'Start Type', property: 'startType' },
        { rowName: 'BinPath', property: 'binPath' },
        { rowName: 'RunAs', property: 'runAsUser' },
        { rowName: 'Process Id', property: 'processId', customStatusFunction: (value: string) => 'success' },
        { rowName: '-', property: '', customDisplayValueFunction: (values: Collection<{ value: string; count: number }>) => '' },
    ] as RowDefinition<IManagingWindowsServiceInstance>[];

    return <ApplicationInstanceEnvironmentComparison data={data} rows={rows} />;
};
